const initialState = {
  dataSearchDeclaration: {
    storedValues: {
      policyNumber: "",
      certificateNumber: "",
      containerNo: "",
      insuredName: "",
      portDeparture: "",
      portDestination: "",
      startDateClaim: "",
      endDateClaim: "",
      startExpiredDate: "",
      endExpiredDate: "",
      submitFrom: "",
      submitTo: "",
      etdFrom: "",
      etdTo: "",
      minPremium: "",
      maxPremium: "",
      status: "",
      isClaimed: "",
      arrivalDate: "",
      vessel: "",
      consignee: "",
      blNo: "",
      fileName: "",
      userEmail: "",
      startDate: "",
      endDate: "",
      depo: "",
      startSurveyDate: "",
      endSurveyDate: "",
      attachmentStatus: "",
      startGateInDate: "",
      endGateInDate: "",
    },
    storedPage: 1,
  },
};

const declarationReducer = (state = initialState, action) => {
  switch (action.type) {
    case "SET_DATA_SEARCH_DECLARATION":
      return {
        ...state,
        dataSearchDeclaration: action.payload,
      };
    case "RESET_DECLARATION":
      return (state = initialState);
    default:
      return state;
  }
};

export default declarationReducer;
