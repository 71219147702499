import React, { useEffect, useState } from "react";
import {
  Accordion,
  Button,
  Card,
  Col,
  Row,
  Table,
  Spinner,
  OverlayTrigger,
  Tooltip,
  Modal,
  Form,
  Badge,
} from "react-bootstrap";
import axiosGet from "../../helpers/axiosGet";
import { Link, useParams } from "react-router-dom";
import "./Declaration.css";
import moment from "moment";
import "moment/locale/id";
import SpinnerPrimary from "../../components/Spinner/Spinner";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft, faPenToSquare } from "@fortawesome/free-solid-svg-icons";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import BannerImage from "../../components/Banner/BannerImage";
import { saveAs } from "file-saver";
import { useForm } from "react-hook-form";
import axiosPut from "../../helpers/axiosPut";
import Alert from "../../components/Alert/Alert";
import constants from "../../constants/constants";
import { StatusContainerFormat } from "../../components/Formating/StatusContainerFormat";
import ClaimLink from "../../components/ClaimLink/ClaimLink";

const StyledAccordion = styled(Accordion)`
  .accordion-item:last-of-type {
    border-radius: 16px;
    border: none;
    box-shadow: #e0e0ec66 0px 0px 10px 10px;
  }

  .accordion-body {
    padding: 10px 40px;
  }

  .accordion-item:first-of-type .accordion-button {
    border-radius: 15px;
    color: #646464 !important;
  }

  .accordion-item:last-of-type > .accordion-header .accordion-button.collapsed {
    border-radius: 15px;
  }

  .accordion-button {
    font-size: 18px;
    font-weight: 800;
    color: #646464;
  }

  .accordion-button:not(.collapsed) {
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
  }
`;

const StyledButtonSubmit = styled.button`
  background: linear-gradient(180deg, #006881, rgba(67, 186, 148, 0) 136.98%);
  border: none;
  border-radius: 20px;
  font-size: 14px;
  padding: 3px 20px;
  color: #fff;

  &.disabled {
    background: #757575;
  }
`;

export default function DetailDeclaration() {
  const isAdminKca = useSelector((state) => state.user.isUserAdminKCa);
  const [loading, setLoading] = useState(false);
  const [loadingDownload, setLoadingDownload] = useState(null);
  const [loadingEdit, setLoadingEdit] = useState(false);
  const [loadingUpdateStatus, setLoadingUpdateStatus] = useState(false);
  const [error, setError] = useState(null);
  const [show, setShow] = useState(false);
  const [messageAlert, setMessageAlert] = useState("");

  // state table action
  const [statusContainer, setStatusContainer] = useState({
    id: "",
    value: "",
    containerNo: "",
  });

  const [gateIn, setGateIn] = useState({
    id: "",
    value: "",
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({});

  const params = useParams();
  const [detail, setDetail] = useState();
  const navigate = useNavigate();

  const [modal, setModal] = useState(false);
  const toggleModal = () => setModal(!modal);

  const decode = window.atob(params.id);

  useEffect(() => {
    setLoading(true);
    setError(null);
    axiosGet({
      url: `/claim-cargo-container/uniq-id/${decode}`,
      callback: (res) => {
        setDetail(res.data);
        setLoading(false);
      },
      errorCallback: (err) => {
        setLoading(false);
        setError(err);
        console.log(error);
      },
    });
  }, [decode, error]);

  useEffect(() => {
    if (statusContainer.id && gateIn.id) {
      const body = {
        attachments: [
          {
            id: statusContainer.id,
            status: statusContainer.value,
            gateInDate: gateIn.value,
          },
        ],
      };

      setLoadingUpdateStatus(true);
      axiosPut({
        url: `/container/update-attachment/${detail?.uniqId}`,
        data: body,
        callback: (res) => {
          setShow(true);
          setMessageAlert(
            `Update status ${statusContainer.containerNo} berhasil!`
          );
          setTimeout(() => {
            setLoadingUpdateStatus(false);
            window.location.reload();
          }, 3000);
        },
        errorCallback: (err) => {
          console.log(err);
        },
      });
    }
  }, [statusContainer, gateIn, detail?.uniqId]);

  const handleClaim = (id) => {
    const encode = window.btoa(id);
    if (isAdminKca) {
      navigate(`/v2/perbaikan/submission/${encode}`);
    } else {
      navigate(`/v2/perbaikan-kontainer/submission/${encode}`);
    }
  };

  const downloadCert = (id, certificateFilename) => {
    setLoadingDownload(id);
    axiosGet({
      url: `container/download/certificate/${id}`,
      responseType: "blob",
      callback: (res) => {
        setLoadingDownload("");
      },
      errorCallback: (res) => {
        setLoadingDownload("");
        let file = new File([res], `${id}.pdf`);
        saveAs(file);
      },
    });
  };

  const toggleEditExpiredDate = () => {
    toggleModal();
  };

  const handleEditExpired = (data) => {
    setLoadingEdit(true);
    const body = {
      expiredDate: data.expiredDate,
    };
    axiosPut({
      url: `container/expired-date/${detail?.uniqId}`,
      data: body,
      callback: (res) => {
        setLoadingEdit(false);
        toggleModal();
        setShow(true);
        setMessageAlert("Edit Valid D/O Berhasil");
        setTimeout(() => {
          window.location.reload();
        }, 3000);
      },
      errorCallback: (err) => {
        setLoadingEdit(false);
        setError(err);
        setShow(true);
      },
    });
  };

  const minDate = moment(detail?.estDepartureDate).format("YYYY-MM-DD");

  const handleSelectStatus = (event, id, containerNo, gateInDate) => {
    const today = moment().format("YYYY-MM-DD");
    setStatusContainer({
      id: id,
      value: event.target.value,
      containerNo: containerNo,
    });
    if (gateInDate !== null) {
      setGateIn({ id: id, value: gateInDate });
    } else {
      setGateIn({ id: id, value: today });
    }
  };

  return loading ? (
    <div
      className="d-flex justify-content-center align-items-center"
      style={{ minHeight: "100vh" }}
    >
      <SpinnerPrimary />
    </div>
  ) : (
    <div className="container">
      <BannerImage isAdmin={isAdminKca} />
      <Row>
        <Col xs={12} md={8}>
          <Card className="card-wrapper p-4 mb-4">
            <Card.Header className="card-main-header">
              Detail Kontainer
            </Card.Header>
            <Card.Body className="main-card">
              <p className="title">Consignee</p>
              <div className="insured">
                <h5 className="m-0">{detail?.insuredReceiverName ?? "-"}</h5>
              </div>

              <div className="p-3">
                <p className="title">Conveyance</p>
                <p className="value">{`${detail?.vesselName}`}</p>
              </div>
            </Card.Body>
          </Card>

          <StyledAccordion className="">
            <Accordion.Item eventKey="0">
              <Accordion.Header>Data Kontainer</Accordion.Header>
              <Accordion.Body>
                <Table striped bordered hover>
                  <thead>
                    <tr className="text-center">
                      <th>No</th>
                      <th>Nomor Kontainer</th>
                      {isAdminKca && <th>Nomor Sertifikat</th>}
                      <th>Status</th>
                      <th>Gate In</th>
                    </tr>
                  </thead>
                  <tbody>
                    {detail?.attachment?.length > 0 ? (
                      <>
                        {detail?.attachment?.map((data, index) => (
                          <tr
                            key={index}
                            className="text-center"
                            style={{ fontSize: "12px", color: "#646464" }}
                          >
                            <td className="text-center">{index + 1}</td>
                            <td>{data.containerNo}</td>
                            {!isAdminKca ? (
                              <>
                                <td className="text-center">
                                  {data.isClaimed ? (
                                    <Badge bg="secondary">
                                      <ClaimLink
                                        id={data?.claimContainerId}
                                        value="Diajukan"
                                        detail
                                        depo
                                      />
                                    </Badge>
                                  ) : (
                                    <Form.Select
                                      name={`description-${data?.id}`}
                                      defaultValue={data.status}
                                      disabled={loadingUpdateStatus}
                                      onChange={(e) =>
                                        handleSelectStatus(
                                          e,
                                          data?.id,
                                          data?.containerNo,
                                          data?.gateInDate
                                        )
                                      }
                                    >
                                      <option value="" disabled selected>
                                        -- Pilih Status --
                                      </option>
                                      {constants.STATUS_DECLARATION.map(
                                        (item, index) => (
                                          <option
                                            value={item.value}
                                            key={index}
                                          >
                                            {item.label}{" "}
                                          </option>
                                        )
                                      )}
                                    </Form.Select>
                                  )}
                                </td>
                                <td className="text-center">
                                  {data?.gateInDate !== null ? (
                                    <>
                                      {moment(data?.gateInDate).format(
                                        "DD MMM YYYY"
                                      )}
                                    </>
                                  ) : (
                                    <Form.Control
                                      type="date"
                                      placeholder=""
                                      disabled={true}
                                      name={`gateIn-${data?.id}`}
                                      min={moment().format("YYYY-MM-DD")}
                                      max={moment().format("YYYY-MM-DD")}
                                      value={
                                        data.gateInDate !== null
                                          ? moment(data.gateInDate).format(
                                              "YYYY-MM-DD"
                                            )
                                          : moment().format("YYYY-MM-DD")
                                      }
                                      style={{ width: "100%" }}
                                    />
                                  )}
                                </td>
                              </>
                            ) : (
                              <>
                                <td>{detail?.certificateNumber ?? "-"}</td>
                                <td>
                                  {data?.isClaimed ? (
                                    <Badge bg="secondary">
                                      <ClaimLink
                                        id={data?.claimContainerId}
                                        value="Diajukan"
                                        detail
                                      />
                                    </Badge>
                                  ) : (
                                    StatusContainerFormat(data.status)
                                  )}
                                </td>
                                <td>
                                  {data.gateInDate
                                    ? moment(data.gateInDate).format(
                                        "DD MMM YYYY"
                                      )
                                    : "-"}
                                </td>
                              </>
                            )}
                          </tr>
                        ))}
                      </>
                    ) : (
                      <>
                        <tr className="text-center">
                          <td
                            colSpan={6}
                            style={{ fontSize: "14px", color: "#646464" }}
                          >
                            Data container tidak ditemukan
                          </td>
                        </tr>
                      </>
                    )}
                  </tbody>
                </Table>
              </Accordion.Body>
            </Accordion.Item>
          </StyledAccordion>
        </Col>

        <Col xs={12} md={4}>
          {detail?.attachment.some(
            (item) => item.status === "damaged" && item.isClaimed === false
          ) && (
            <div className="claim-btn" onClick={() => handleClaim(decode)}>
              <p className="mb-0">Ajukan Perbaikan</p>
            </div>
          )}

          {detail?.certificateNumber && isAdminKca && (
            <Button
              type="button"
              className="download-doc full-width text-medium p-2 mb-2"
              onClick={() =>
                downloadCert(detail?.refId, detail.certificateFilename)
              }
              disabled={loadingDownload}
            >
              {loadingDownload === detail?.refId ? (
                <Spinner
                  animation="border"
                  style={{
                    width: "15px",
                    height: "15px",
                  }}
                />
              ) : (
                "Unduh Sertifikat"
              )}
            </Button>
          )}

          <h5 className="pl-4 pr-4 mt-2 title-header-right">Detail</h5>
          <Card className="card-wrapper p-4" style={{ minHeight: "100px" }}>
            <Card.Body className="main-card">
              <p className="title mb-0">Vessel</p>
              <p className="value-left">
                {detail?.vesselName ? detail?.vesselName : "-"}
              </p>

              <p className="title mb-0">Tanggal Terbit</p>
              <p className="value-left">
                {detail?.policyIssueDate
                  ? moment(detail?.policyIssueDate).format("DD MMM YYYY")
                  : "-"}
              </p>

              <p className="title mb-0">ATA</p>
              <p className="value-left">
                {detail?.estDepartureDate
                  ? moment(detail?.estDepartureDate).format("DD MMM YYYY")
                  : "-"}
              </p>

              <p className="title mb-0">
                Valid D/O{" "}
                <OverlayTrigger
                  placement={"top"}
                  overlay={
                    <Tooltip id={`tooltip-top`}>
                      <strong>Edit Valid D/O</strong>
                    </Tooltip>
                  }
                >
                  <span
                    style={{ cursor: "pointer" }}
                    onClick={toggleEditExpiredDate}
                  >
                    <FontAwesomeIcon
                      icon={faPenToSquare}
                      style={{ color: "#5c7eff" }}
                    ></FontAwesomeIcon>
                  </span>
                </OverlayTrigger>
              </p>
              <p className="value-left">
                {detail?.expiredDate
                  ? moment(detail?.expiredDate).format("DD MMM YYYY")
                  : "-"}
              </p>
            </Card.Body>
          </Card>
        </Col>
      </Row>

      <Row className="mt-5">
        <Col lg={12}>
          <Link to="/transaksi" style={{ textDecoration: "none" }}>
            <Button
              type="button"
              color="primary"
              size="small"
              className="d-flex justify-content-between align-items-center back-button"
            >
              <FontAwesomeIcon icon={faArrowLeft}></FontAwesomeIcon>
              KEMBALI
            </Button>
          </Link>
        </Col>
      </Row>

      <Alert
        message={messageAlert}
        show={show}
        error={error}
        handleClose={() => setShow(false)}
      />

      <Modal show={modal} onHide={toggleModal} size={isAdminKca ? "md" : "lg"}>
        <Modal.Header closeButton>
          <Modal.Title style={{ fontSize: "15px" }}>Edit Valid D/O</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form onSubmit={handleSubmit(handleEditExpired)}>
            <Form.Group className="mb-3">
              <Form.Label>Valid D/O</Form.Label>
              <Form.Control
                type="date"
                name="expiredDate"
                min={minDate}
                {...register("expiredDate", {
                  required: "Pilih tanggal terlebih dahulu!",
                })}
                style={{ width: "100%" }}
              />
              {errors[`expiredDate`] && (
                <p className="mb-0 mt-1 text-danger small">
                  *{errors[`expiredDate`].message}
                </p>
              )}
            </Form.Group>
            <div className="d-flex justify-content-end">
              <StyledButtonSubmit type="submit" disabled={loadingEdit}>
                {loadingEdit ? (
                  <Spinner animation="border" size="sm" />
                ) : (
                  "Edit"
                )}
              </StyledButtonSubmit>
            </div>
          </form>
        </Modal.Body>
      </Modal>
    </div>
  );
}
