import { saveAs } from "file-saver";
import React, { useEffect, useState } from "react";
import { Accordion, Button, Card, Col, Row, Spinner } from "react-bootstrap";
import { useForm } from "react-hook-form";
import styled from "styled-components";
import * as uniqId from "uniqid";
import axiosGet from "../../helpers/axiosGet";
import axiosPost from "../../helpers/axiosPost";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrashAlt } from "@fortawesome/free-solid-svg-icons";
import Alert from "../Alert/Alert";
import axiosDelete from "../../helpers/axiosDelete";
import { useSelector } from "react-redux";

const StyledInput = styled.div`
  /* background: #ffffff; */
  background: ${({ isExist }) => (isExist ? "#f5f5f5" : "#ffffff")};
  border: 1px solid #bcbcbc;
  border-radius: 5px;
  padding: 15px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 1rem;

  span {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  div {
    display: flex;
    align-items: center;
    gap: 0.5rem;
  }
`;

const StyledButton = styled.button`
  padding: 2px 20px;
  font-size: 16px;
  font-weight: 700;
  border-radius: 30px;
  color: white;
  min-height: 1.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  background: ${(props) =>
    props.isDelete ? "#F93154" : props.isDownload ? "#ADDFFF" : "#27AE60"};
  border: none;
  pointer-events: ${(props) => props.isUpload && "none"};
  min-width: ${(props) => (props.isDelete ? "40px" : "120px")};

  :disabled {
    background: #757575;
  }

  i {
    pointer-events: none;
  }
`;

const StyledAbsoluteInput = styled.div`
  padding: 0.5rem 1rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 1rem;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  div {
    display: flex;
    align-items: center;
    gap: 0.5rem;
  }
`;

const StyledWrapper = styled.div`
  position: relative;
`;

const StyledAccordion = styled(Accordion)`
  .accordion-item:last-of-type {
    border-radius: 16px;
    margin-bottom: 20px;
    border: none;
    box-shadow: #e0e0ec66 0px 0px 10px 10px;
  }

  .accordion-body {
    padding: 30px 40px;
  }

  .accordion-item:first-of-type .accordion-button {
    border-radius: 13px;
    color: #646464 !important;
  }

  .accordion-item:last-of-type > .accordion-header .accordion-button.collapsed {
    border-radius: 13px;
  }

  .accordion-button:not(.collapsed) {
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
  }
`;

export default function FileForm({
  formId,
  handleNext,
  data,
  docs,
  docsOther,
  disableNext,
  fields,
  detail,
}) {
  const isAdminKca = useSelector((state) => state.user.isUserAdminKCa);

  const {
    handleSubmit,
    register,
    formState: { errors },
    watch,
    setError,
    clearErrors,
    reset,
  } = useForm({ shouldUnregister: true });

  const onSubmit = (data) => {
    handleNext(data);
  };

  // check semua value insurerBy = PROVIS, return true / false
  const allInsuredByProvis = detail?.claimContainerDetail.every(
    (item) => item.insurerBy === "PROVIS"
  );

  // semua fields bukan untuk upload per kontainer
  const fieldsNotForContainer = fields?.filter(
    (field) => field.isContainer !== true || field.isContainer === undefined
  );

  // fields tanpa type doc = "DOC_ARRIVAL_NOTICE" dan bukan untuk upload per kontainer
  const allFieldsForProvis = fields?.filter(
    (field) =>
      (field.isContainer !== true || field.isContainer === undefined) &&
      field.name !== "DOC_ARRIVAL_NOTICE"
  );

  // variabel fields yang di mapping. Check isAdmin hanya berlaku untuk upload dokumen tambahan
  const allFields =
    isAdminKca && allInsuredByProvis
      ? allFieldsForProvis
      : fieldsNotForContainer;

  // fields per container
  const fieldsContainer = fields?.filter(
    (field) => field.isContainer !== false
  );
  const fieldsContainerForProvis = fieldsContainer?.filter(
    (field) => !["DOC_CLAIM", "DOC_POLICE_REPORT"].includes(field.name)
  );

  const renderFields = (fields, item) =>
    fields?.map((field) => (
      <Col md={12} key={field.name} className="mb-3">
        <FieldContainer
          containerId={item.id}
          field={field}
          rhf={{ register, errors, watch, setError, clearErrors, reset }}
          detail={detail}
          data={data}
          docs={docs}
          disableNext={disableNext}
        />
      </Col>
    ));

  return (
    <div>
      {isAdminKca}
      <Card
        className="card-wrapper px-5 pt-3 pb-5 mb-4"
        style={{ minHeight: "100px" }}
      >
        <Card.Header className="card-main-header">
          {detail?.claimContainerStatus[0]?.statusCode === "101"
            ? "Unggah Dokumen Perbaikan"
            : "Unggah Dokumen"}
        </Card.Header>
        <hr className="mt-2" />
        <br />
        <form onSubmit={handleSubmit(onSubmit)} id={formId}>
          <Row>
            {allFields?.map((field) => (
              <Col md={12} key={field.name} className="mb-3">
                <Field
                  field={field}
                  rhf={{
                    register: register,
                    errors: errors,
                    watch: watch,
                    setError: setError,
                    clearErrors: clearErrors,
                    reset: reset,
                  }}
                  detail={detail}
                  data={data}
                  docs={docs}
                  disableNext={disableNext}
                />
              </Col>
            ))}

            {isAdminKca &&
            allInsuredByProvis &&
            detail?.claimContainerStatus[0]?.statusCode !== "140" ? (
              <div className="text-center">
                <span>Unggah Dokumen Perkontainer</span>
              </div>
            ) : isAdminKca &&
              detail?.claimContainerStatus[0]?.statusCode === "140" ? (
              <></>
            ) : (
              <FieldArray
                rhf={{
                  register: register,
                  errors: errors,
                  setError: setError,
                  clearErrors: clearErrors,
                }}
                data={data}
                disableNext={disableNext}
                docs={docsOther}
                detail={detail}
              />
            )}
          </Row>
        </form>
      </Card>

      {fieldsContainer?.length > 0 &&
        detail?.claimContainerDetail?.map((item) => (
          <StyledAccordion key={item.containerNo}>
            <Accordion.Item eventKey="0">
              <Accordion.Header>
                <span>
                  Unggah Dokumen <strong>({item.containerNo})</strong>
                </span>
              </Accordion.Header>
              <Accordion.Body>
                <Row>
                  {item?.insurerBy === "PROVIS"
                    ? renderFields(fieldsContainerForProvis, item)
                    : renderFields(fieldsContainer, item)}
                </Row>
              </Accordion.Body>
            </Accordion.Item>
          </StyledAccordion>
        ))}
    </div>
  );
}

const Field = ({ field, rhf, data, docs, disableNext, detail }) => {
  const { name, label, required } = field;
  const { register, errors, watch, setError, clearErrors, reset } = rhf;
  const [showAlert, setShowAlert] = useState(false);
  const [err, setErr] = useState(null);
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [initialDocs, setInitialDocs] = useState(docs);
  // const { id } = data;

  const [downloadLoading, setDownloadLoading] = useState(false);
  const [uploadLoading, setUploadLoading] = useState(false);

  const value = watch(name);

  let doc = {};
  initialDocs.forEach((element) => {
    if (element.type === name) {
      doc.isExist = true;
      doc.fileId = element.fileId;
      doc.claimContainerId = element.claimContainerId;
      doc.filename = element.filename;
    }
  });

  const handleLampirkan = (e) => {
    const file = e.target.files[0];
    clearErrors(name);

    if (file) {
      // max size adalah 5MB
      if (file.size > 5_000_000) {
        setError(
          name,
          {
            type: "manual",
            message: "Ukuran file terlalu besar, maksimum ukuran file 5MB",
          },
          {
            shouldFocus: true,
          }
        );
      } else {
        setUploadLoading(true);
        disableNext(true);

        const fd = new FormData();
        fd.append("file", file);
        fd.append("type", name);
        fd.append("description", name);
        fd.append("refId", detail?.claim?.refId);
        fd.append("claimContainerId", detail?.id);

        axiosPost({
          url: "/claim-kca/upload",
          data: fd,
          callback: (res) => {
            // cek tipe dokumen, apakah ada yang sama atau tidak
            const index = initialDocs.findIndex((doc) => doc.type === name);

            // jika tidak, push dokumen baru
            if (index === -1) {
              initialDocs.push(res.data);
            } else {
              // jika ada yang sama, replace dokumen yang lama
              initialDocs[index] = res.data;
            }
            setShowAlert(true);
            setUploadLoading(false);
            disableNext(false);
          },
          errorCallback: (err) => {
            setUploadLoading(false);
            disableNext(false);
            setShowAlert(true);
            setErr(err);
          },
        });
      }
    }
  };

  const handleDelete = (fileId) => {
    // jika file sudah diupload, hapus dari db
    setDeleteLoading(true);
    axiosDelete({
      url: `/claim-kca/delete/file-id/${fileId}`,
      callback: () => {
        setDeleteLoading(false);
        // docs = docs.filter((docObj) => docObj.fileId !== doc);
        setInitialDocs((prevDocs) =>
          prevDocs?.filter((docObj) => docObj.fileId !== fileId)
        );
        reset({ [name]: "" });

        if (doc.fileId === fileId) {
          doc.isExist = false;
        }
      },
      errorCallback: (err) => {
        setDeleteLoading(false);
        console.log(err);
      },
    });
  };

  const handleDownload = (fileId, filename) => {
    setDownloadLoading(true);

    axiosGet({
      url: `/claim-kca/download/file-id/${fileId}`,
      responseType: "blob",
      callback: () => {
        setDownloadLoading(false);
      },
      errorCallback: (res) => {
        setDownloadLoading(false);

        let file = new File([res], filename);
        saveAs(file);
      },
    });
  };

  return (
    <>
      <label htmlFor={name} className="mb-2">
        {label} {required && <span className="text-danger">*</span>}
      </label>
      <input
        type="file"
        className="form-control form-control-lg"
        id={name}
        name={name}
        {...register(name, {
          onChange: handleLampirkan,
          required: !doc.isExist
            ? required && `${label} harus diunggah`
            : false,
        })}
        style={{ display: "none" }}
        accept={".doc, .docx, .pdf, .jpg, .jpeg, .png, .zip, .7z, .rar"}
      />

      <StyledInput>
        <span>
          {value?.length
            ? value[0].name
            : doc.isExist
            ? doc.filename
            : "Pilih file"}
        </span>
        <div>
          {doc.isExist ? (
            <>
              <StyledButton
                type="button"
                disabled={downloadLoading}
                onClick={() => handleDownload(doc.fileId, doc.filename)}
                isDownload
              >
                {downloadLoading ? (
                  <Spinner role="status" size="sm">
                    <span className="visually-hidden">Loading...</span>
                  </Spinner>
                ) : (
                  "Unduh"
                )}
              </StyledButton>
              <StyledButton
                type="button"
                onClick={() => handleDelete(doc.fileId)}
                disabled={deleteLoading || uploadLoading}
                isDelete
              >
                {deleteLoading ? (
                  <Spinner animation="border" size="sm" />
                ) : (
                  <FontAwesomeIcon icon={faTrashAlt}></FontAwesomeIcon>
                  // <MDBIcon fas icon="trash" />
                )}
              </StyledButton>
            </>
          ) : (
            <label htmlFor={name}>
              <div style={{ cursor: "pointer" }}>
                <StyledButton
                  type="button"
                  disabled={uploadLoading || doc.isExist}
                  isUpload
                >
                  {uploadLoading ? (
                    <Spinner animation="border" size="sm" />
                  ) : (
                    "Lampirkan"
                  )}
                </StyledButton>
              </div>
            </label>
          )}
        </div>
      </StyledInput>

      {errors[name] && (
        <p className="mb-0 mt-1 text-danger small">*{errors[name].message}</p>
      )}

      {name === "DOC_REPAIR_DOCUMENTATION" && (
        <p className="mb-0 mt-1 text-danger small">
          <small>
            <p className="mb-0">*catatan</p>
            <p className="mb-0">
              Jenis format file yang diterima : PDF, ZIP, 7Z, dan RAR{" "}
            </p>
            <p className="mb-0">Maksimum ukuran file 5MB</p>
          </small>
        </p>
      )}

      <Alert
        message={`${label} Berhasil!`}
        show={showAlert}
        error={err}
        handleClose={() => setShowAlert(false)}
      />
    </>
  );
};

const FieldContainer = ({
  containerId,
  field,
  rhf,
  data,
  docs,
  disableNext,
  detail,
}) => {
  const { name, label, required } = field;
  const { register, errors, watch, setError, clearErrors, reset } = rhf;
  const [showAlert, setShowAlert] = useState(false);
  const [err, setErr] = useState(null);

  const [downloadLoading, setDownloadLoading] = useState(false);
  const [deleteLoading, setDeleteLoading] = useState(false);

  // Temukan objek DOC_EOR terbaru berdasarkan createdDate
  const latestDocEor = docs
    .filter((doc) => doc.type === "DOC_EOR")
    .reduce(
      (latest, current) => {
        return new Date(latest.createdDate) > new Date(current.createdDate)
          ? latest
          : current;
      },
      { createdDate: "1970-01-01T00:00:00.000Z" }
    );

  // Cek apakah ada objek DOC_EOR terbaru dan claimContainerDetailId-nya null
  const shouldUpdate =
    latestDocEor && latestDocEor.claimContainerDetailId === null;

  // Buat array baru dengan DOC_EOR terbaru diperbarui jika perlu
  const updatedDocs = docs.map((doc) => {
    if (shouldUpdate && doc.id === latestDocEor.id) {
      return { ...doc, claimContainerDetailId: containerId };
    }
    return doc;
  });

  // set nilai awal untuk initialDocs
  const initialDocsValue = shouldUpdate ? updatedDocs : docs;

  const [initialDocs, setInitialDocs] = useState(initialDocsValue);
  // const { id } = data;

  // const [downloadLoading, setDownloadLoading] = useState(false);
  const [uploadLoading, setUploadLoading] = useState(false);

  const value = watch(`${name}_${containerId}`);

  let doc = {};
  initialDocs.forEach((element) => {
    if (
      element.type === name &&
      element.claimContainerDetailId === containerId
    ) {
      doc.isExist = true;
      doc.fileId = element.fileId;
      doc.claimContainerId = element.claimContainerId;
      doc.claimContainerDetailId = element.claimContainerDetailId;
      doc.filename = element.filename;
    }
  });

  const handleLampirkan = (e) => {
    const file = e.target.files[0];

    clearErrors(`${name}_${containerId}`);

    if (file) {
      // max size adalah 5MB
      if (file.size > 5_000_000) {
        setError(
          `${name}_${containerId}`,
          {
            type: "manual",
            message: "Ukuran file terlalu besar, maksimum ukuran file 5MB",
          },
          {
            shouldFocus: true,
          }
        );
      } else {
        setUploadLoading(true);
        disableNext(true);

        const fd = new FormData();
        fd.append("file", file);
        fd.append("type", name);
        fd.append("description", name);
        fd.append("refId", detail?.claim?.refId);
        fd.append("claimContainerId", detail?.id);
        fd.append("claimContainerDetailId", containerId);

        axiosPost({
          url: "/claim-kca/upload",
          data: fd,
          callback: (res) => {
            // cek tipe dokumen, apakah ada yang sama atau tidak
            const index = initialDocs.findIndex(
              (doc) =>
                doc.type === name && doc.claimContainerDetailId === containerId
            );

            // jika tidak, push dokumen baru
            if (index === -1) {
              initialDocs.push(res.data);
            } else {
              // jika ada yang sama, replace dokumen yang lama
              initialDocs[index] = res.data;
            }
            setShowAlert(true);
            setUploadLoading(false);
            disableNext(false);
          },
          errorCallback: (err) => {
            setUploadLoading(false);
            disableNext(false);
            setShowAlert(true);
            setErr(err);
          },
        });
      }
    }
  };

  const handleDelete = (fileId) => {
    // jika file sudah diupload, hapus dari db
    setDeleteLoading(true);
    axiosDelete({
      url: `/claim-kca/delete/file-id/${fileId}`,
      callback: () => {
        setDeleteLoading(false);
        // docs = docs.filter((docObj) => docObj.fileId !== fileId);
        setInitialDocs((prevDocs) =>
          prevDocs?.filter((docObj) => docObj.fileId !== fileId)
        );
        reset({ [`${name}_${containerId}`]: "" });

        if (doc.fileId === fileId) {
          doc.isExist = false;
        }
      },
      errorCallback: (err) => {
        setDeleteLoading(false);
        console.log(err);
      },
    });
  };

  const handleDownload = (fileId, filename) => {
    setDownloadLoading(true);

    axiosGet({
      url: `/claim-kca/download/file-id/${fileId}`,
      responseType: "blob",
      callback: () => {
        setDownloadLoading(false);
      },
      errorCallback: (res) => {
        setDownloadLoading(false);

        let file = new File([res], filename);
        saveAs(file);
      },
    });
  };

  return (
    <>
      <label htmlFor={`${name}_${containerId}`} className="mb-2">
        {label} {required && <span className="text-danger">*</span>}
      </label>
      <input
        type="file"
        className="form-control form-control-lg"
        id={`${name}_${containerId}`}
        name={`${name}_${containerId}`}
        {...register(`${name}_${containerId}`, {
          onChange: handleLampirkan,
          required: !doc.isExist
            ? required && `Dokument wajib diunggah`
            : false,
        })}
        style={{ display: "none" }}
        accept={
          name === "DOC_EOR"
            ? ".xlsx, .xls,  .doc, .docx, .pdf, .jpg, .jpeg, .png, .zip, .7z, .rar"
            : ".doc, .docx, .pdf, .jpg, .jpeg, .png, .zip, .7z, .rar"
        }
      />

      <StyledInput>
        <span>
          {value?.length
            ? value[0].name
            : doc.isExist
            ? doc.filename
            : "Pilih file"}
        </span>
        <div>
          {doc.isExist ? (
            <>
              <StyledButton
                type="button"
                disabled={downloadLoading}
                onClick={() => handleDownload(doc.fileId, doc.filename)}
                isDownload
              >
                {downloadLoading ? (
                  <Spinner role="status" size="sm">
                    <span className="visually-hidden">Loading...</span>
                  </Spinner>
                ) : (
                  "Unduh"
                )}
              </StyledButton>
              <StyledButton
                type="button"
                onClick={() => handleDelete(doc.fileId)}
                disabled={deleteLoading || uploadLoading}
                isDelete
              >
                {deleteLoading ? (
                  <Spinner animation="border" size="sm" />
                ) : (
                  <FontAwesomeIcon icon={faTrashAlt}></FontAwesomeIcon>
                )}
              </StyledButton>
            </>
          ) : (
            <label htmlFor={`${name}_${containerId}`}>
              <div style={{ cursor: "pointer" }}>
                <StyledButton
                  type="button"
                  disabled={uploadLoading || doc.isExist}
                  isUpload
                >
                  {uploadLoading ? (
                    <Spinner animation="border" size="sm" />
                  ) : (
                    "Lampirkan"
                  )}
                </StyledButton>
              </div>
            </label>
          )}
        </div>
      </StyledInput>

      {errors[`${name}_${containerId}`] && (
        <p className="mb-0 mt-1 text-danger small">
          *{errors[`${name}_${containerId}`].message}
        </p>
      )}

      {name === "DOC_REPAIR_DOCUMENTATION" && (
        <p className="mb-0 mt-1 text-danger small">
          <small>
            <p className="mb-0">*catatan</p>
            <p className="mb-0">
              Jenis format file yang diterima : PDF, ZIP, 7Z, dan RAR{" "}
            </p>
            <p className="mb-0">Maksimum ukuran file 5MB</p>
          </small>
        </p>
      )}

      <Alert
        message={`${label} Berhasil!`}
        show={showAlert}
        error={err}
        handleClose={() => setShowAlert(false)}
      />
    </>
  );
};

const FieldArray = ({ rhf, data, docs, disableNext, detail }) => {
  const [fields, setFields] = useState(docs);
  const [addFieldDisabled, setAddFieldDisabled] = useState(false);

  useEffect(() => {
    if (docs) {
      setFields(docs);
    }
  }, [docs]);

  const addField = () => {
    const newField = {
      id: uniqId(),
      description: "",
    };

    setFields([...fields, newField]);
  };

  const removeField = (id) => {
    const filteredDocs = fields?.filter((field) => field.id !== id);

    // Menghapus deskripsi atau konten terkait
    const updatedFields = filteredDocs.map((field) => {
      // Jika field yang dihapus memiliki deskripsi, maka deskripsi tersebut dihapus
      if (field.id === id && field.description) {
        return { ...field, description: "" };
      }
      return field;
    });
    setFields(updatedFields);
  };

  const changeField = (data, id) => {
    const newFields = [...fields];

    const index = newFields.findIndex((field) => field.id === id);

    if (index === -1) {
      // do nothing
    } else {
      // change object value with new data
      newFields[index] = data;
      setFields(newFields);
    }
  };

  const addDescription = (desc, id) => {
    const newFields = [...fields];

    const newFieldsWithDesc = newFields?.map((field) => {
      return field.id === id
        ? {
            ...field,
            description: desc,
          }
        : {
            ...field,
            description: field.description,
          };
    });

    setFields(newFieldsWithDesc);
  };

  const disableAddField = (value) => {
    setAddFieldDisabled(value);
  };

  return (
    <>
      {fields?.map((field, index) => (
        <Col lg={12} key={field.id}>
          <SingleFieldArray
            index={index}
            field={field}
            rhf={rhf}
            data={data}
            docs={docs}
            detail={detail}
            disableNext={disableNext}
            handleField={{
              changeField: changeField,
              removeField: removeField,
              addDescription: addDescription,
              disableAddField: disableAddField,
            }}
          />
        </Col>
      ))}

      <Col lg={12}>
        <Button
          onClick={addField}
          disabled={addFieldDisabled}
          color="primary"
          size="small"
          className="d-flex justify-content-between align-items-center back-button"
        >
          DOKUMEN LAINNYA
        </Button>
      </Col>
    </>
  );
};

const SingleFieldArray = ({
  index,
  field,
  rhf,
  data,
  detail,
  disableNext,
  handleField,
}) => {
  const { register, errors, setError, clearErrors } = rhf;
  // const { id } = data;

  const { changeField, removeField, addDescription, disableAddField } =
    handleField;

  const name = `DOC_OTHER_${index}`;

  const desc = `${name}_DESC`;
  const descLabel = "Deskripsi";

  const isExist = field.filename ? true : false;

  // alert
  const [showAlert, setShowAlert] = useState(false);

  const [err, setErr] = useState(null);

  const [downloadLoading, setDownloadLoading] = useState(false);
  const [uploadLoading, setUploadLoading] = useState(false);
  const [deleteLoading, setDeleteLoading] = useState(false);

  const [filename, setFileName] = useState(null);

  const handleLampirkan = (e) => {
    const file = e.target.files[0];
    setFileName(file.name);

    clearErrors(name);

    if (file) {
      // validasi file size
      if (file.size > 5_000_000) {
        setError(
          name,
          {
            type: "manual",
            message: "Ukuran file terlalu besar, maksimum ukuran file 5MB",
          },
          {
            shouldFocus: true,
          }
        );
      } else {
        setUploadLoading(true);
        disableNext(true);
        disableAddField(true);

        const fd = new FormData();

        fd.append("file", file);
        fd.append("type", "DOC_OTHER");
        fd.append("description", field.description);
        fd.append("refId", detail?.claim?.refId);
        fd.append("claimContainerId", detail?.id);

        axiosPost({
          url: "/claim-kca/upload",
          data: fd,
          callback: (res) => {
            setUploadLoading(false);
            disableNext(false);
            disableAddField(false);
            changeField(res.data, field.id);
            setShowAlert(true);
          },
          errorCallback: (err) => {
            setShowAlert(true);
            setUploadLoading(false);
            disableNext(false);
            disableAddField(false);
            setErr(err);
            console.log(err);
          },
        });
      }
    }
  };

  const handleDownload = (fileId, filename) => {
    setDownloadLoading(true);

    axiosGet({
      url: `/claim-kca/download/file-id/${fileId}`,
      responseType: "blob",
      callback: () => {
        setDownloadLoading(false);
      },
      errorCallback: (res) => {
        setDownloadLoading(false);

        let file = new File([res], filename);
        saveAs(file);
      },
    });
  };

  const handleDelete = (id, fileId) => {
    // jika file sudah diupload, hapus dari db
    if (isExist) {
      setDeleteLoading(true);
      axiosDelete({
        url: `/claim-kca/delete/file-id/${fileId}`,
        callback: () => {
          setDeleteLoading(false);
          // kemudian hapus field
          removeField(id);
        },
        errorCallback: (err) => {
          setDeleteLoading(false);
          console.log(err);
        },
      });
    } else {
      // jika belum, hanya hapus field
      removeField(id);
    }
  };

  const handleDescription = (e) => {
    clearErrors(desc);

    addDescription(e.target.value, field.id);
  };

  const handleError = () => {
    if (field.description) {
      clearErrors(desc);
    } else {
      setError(
        desc,
        {
          type: "manual",
          message: `${descLabel} wajib diisi sebelum unggah file!`,
        },
        {
          shouldFocus: true,
        }
      );
    }
  };

  return (
    <>
      <label style={{ borderBottom: "2px solid #006677" }}>
        Dokumen Lainnya
      </label>
      <Row className="mb-3 pt-2 pb-2">
        <Col md={8}>
          <label htmlFor={desc}>{descLabel}</label>
          {isExist ? (
            <>
              <StyledInput>{field.description}</StyledInput>
              <p className="mb-0">
                <small>Nama File: {isExist ? field.filename : filename}</small>{" "}
              </p>
            </>
          ) : (
            <>
              <input
                type="text"
                className="form-control form-control-sm"
                id={desc}
                name={desc}
                {...register(desc, {
                  required: `${descLabel} wajib diisi sebelum unggah file!`,
                  onChange: handleDescription,
                })}
                placeholder={descLabel}
                defaultValue=""
                style={{ width: "100%" }}
              />

              <p className="mb-0">
                <small>Nama File: {isExist ? field.filename : filename}</small>{" "}
              </p>

              {errors[desc] && (
                <>
                  <br />
                  <p className="mb-0 mt-1 text-danger small">
                    *{errors[desc].message}
                  </p>
                </>
              )}

              <p className="mb-0 mt-1 text-danger small">
                <small>
                  <p className="mb-0">*catatan</p>
                  <p className="mb-0">
                    Jenis format file yang diterima : PDF, ZIP, 7Z, dan RAR{" "}
                  </p>
                  <p className="mb-0">Maksimum ukuran file 5MB</p>
                </small>
              </p>
            </>
          )}
        </Col>
        <Col md={4}>
          {/* <label>Nama Files</label> */}
          <label className={isExist ? "mb-1" : "mb-4"}>
            <span> </span>{" "}
          </label>

          {isExist ? (
            <StyledInput>
              <div>
                <StyledButton
                  type="button"
                  disabled={downloadLoading}
                  onClick={() => handleDownload(field.fileId, field.filename)}
                  isDownload
                >
                  {downloadLoading ? (
                    <Spinner role="status" size="sm">
                      <span className="visually-hidden">Loading...</span>
                    </Spinner>
                  ) : (
                    "Unduh"
                  )}
                </StyledButton>
                <StyledButton
                  type="button"
                  onClick={() => handleDelete(field.id, field.fileId)}
                  disabled={deleteLoading || uploadLoading}
                  isDelete
                >
                  {deleteLoading ? (
                    <Spinner animation="border" size="sm" />
                  ) : (
                    <FontAwesomeIcon icon={faTrashAlt}></FontAwesomeIcon>
                  )}
                </StyledButton>
              </div>
            </StyledInput>
          ) : (
            <>
              <StyledWrapper>
                {/* {filename ?? "-"} */}
                <input
                  type="file"
                  className="form-control form-control-lg"
                  id={name}
                  name={name}
                  {...register(name, {
                    required: field.description && "Tambahkan file",
                    onChange: handleLampirkan,
                  })}
                  style={{ display: "none" }}
                  accept=".jpg, .jpeg, .png, .pdf, .mp4, .3gp, .webm, .mkv, .avi, .mpeg, .m4v, .zip, .7z, .rar"
                  disabled={!field.description}
                />
                <StyledAbsoluteInput>
                  {/* <span></span> */}

                  <div className="text-center">
                    <label htmlFor={name}>
                      <div style={{ cursor: "pointer" }} onClick={handleError}>
                        <StyledButton
                          type="button"
                          disabled={uploadLoading || isExist}
                          isUpload
                        >
                          {uploadLoading ? (
                            <Spinner animation="border" size="sm" />
                          ) : (
                            "Lampirkan"
                          )}
                        </StyledButton>
                      </div>
                    </label>

                    <StyledButton
                      type="button"
                      onClick={() => handleDelete(field.id, field.fileId)}
                      disabled={deleteLoading || uploadLoading}
                      isDelete
                    >
                      {/* {deleteLoading ? (
                        <MDBSpinner role="status" size="sm">
                          <span className="visually-hidden">Loading...</span>
                        </MDBSpinner>
                      ) : (
                        <MDBIcon fas icon="trash" />
                      )} */}
                      <FontAwesomeIcon icon={faTrashAlt}></FontAwesomeIcon>
                    </StyledButton>
                  </div>
                </StyledAbsoluteInput>
              </StyledWrapper>

              {errors[name] && (
                <p className="mb-0 mt-1 text-danger small">
                  *{errors[name].message}
                </p>
              )}
            </>
          )}
        </Col>
      </Row>
      <hr />

      <Alert
        message={`Unggah Dokumen Lainnya Berhasil!`}
        show={showAlert}
        error={err}
        handleClose={() => setShowAlert(false)}
      />
    </>
  );
};
