import React from "react";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import SearchForm from "../../components/Form/SearchForm";
import {
  Accordion,
  Container,
  Card,
  Nav,
  Button,
  Spinner,
} from "react-bootstrap";
import ScrollToHere from "../../components/Scroll/ScrollToHere";
import axiosGet from "../../helpers/axiosGet";
import DataTable from "../../components/DataTable/DataTable";
import styled from "styled-components";
import {
  resetClaim,
  setDataEorClaim,
  setSearchClaim,
} from "../../store/claim/actions";
import constants from "../../constants/constants";
import { useNavigate } from "react-router-dom";
import BannerImage from "../../components/Banner/BannerImage";
import StatusClaim from "../../locales/ListStatusClaim";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faList } from "@fortawesome/free-solid-svg-icons";
import moment from "moment";
import { saveAs } from "file-saver";

const StyledAccordion = styled(Accordion)`
  .accordion-item:last-of-type {
    border-radius: 16px;
    border: none;
  }

  .accordion-body {
    padding: 30px 40px;
  }

  .accordion-item:first-of-type .accordion-button {
    border-radius: 10px;
    background: #fff;
    border: none;
    box-shadow: none;
  }

  .accordion-item:first-of-type .accordion-button:not(.collapsed) {
    border-radius: 10px 10px 0px 0px;
    border-bottom: 1px solid #eaeaea;
  }
`;

const ExportButton = styled(Button)`
  border-radius: 20px;
  border: none;
  background: #eaeaea;
  color: #646464;
  padding: 5px 25px;
  margin-right: 10px;
`;

const HistoryExportButton = styled(Button)`
  border-radius: 50%;
  border: none;
  background: #eaeaea;
  color: #646464;
  padding: 5px 10px;
`;

const ListClaimAdmin = () => {
  const dataSearchClaim = useSelector((state) => state.claim.dataSearchClaim);
  const isAdminKca = useSelector((state) => state.user.isUserAdminKCa);
  const { storedValues, storedPage } = dataSearchClaim;
  const currentPath = window.location.pathname;

  const [dataClaim, setDataClaim] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [totalPage, setTotalPage] = useState(0);
  const [active, setActive] = useState("");
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [loadingGenerate, setLoadingGenerate] = useState(false);
  const [dataHistoryExport, setDataHistoryExport] = useState([]);
  const [isModalHistory, setModalHistory] = useState(false);
  const [loadingReport, setLoadingReport] = useState(false);
  const [indexLoad, setIndexLoad] = useState("");
  const [flag, setFlag] = useState(0);
  const [flagHistory, setFlagHistory] = useState(0);

  useEffect(() => {
    setLoading(true);
    setError(null);
    setDataEorClaim();
    axiosGet({
      url: `/claim-kca/page/${storedPage}?noClaim=${
        storedValues.noClaim
      }&policyNumber=${storedValues.policyNumber}&containerNo=${
        storedValues.containerNo
      }&certificateNo=${storedValues.certificateNo}&insured=${
        storedValues.insured
      }&accidentType=&minAmount=&maxAmount=&startDate=${
        storedValues.startDateClaim
      }&endDate=${storedValues.endDateClaim}&statusCode=${
        active !== "" ? active : storedValues.status
      }&blNo=${storedValues.blNo}&insuredReceiverName=${
        storedValues.consignee
      }&vesselName=${storedValues.vessel}&principle=${
        storedValues.principle
      }&route=${storedValues.route}&netPremium=${storedValues.premium}&type=${
        storedValues.type
      }&startSurveyDate=${storedValues.surveyDate}&endSurveyDate=${
        storedValues.surveyDate
      }`,
      callback: (res) => {
        setLoading(false);
        setDataClaim(res.data.data);
        setTotalPage(res?.data?.totalPage);
      },
      errorCallback: (err) => {
        if (
          err.statusCode &&
          (err.statusCode === constants.HTTP_STATUS_UNAUTHORIZED ||
            err.statusCode === constants.HTTP_STATUS_FAILED ||
            err.statusCode === constants.HTTP_STATUS_ERROR ||
            err.statusCode === constants.HTTP_STATUS_SERVER_ERROR)
        ) {
          navigate("/");
        }
        setLoading(false);
        setError(err);
      },
    });
  }, [
    active,
    navigate,
    storedPage,
    storedValues.noClaim,
    storedValues.policyNumber,
    storedValues.certificateNo,
    storedValues.containerNo,
    storedValues.insured,
    storedValues.blNo,
    storedValues.consignee,
    storedValues.vessel,
    storedValues.principle,
    storedValues.route,
    storedValues.premium,
    storedValues.startDateClaim,
    storedValues.endDateClaim,
    storedValues.status,
    storedValues.type,
    storedValues.surveyDate,
  ]);

  const handleGenerate = () => {
    setLoadingGenerate(true);
    axiosGet({
      url: `/claim-kca/report/generate?noClaim=${
        storedValues.noClaim
      }&policyNumber=${storedValues.policyNumber}&containerNo=${
        storedValues.containerNo
      }&certificateNo=${storedValues.certificateNo}&insured=${
        storedValues.insured
      }&accidentType=&minAmount=&maxAmount=&startDate=${
        storedValues.startDateClaim
      }&endDate=${storedValues.endDateClaim}&statusCode=${
        active !== "" ? active : storedValues.status
      }&blNo=${storedValues.blNo}&consignee=${
        storedValues.consignee
      }&vesselName=${storedValues.vessel}&principle=${
        storedValues.principle
      }&route=${storedValues.route}&netPremium=${storedValues.premium}&type=${
        storedValues.type
      }&startSurveyDate=${storedValues.surveyDate}&endSurveyDate=${
        storedValues.surveyDate
      }`,
      callback: (res) => {
        setFlag((prev) => prev + 1);
        setLoadingGenerate(false);
        setModalHistory(true);
      },
      errorCallback: (err) => {
        setLoadingGenerate(false);
        setError(err);
      },
    });
  };

  useEffect(() => {
    axiosGet({
      url: `/report/page/1?maxCount=5&source=CLAIM`,
      callback: (res) => {
        setDataHistoryExport(res.data.data);
      },
      errorCallback: (err) => {
        console.log(err);
      },
    });
  }, [flag, dispatch, flagHistory, isModalHistory]);

  useEffect(() => {
    const timer = setTimeout(() => {
      if (dataHistoryExport[0]?.status !== 100) {
        setFlagHistory((prev) => prev + 1);
      }
    }, 5000);
    return () => clearTimeout(timer);
  }, [dataHistoryExport]);

  const handleOpenModalHistory = () => {
    if (isModalHistory) {
      return setModalHistory(false);
    } else {
      return setModalHistory(true);
    }
  };

  const downloadReport = (uniqId, type) => {
    setLoadingReport(true);
    setIndexLoad(uniqId);
    axiosGet({
      url: `/report/download/${uniqId}`,
      responseType: "blob",
      callback: () => {
        setLoadingReport(false);
      },
      errorCallback: (res) => {
        setLoadingReport(false);

        let file = new File([res], `${type}`);
        saveAs(file);
      },
    });
  };

  const handleSearch = (data) => {
    ScrollToHere("search-form");
    dispatch(
      setSearchClaim({
        storedValues: data,
        storedPage: 1,
      })
    );
  };

  const handlePageClick = (e) => {
    ScrollToHere("search-form");

    const page = e.selected + 1;
    dispatch({
      type: "SET_DATA_SEARCH_CLAIM",
      payload: {
        ...dataSearchClaim,
        storedPage: page,
      },
    });
  };

  const handleReset = () => {
    ScrollToHere("search-form");
    setActive("");
    dispatch(resetClaim());
  };

  const inputs = [
    {
      name: "noClaim",
      label: "No Klaim",
      type: "text",
      placeholder: "No Klaim",
    },
    {
      name: "policyNumber",
      label: "No. Polis",
      type: "text",
      placeholder: "No. Polis",
    },
    {
      name: "certificateNo",
      label: "No Sertifikat",
      type: "text",
      placeholder: "No Sertifikat",
    },
    {
      name: "blNo",
      label: "No. BL",
      type: "text",
      placeholder: "No. BL",
    },
    {
      name: "containerNo",
      label: "No Kontainer",
      type: "text",
      placeholder: "No Kontainer",
    },
    {
      name: "insured",
      label: "Tertanggung",
      type: "text",
      placeholder: "Tertanggung",
    },
    {
      name: "consignee",
      label: "Consignee",
      type: "text",
      placeholder: "Consignee",
    },
    {
      name: "vessel",
      label: "Vessel",
      type: "text",
      placeholder: "Vessel",
    },
    {
      name: "principle",
      label: "Principle",
      type: "text",
      placeholder: "Principle",
    },
    {
      name: "route",
      label: "Rute",
      type: "text",
      placeholder: "Rute",
    },
    {
      name: "premium",
      label: "Premi",
      type: "number",
      placeholder: "Premi",
    },
    {
      name: "type",
      label: "Jenis Kerugian",
      type: "select",
      placeholder: "Jenis Kerugian",
      options: [
        {
          label: "Partial Loss",
          value: "partial-loss",
        },
        {
          label: "Total Loss",
          value: "total-loss",
        },
      ],
    },
    {
      name: "surveyDate",
      label: "Gate In Depo",
      type: "date",
      placeholder: "Gate In Depo",
    },
    // {
    //   name: "endDateClaim",
    //   label: "Tanggal Akhir Klaim",
    //   type: "date",
    //   placeholder: "Tanggal Akhir Klaim",
    // },
    {
      name: "status",
      label: "Status",
      type: "select",
      placeholder: "Status",
      options: StatusClaim,
    },
  ];

  const headers = [
    {
      title: ["No. Klaim", "No. Polis/No. Sertifikat.", "No. BL"],
    },
    {
      title: ["Insured", "No. Container"],
    },
    {
      title: ["Consignee", "Vessel", "Principle"],
    },
    {
      title: ["Route", "Gate In Depo"],
    },
    {
      title: ["Premi", "Jenis Kerugian", "Status"],
    },
  ];

  const isDirty = () => {
    const obj = { ...storedValues };

    const keys = Object.keys(obj);

    let dirty = false;
    keys.forEach((key) => {
      if (obj[key] !== "") {
        dirty = true;
      }
    });

    return dirty;
  };

  const tabs = [
    { label: "Container List In Depo", statusCode: "100" },
    { label: "Repair Approved", statusCode: "101" },
    { label: "Repair Rejected", statusCode: "400" },
    { label: "Approval MNR", statusCode: "103" },
    { label: "Done", statusCode: "102,110,120,200,130,140,150,300,310" },
  ];

  const tabsClaim = [
    { label: "All List", statusCode: "" },
    { label: "Ready to Claim", statusCode: "110" },
    { label: "Claim Requested", statusCode: "120,130,140,150,200,300" },
    { label: "Finish Claim", statusCode: "310" },
  ];

  return (
    <section>
      <Container className="py-4">
        <BannerImage isAdmin={isAdminKca} />
        <div id="search-form">
          <StyledAccordion title="Search" isDirty={isDirty()}>
            <Accordion.Item eventKey="0">
              <Accordion.Header>Cari</Accordion.Header>
              <Accordion.Body>
                <SearchForm
                  inputs={inputs}
                  defaultValues={storedValues}
                  handleSearch={handleSearch}
                  handleReset={handleReset}
                />
              </Accordion.Body>
            </Accordion.Item>
          </StyledAccordion>
        </div>

        <div id="tab-page" className="mt-5">
          <Card
            className="w-100"
            style={{ border: "none", borderRadius: "10px" }}
          >
            <Card.Body>
              <Nav justify variant="pills">
                {currentPath === "/claim"
                  ? tabsClaim.map((item, index) => (
                      <Nav.Item key={index}>
                        <Nav.Link
                          eventKey={`link-${index}`}
                          onClick={() => setActive(item.statusCode)}
                        >
                          {item.label}
                        </Nav.Link>
                      </Nav.Item>
                    ))
                  : tabs.map((item, index) => (
                      <Nav.Item key={index}>
                        <Nav.Link
                          eventKey={`link-${index}`}
                          onClick={() => setActive(item.statusCode)}
                        >
                          {item.label}
                        </Nav.Link>
                      </Nav.Item>
                    ))}
              </Nav>
            </Card.Body>
          </Card>
        </div>

        <div className="text-end mt-3">
          {!loading && (
            <div className="export-wrapper">
              <ExportButton onClick={handleGenerate} disabled={loadingGenerate}>
                Export{" "}
                {loadingGenerate && <Spinner animation="border" size="sm" />}
              </ExportButton>
              <HistoryExportButton onClick={handleOpenModalHistory}>
                <FontAwesomeIcon icon={faList}></FontAwesomeIcon>
              </HistoryExportButton>

              {isModalHistory && (
                <div className="export-history">
                  {dataHistoryExport.length > 0 ? (
                    <>
                      {dataHistoryExport?.map((item) => (
                        <div className="history-content">
                          <div className="history-title">
                            {item?.filename},{" "}
                            {moment(item?.createdDate).format("DD MMMM YYYY")}
                          </div>
                          <Button
                            onClick={() =>
                              downloadReport(item?.uniqId, item?.filename)
                            }
                            disabled={item?.status !== 100 || loadingReport}
                            className={`history-badge ${
                              item?.status === 100 ? "complete" : "waiting"
                            }`}
                          >
                            {item?.status === 100 &&
                            loadingReport &&
                            indexLoad === item?.uniqId
                              ? "Memuat..."
                              : item?.status === 100
                              ? "Unduh"
                              : "Menunggu Generate"}
                          </Button>
                        </div>
                      ))}
                    </>
                  ) : (
                    <>
                      <div className="history-content">
                        <div className="history-title">Tidak Ada History</div>
                      </div>
                    </>
                  )}
                </div>
              )}
            </div>
          )}
        </div>

        <div className="mt-3">
          <DataTable
            active="list-claim"
            headers={headers}
            data={dataClaim}
            loading={loading}
            error={error}
            totalPage={totalPage}
            onPageChange={handlePageClick}
            page={storedPage}
            isAdminKca={isAdminKca}
          />
        </div>
      </Container>
    </section>
  );
};

export default ListClaimAdmin;
