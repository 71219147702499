import { Type } from "react-bootstrap-table2-editor";
import CurrencyFormat from "./CurrencyFormat";

const dataFieldEorMap = {
  COMP: "comp",
  LOC: "lcNo",
  DMG: "dc",
  REP: "rc",
  "WIDTH | LENGTH": "dms",
  QTY: "qty",
  PY: "resp",
  HOUR: "hr",
  LABOUR: "labour",
  MATERIAL: "matl",
  TOTAL: "subTtl",
  "COMPONENT NAME": "compName",
  LC: "lcNo",
  DC: "dc",
  RC: "rc",
  DMS: "dms",
  RESP: "resp",
  HR: "hr",
  "LABOUR RATE": "labour",
  MATL: "matl",
  SUBTTL: "subTtl",
};

export default function CreateColumnEor(header) {
  const dataField = dataFieldEorMap[header];
  const column = {
    dataField,
    text: header,
  };

  if (header === "COMPONENT NAME") {
    column.headerStyle = { width: "10%" };
    column.editor = { type: Type.TEXTAREA };
  }

  if (header === "WIDTH | LENGTH" || header === "MATERIAL") {
    column.headerStyle = { width: "8%" };
    column.editor = { type: Type.TEXTAREA };
  }

  if (header === "GRN TTL" || header === "TOTAL") {
    column.headerStyle = { width: "7%" };
    column.formatter = CurrencyFormat;
  }

  return column;
}
