import React from "react";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";

export default function TableContainer({ data, cols, eor, form, history }) {
  const columns = [
    {
      dataField: "containerNo",
      text: "Nomor Container",
      headerAlign: "center",
      align: "center",
    },
  ];

  const customTotal = (from, to, size) => (
    <span
      className="react-bootstrap-table-pagination-total"
      style={{ fontSize: "12px" }}
    >
      {" "}
      Menampilkan {from} hingga {to} dari {size} Hasil
    </span>
  );

  const expandRowOptions =
    eor && form
      ? {}
      : eor
      ? {
          expandRow: {
            renderer: (row) => (
              <div>
                <p className="mb-0 text-danger">Note: {row.note ?? "-"}</p>
              </div>
            ),
            expanded: data
              .filter((item) => item.note !== null)
              .map((item) => item.id),
            showExpandColumn: true,
            expandByColumnOnly: true,
          },
        }
      : history
      ? {
          expandRow: {
            renderer: (row) => (
              <div>
                <p
                  className={`mb-0 ${
                    row.isSelected ? "text-success" : "text-danger"
                  } `}
                >
                  Note:{" "}
                  <strong>
                    {row.isSelected ? "DISETUJUI" : "TIDAK DISETUJUI"}
                  </strong>
                </p>
              </div>
            ),
            expanded: data
              .filter((item) => item.isSelected !== null)
              .map((item) => item.id),
            showExpandColumn: true,
            expandByColumnOnly: true,
          },
        }
      : {};

  const options = {
    paginationSize: 4,
    pageStartIndex: 1,
    // firstPageText: "First",
    // prePageText: "Sebelumnya",
    // nextPageText: "Selanjutnya",
    // lastPageText: "Terakhir",
    alwaysShowAllBtns: true,
    withFirstAndLast: false,
    hideSizePerPage: true,
    hidePageListOnlyOnePage: true,
    nextPageTitle: "Halaman Pertama",
    prePageTitle: "Pre page",
    firstPageTitle: "Halaman Selanjutnya",
    lastPageTitle: "Halaman Terakhir",
    showTotal: true,
    paginationTotalRenderer: customTotal,
    disablePageTitle: true,
    sizePerPageList: [
      {
        text: "5",
        value: 5,
      },
      {
        text: "10",
        value: 10,
      },
      {
        text: "All",
        value: data.length,
      },
    ],
  };

  return (
    <BootstrapTable
      keyField="id"
      data={data}
      columns={
        eor || history
          ? cols.filter((column) => column.text !== "ACTION")
          : columns
      }
      {...expandRowOptions}
      striped
      hover
      condensed
      pagination={paginationFactory(options)}
      noDataIndication="Data tidak ditemukan"
    />
  );
}
