import React, { useState } from "react";
import { Button, Spinner } from "react-bootstrap";
import axiosPut from "../../helpers/axiosPut";
import Alert from "../Alert/Alert";

export default function SubmitToInsurance({ detail }) {
  const [loading, setLoading] = useState(false);
  const [show, setShow] = useState(false);
  const [error, setError] = useState(null);

  const submitClaim = () => {
    setLoading(true);
    const body = {
      noClaim: detail.noClaim,
      description: "Submit initial to insurance",
      metadata: "",
    };
    axiosPut({
      url: `/claim-kca/submit-initial-to-insurance`,
      data: body,
      callback: (res) => {
        setShow(true);
        setLoading(false);
        setTimeout(() => {
          window.location.reload();
        }, 3000);
      },
      errorCallback: (err) => {
        setLoading(false);
        setShow(true);
        setError(err);
      },
    });
  };
  return (
    <div>
      <Button
        type="button"
        color="primary"
        size="small"
        className="text-center action-button w-100 mt-4"
        onClick={submitClaim}
        disabled={loading}
      >
        {loading ? (
          <Spinner animation="border" size="sm" />
        ) : (
          "Teruskan Perbaikan ke Asuransi"
        )}
      </Button>
      <Alert
        message="Submit Perbaikan Ke Asuransi Berhasil!"
        show={show}
        error={error}
        handleClose={() => setShow(false)}
      />
    </div>
  );
}
