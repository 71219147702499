import React, { useEffect, useState } from "react";
import { Button, Card, Col, Form, Row } from "react-bootstrap";
import axiosGet from "../../helpers/axiosGet";
import { Link, useParams, useNavigate } from "react-router-dom";
import "./Claim.css";
import SpinnerPrimary from "../../components/Spinner/Spinner";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft, faArrowRight } from "@fortawesome/free-solid-svg-icons";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import { useForm } from "react-hook-form";
import { setClaimSubmission } from "../../store/claim/actions";
import moment from "moment";
import "moment/locale/id";
import getBase64 from "../../helpers/getBase64";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import "react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css";
import BannerImage from "../../components/Banner/BannerImage";

const StyledInput = styled.div`
  /* background: #ffffff; */
  background: ${({ isExist }) => (isExist ? "#f5f5f5" : "#ffffff")};
  border: 1px solid #bcbcbc;
  border-radius: 5px;
  padding: 15px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 1rem;

  span {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  div {
    display: flex;
    align-items: center;
    gap: 0.5rem;
  }
`;

const StyledButton = styled.button`
  padding: 2px 20px;
  font-size: 16px;
  font-weight: 700;
  border-radius: 30px;
  color: white;
  min-height: 1.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  background: ${(props) =>
    props.isDelete ? "#F93154" : props.isDownload ? "#ADDFFF" : "#27AE60"};
  border: none;
  pointer-events: ${(props) => props.isUpload && "none"};
  min-width: ${(props) => (props.isDelete ? "40px" : "90px")};

  :disabled {
    background: #757575;
  }

  i {
    pointer-events: none;
  }
`;

export default function ClaimSubmission() {
  const dataClaim = useSelector((state) => state.claim.dataClaimSubmission);
  const isAdminKca = useSelector((state) => state.user.isUserAdminKCa);
  const dataUser = useSelector((state) => state.user.user);
  const [loading, setLoading] = useState(false);
  const [error, setErr] = useState(null);

  const params = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [detail, setDetail] = useState();
  const [containerList, setContainerList] = useState([]);
  // const [containerId, setContainerId] = useState(dataClaim?.containerId ?? []);
  // const [containerNo, setContainerNo] = useState(dataClaim?.containerNo ?? []);
  // const [selectedRows, setSelectedRows] = useState(dataClaim?.details ?? []);
  // const [dataContainer, setDataContainer] = useState(
  //   dataClaim?.dataContainer ?? []
  // );
  const [filenameClaimDoc, setNameClaimDoc] = useState(
    dataClaim?.filenameClaimDoc ?? null
  );
  const [filenameReportDoc, setNameReportDoc] = useState(
    dataClaim?.filenameReportDoc ?? null
  );

  const {
    // control,
    handleSubmit,
    register,
    formState: { errors },
    watch,
    setError,
    clearErrors,
  } = useForm({ defaultValues: dataClaim });

  const claimDocValue = watch("claimDoc");
  const reportDocValue = watch("reportDoc");

  const decode = window.atob(params.id);

  useEffect(() => {
    setLoading(true);
    axiosGet({
      url: `/claim-cargo-container/uniq-id/${decode}`,
      callback: (res) => {
        setLoading(false);
        setContainerList(
          res?.data?.attachment?.filter((item) => item.isClaimed === false)
        );
        setDetail(res.data);
      },
      errorCallback: (err) => {
        setLoading(false);
        setErr(err);
        console.log(error);
      },
    });
  }, [decode, error]);

  const handleFileClaimDoc = (e) => {
    const file = e.target.files[0];
    setNameClaimDoc(file.name);
    if (file.size > 2_000_000) {
      setError("claimDoc", {
        type: "manual",
        message: `ukuran file maksimal 2MB.`,
        shouldFocus: true,
      });
    } else {
      clearErrors("claimDoc");
      getBase64(file, "claimDoc");
    }
  };

  const handlePoliceReport = (e) => {
    const file = e.target.files[0];
    setNameReportDoc(file.name);

    if (file.size > 2_000_000) {
      setError("reportDoc", {
        type: "manual",
        message: `ukuran file maksimal 2MB.`,
        shouldFocus: true,
      });
    } else {
      clearErrors("reportDoc");
      getBase64(file, "reportDoc");
    }
  };

  // const columns = [
  //   {
  //     dataField: "containerNo",
  //     text: "Nomor Container",
  //     headerAlign: "center",
  //     align: "center",
  //   },
  //   {
  //     dataField: "sizeType",
  //     text: "Size Type",
  //     headerAlign: "center",
  //     align: "center",
  //   },
  // ];

  // const customTotal = (from, to, size) => (
  //   <span
  //     className="react-bootstrap-table-pagination-total"
  //     style={{ fontSize: "12px" }}
  //   >
  //     {" "}
  //     Menampilkan {from} hingga {to} dari {size} Hasil
  //   </span>
  // );

  // const options = {
  //   paginationSize: 4,
  //   pageStartIndex: 1,
  //   firstPageText: "First",
  //   prePageText: "Sebelumnya",
  //   nextPageText: "Selanjutnya",
  //   lastPageText: "Terakhir",
  //   nextPageTitle: "Halaman Pertama",
  //   prePageTitle: "Pre page",
  //   firstPageTitle: "Halaman Selanjutnya",
  //   lastPageTitle: "Halaman Terakhir",
  //   showTotal: true,
  //   paginationTotalRenderer: customTotal,
  //   disablePageTitle: true,
  //   sizePerPageList: [
  //     {
  //       text: "5",
  //       value: 5,
  //     },
  //     {
  //       text: "10",
  //       value: 10,
  //     },
  //     {
  //       text: "All",
  //       value: containerList.length,
  //     },
  //   ],
  // };
  // const handleOnSelect = (row, isSelect) => {
  //   if (isSelect) {
  //     setContainerId([...containerId, row.id]);
  //     setContainerNo([...containerNo, row.containerNo]);
  //     setDataContainer([...dataContainer, row]);
  //     setSelectedRows([
  //       ...selectedRows,
  //       {
  //         id: row.id,
  //         sizeType: row.sizeType,
  //       },
  //     ]);
  //     clearErrors("containerList");
  //   } else {
  //     setContainerId(containerId.filter((item) => item !== row.id));
  //     setContainerNo(containerNo.filter((item) => item !== row.containerNo));
  //     setSelectedRows(
  //       selectedRows.filter((selectedRow) => selectedRow.id !== row.id)
  //     );
  //     setDataContainer(
  //       dataContainer.filter((item) => item.containerNo !== row.containerNo)
  //     );
  //   }
  // };

  // const handleOnSelectAll = (isSelect, rows) => {
  //   const conId = rows.map((r) => r.id);
  //   const conNo = rows.map((r) => r.containerNo);
  //   const dataCont = rows.map((r) => r);
  //   const allRows = rows.map((r) => ({
  //     id: r.id,
  //     sizeType: r.sizeType,
  //   }));
  //   if (isSelect) {
  //     setContainerId(conId);
  //     setContainerNo(conNo);
  //     setSelectedRows(allRows);
  //     setDataContainer(dataCont);
  //     clearErrors("containerList");
  //   } else {
  //     setContainerId([]);
  //     setContainerNo([]);
  //     setSelectedRows([]);
  //     setDataContainer([]);
  //   }
  // };

  const onSubmit = (data) => {
    const container = containerList.find((item) => item.id === data.container);

    const today = moment().format("YYYY-MM-DD");
    data.nextProgressDeadlineDate = today;
    data.picName = dataUser?.userFullname ?? dataUser?.username;
    data.picEmail = dataUser?.userEmail;
    data.picPhoneNumber = dataUser?.userPhoneNumber;
    data.conveyance = detail.vesselName;
    data.publishDate = today;
    data.declarationUniqId = detail.uniqId;
    data.companyName = detail.companyName;
    data.insuranceCode = detail.insurerCode;
    data.policyNumber = detail.policyNumber;
    data.certificateNo = detail.certificateNumber;
    data.containerId = data.container;
    data.containerNo = container.containerNo;
    data.sizeType = container.sizeType;
    data.filenameClaim = filenameClaimDoc;
    data.filenameReport = filenameReportDoc;
    data.consigneeLocation = dataUser?.address;
    data.insuredReceiverName = detail?.insuredReceiverName;

    dispatch(setClaimSubmission(data));
    // navigate(`/perbaikan-kontainer/submission/summary`);
    navigate(`/v2/perbaikan/submission/summary`);
  };

  return loading ? (
    <div
      className="d-flex justify-content-center align-items-center"
      style={{ minHeight: "100vh" }}
    >
      <SpinnerPrimary />
    </div>
  ) : (
    <div className="container">
      <BannerImage isAdmin={isAdminKca} />

      <h5 className="pl-4 pr-4 mt-2 mb-3 title-header-left">
        Form Pengajuan Perbaikan
      </h5>
      <Form
        className="form-submission"
        onSubmit={handleSubmit(onSubmit)}
        noValidate
      >
        <Row>
          <Col xs={12} md={12}>
            <Card className="card-wrapper p-4 mb-4">
              <Card.Body className="main-card">
                <Form.Group
                  className="mb-3"
                  controlId="exampleForm.ControlInput1"
                >
                  <Form.Label>
                    Silakan Pilih Kontainer{" "}
                    <span className="text-danger">*</span>{" "}
                  </Form.Label>
                  {/* <Controller
                    control={control}
                    name="containerList"
                    {...register("containerList")}
                    render={({ field }) => (
                      <BootstrapTable
                        {...field}
                        name={field.name}
                        id={field.name}
                        value={field.value}
                        keyField="id"
                        data={containerList}
                        columns={columns}
                        selectRow={selectRow}
                        striped
                        hover
                        condensed
                        onBlur={field.onBlur}
                        isInvalid={errors.containerList}
                        getInputRef={field.ref}
                        pagination={paginationFactory(options)}
                      />
                    )}
                  /> */}
                  <Form.Select
                    name="container"
                    {...register(`container`, {
                      required: "Pilih kontainer terlebih dahulu!",
                    })}
                  >
                    <option value="" disabled selected>
                      -- Pilih Kontainer --
                    </option>
                    {containerList.map((item, index) => (
                      <option value={item.id} key={index}>
                        {item.containerNo} ({item.sizeType})
                      </option>
                    ))}
                  </Form.Select>
                  {errors["container"] && (
                    <p className="mb-0 mt-1 text-danger small">
                      *{errors["container"].message}
                    </p>
                  )}
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Label>
                    Nama PIC <span className="text-danger">*</span>{" "}
                  </Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Nama PIC"
                    value={dataUser?.userFullname ?? dataUser?.username}
                    name="picName"
                    {...register("picName")}
                    disabled
                  />
                  {errors["picName"] && (
                    <p className="mb-0 mt-1 text-danger small">
                      *{errors["picName"].message}
                    </p>
                  )}
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Label>
                    Email PIC <span className="text-danger">*</span>{" "}
                  </Form.Label>
                  <Form.Control
                    type="email"
                    placeholder="Email PIC"
                    value={dataUser?.userEmail}
                    name="picEmail"
                    {...register("picEmail", {
                      pattern: {
                        value: /\S+@\S+\.\S+/,
                        message: "email tidak valid!",
                      },
                    })}
                    disabled
                  />
                  {errors["picEmail"] && (
                    <p className="mb-0 mt-1 text-danger small">
                      *{errors["picEmail"].message}
                    </p>
                  )}
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Label>
                    Nomor Telepon PIC <span className="text-danger">*</span>{" "}
                  </Form.Label>
                  <Form.Control
                    type="number"
                    placeholder="Nomor Telepon PIC"
                    value={dataUser?.userPhoneNumber}
                    name="picPhoneNumber"
                    {...register("picPhoneNumber")}
                    disabled
                  />
                  {errors["picPhoneNumber"] && (
                    <p className="mb-0 mt-1 text-danger small">
                      *{errors["picPhoneNumber"].message}
                    </p>
                  )}
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Label>
                    Lokasi Kejadian <span className="text-danger">*</span>{" "}
                  </Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Lokasi Kejadian"
                    name="accidentLocation"
                    {...register("accidentLocation", {
                      required: "Lokasi kejadian wajib diisi!",
                    })}
                  />
                  {errors["accidentLocation"] && (
                    <p className="mb-0 mt-1 text-danger small">
                      *{errors["accidentLocation"].message}
                    </p>
                  )}
                </Form.Group>

                <label htmlFor="claimDoc" className="mb-2 mt-2">
                  Upload Formulir Klaim <span className="text-danger">*</span>{" "}
                </label>
                <input
                  type="file"
                  className="form-control form-control-lg"
                  id="claimDoc"
                  name="claimDoc"
                  {...register("claimDoc", {
                    onChange: handleFileClaimDoc,
                    required: dataClaim.filenameClaimDoc
                      ? false
                      : "Formulir Klaim wajib diupload!",
                  })}
                  style={{ display: "none" }}
                  accept={
                    ".doc, .docx, .pdf, .jpg, .jpeg, .png, .zip, .7z, .rar"
                  }
                />

                <StyledInput>
                  <span>
                    {claimDocValue?.length
                      ? claimDocValue[0].name
                      : dataClaim.filenameClaimDoc
                      ? dataClaim.filenameClaimDoc
                      : "Pilih file"}
                  </span>
                  <div>
                    <label htmlFor="claimDoc">
                      <div style={{ cursor: "pointer" }}>
                        <StyledButton type="button" isUpload>
                          Lampirkan
                        </StyledButton>
                      </div>
                    </label>
                  </div>
                </StyledInput>

                {errors["claimDoc"] && (
                  <p className="mb-0 mt-1 text-danger small">
                    *{errors["claimDoc"].message}
                  </p>
                )}

                <Form.Group>
                  <Form.Label htmlFor="reportDoc" className="mb-2 mt-2">
                    Laporan kepolisian *hanya untuk kasus kehilangan
                  </Form.Label>
                  <Form.Control
                    type="file"
                    className="form-control form-control-lg"
                    id="reportDoc"
                    name="reportDoc"
                    {...register("reportDoc", {
                      onChange: handlePoliceReport,
                    })}
                    hidden
                    accept={
                      ".doc, .docx, .pdf, .jpg, .jpeg, .png, .zip, .7z, .rar"
                    }
                  />

                  <StyledInput>
                    <span>
                      {reportDocValue?.length
                        ? reportDocValue[0].name
                        : dataClaim.filenameReportDoc
                        ? dataClaim.filenameReportDoc
                        : "Pilih file"}
                    </span>
                    <div>
                      <Form.Label htmlFor="reportDoc">
                        <div style={{ cursor: "pointer" }}>
                          <StyledButton type="button" isUpload>
                            Lampirkan
                          </StyledButton>
                        </div>
                      </Form.Label>
                    </div>
                  </StyledInput>
                </Form.Group>
              </Card.Body>
            </Card>
          </Col>

          {/* <Col xs={12} md={4}>
            <DetailCertificate detail={detail} />
          </Col> */}
        </Row>

        <Row className="mt-5">
          <Col lg={12} className="d-flex justify-content-between">
            <Link
              to={isAdminKca ? "/perbaikan" : "/perbaikan-kontainer"}
              style={{ textDecoration: "none" }}
            >
              <Button
                type="button"
                color="primary"
                size="small"
                className="d-flex justify-content-between align-items-center back-button"
              >
                <FontAwesomeIcon icon={faArrowLeft}></FontAwesomeIcon>
                KEMBALI
              </Button>
            </Link>
            <Button
              type="submit"
              color="primary"
              size="small"
              className="d-flex justify-content-between align-items-center back-button"
              disabled={
                errors["claimDoc"] || errors["reportDoc"] ? true : false
              }
            >
              SELANJUTNYA{" "}
              <FontAwesomeIcon icon={faArrowRight}></FontAwesomeIcon>
            </Button>
          </Col>
        </Row>
      </Form>
    </div>
  );
}
