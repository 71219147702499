export function StatusContainerFormat(value) {
  switch (value) {
    case "available":
      return "Tersedia (available)";
    case "damaged":
      return "Rusak (damaged)";
    default:
      return "-";
  }
}
