const initialState = {
  dataClaimSubmission: [],
  dataSearchClaim: {
    storedValues: {
      noClaim: "",
      policyNumber: "",
      certificateNo: "",
      containerNo: "",
      blNo: "",
      consignee: "",
      vessel: "",
      principle: "",
      route: "",
      premium: "",
      insured: "",
      minAmount: "",
      maxAmount: "",
      startDateClaim: "",
      endDateClaim: "",
      status: "",
      type: "",
      surveyDate: "",
    },
    storedPage: 1,
  },
  dataEorClaim: [],
};

const claimReducer = (state = initialState, action) => {
  switch (action.type) {
    case "SET_DATA_CLAIM_SUBMISSION":
      return {
        ...state,
        dataClaimSubmission: action.payload,
      };
    case "SET_DATA_SEARCH_CLAIM":
      return {
        ...state,
        dataSearchClaim: action.payload,
      };
    case "SET_DATA_EOR_CLAIM":
      return {
        ...state,
        dataEorClaim: action.payload,
      };
    case "RESET_CLAIM":
      return (state = initialState);
    default:
      return state;
  }
};

export default claimReducer;
