import status from "@provis/provis-common-be-module/dist/constants/claim/status";

function StatusFormat(statusCode, lang) {
  for (const key in status) {
    if (status[key].code === statusCode) {
      return status[key].desc[lang];
    }
  }

  return null;
}

export default StatusFormat;
