import React, { Fragment, useState } from "react";
import axiosGet from "../../helpers/axiosGet";
import { saveAs } from "file-saver";
import { Button, Spinner } from "react-bootstrap";
import { FilenameFormat } from "../Formating/DocFormat";

export default function DownloadDocClaim({
  data,
  url,
  noClaim,
  isAdminKca,
  isContainer,
  containerDetails,
}) {
  const [loadingDownload, setLoadingDownload] = useState(null);

  const downloadDoc = (
    fileId,
    type,
    originalName,
    filename,
    claimContainerDetailId
  ) => {
    setLoadingDownload(fileId);
    axiosGet({
      url: `${
        type === "DOC_INVOICE"
          ? `/claim-kca/download/invoice/${claimContainerDetailId}`
          : `/claim-kca/download/file-id/${fileId}`
      }`,
      responseType: "blob",
      callback: (res) => {
        setLoadingDownload("");
      },
      errorCallback: (res) => {
        setLoadingDownload("");
        let file = new File(
          [res],
          `${type === "DOC_INVOICE" ? filename : type + originalName}`
        );
        saveAs(file);
      },
    });
  };

  const getContainerNo = (claimContainerDetailId) => {
    const detail = containerDetails?.find(
      (detail) => detail.id === claimContainerDetailId
    );
    return detail ? detail.containerNo : null;
  };

  let datas = data;

  const docWithoutContainerInv = data?.filter(
    (doc) => doc.type !== "DOC_INVOICE" && doc.claimContainerDetailId === null
  );

  const docWithoutContainer = data?.filter(
    (doc) => doc.claimContainerDetailId === null
  );

  const docWithContainer = data?.filter(
    (doc) => doc.claimContainerDetailId !== null
  );

  const docWithContainerInv = data?.filter(
    (doc) => doc.claimContainerDetailId !== null && doc.type !== "DOC_INVOICE"
  );

  if (isAdminKca) {
    datas = isContainer ? docWithContainer : docWithoutContainer;
  } else {
    datas = isContainer ? docWithContainerInv : docWithoutContainerInv;
  }

  return (
    <Fragment>
      {datas?.length > 0 ? (
        <>
          {datas?.map((item, index) => (
            <div key={index}>
              <div className="d-flex justify-content-between">
                <div>
                  {FilenameFormat(item?.type)}{" "}
                  {item?.claimContainerDetailId !== null && (
                    <strong>
                      ({getContainerNo(item?.claimContainerDetailId)})
                    </strong>
                  )}
                  <div>
                    <small style={{ color: "#6b6b6b" }}>
                      {item?.type === "DOC_OTHER" && item?.description}
                    </small>
                  </div>
                </div>
                <div>
                  <Button
                    type="button"
                    className="download-doc"
                    onClick={() =>
                      downloadDoc(
                        item.fileId,
                        item?.type,
                        item.originalName,
                        item?.filename,
                        item?.claimContainerDetailId
                      )
                    }
                    disabled={loadingDownload}
                  >
                    {loadingDownload === item.fileId ? (
                      <Spinner
                        animation="border"
                        style={{ width: "15px", height: "15px" }}
                      />
                    ) : (
                      "Unduh"
                    )}
                  </Button>
                </div>
              </div>
              <hr />
            </div>
          ))}
        </>
      ) : (
        <div className="d-flex justify-content-between">Belum ada lampiran</div>
      )}
    </Fragment>
  );
}
