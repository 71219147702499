import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useRef, useState } from "react";
import {
  Button,
  Card,
  Col,
  Container,
  Form,
  Row,
  Spinner,
} from "react-bootstrap";
import { useForm } from "react-hook-form";
import Alert from "../../components/Alert/Alert";
import axiosPost from "../../helpers/axiosPost";
import "./UpdatePassword.css";

export default function UpdatePassword() {
  const [error, setError] = useState("");
  const [show, setShow] = useState(false);
  const [loading, setLoading] = useState(false);
  const [showOldPassword, setShowOldPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const passwordRef = useRef("");
  const confirmPasswordRef = useRef("");

  const {
    handleSubmit,
    register,
    formState: { errors },
    setValue,
  } = useForm();

  const handleToggleShow = (setter) => () => setter((prev) => !prev);

  const validateConfirmPassword = (value) =>
    value === passwordRef.current || "Password baru tidak sama";

  const handleNext = (data) => {
    const body = {
      oldPassword: data.oldPassword,
      newPassword: data.newPassword,
      confirmPassword: data.confirmPassword,
    };

    setLoading(true);
    axiosPost({
      url: `/sso/change-password`,
      data: body,
      callback: (res) => {
        setLoading(false);
        setShow(true);
        setTimeout(() => {
          window.location.reload();
        }, 4000);
      },
      errorCallback: (err) => {
        setLoading(false);
        setError(err);
        setShow(true);
        setTimeout(() => {
          setError(false);
        }, 5000);
      },
    });
  };

  return (
    <>
      <Alert
        message="Ganti Password Berhasil!"
        show={show}
        error={error}
        handleClose={() => setShow(false)}
      />

      <Container className="py-5 d-flex justify-content-center">
        <Row className="change-password-wrapper">
          <Col lg={12}>
            <Card className="card-wrapper p-4 mb-4">
              <Card.Header className="card-main-header">
                Change Password
              </Card.Header>
              <Card.Body className="main-card">
                <Form onSubmit={handleSubmit(handleNext)}>
                  <Row>
                    <Col xs={12} md={12} lg={12} className="form-login">
                      <Form.Label className="form-label">
                        Password Lama <span>*</span>
                      </Form.Label>
                      <Form.Group>
                        <div className="input-current-wp">
                          <Form.Control
                            className="form-input"
                            type={!showOldPassword ? "password" : "text"}
                            name="oldPassword"
                            placeholder="Masukan password lama"
                            {...register("oldPassword", {
                              required: "Password Harus Diisi!",
                            })}
                          />
                          <div
                            className="show-password"
                            onClick={handleToggleShow(setShowOldPassword)}
                          >
                            <FontAwesomeIcon
                              icon={showOldPassword ? faEyeSlash : faEye}
                            />
                          </div>
                        </div>
                        <p className="text-danger error-message">
                          {errors.oldPassword?.message}
                        </p>
                      </Form.Group>
                      <Form.Label className="form-label">
                        Password Baru <span>*</span>
                      </Form.Label>
                      <Form.Group>
                        <div className="input-current-wp">
                          <Form.Control
                            className="form-input"
                            type={!showNewPassword ? "password" : "text"}
                            name="newPassword"
                            placeholder="Masukan password baru"
                            {...register("newPassword", {
                              required: "Password Baru Harus Diisi!",
                              onChange: (e) => {
                                setValue("newPassword", e.target.value);
                                passwordRef.current = e.target.value;
                              },
                            })}
                          />
                          <div
                            className="show-password"
                            onClick={handleToggleShow(setShowNewPassword)}
                          >
                            <FontAwesomeIcon
                              icon={showNewPassword ? faEyeSlash : faEye}
                            />
                          </div>
                        </div>
                        <p className="text-danger error-message">
                          {errors.newPassword?.message}
                        </p>
                      </Form.Group>
                      <Form.Label className="form-label">
                        Konfirmasi Password <span>*</span>
                      </Form.Label>
                      <Form.Group>
                        <div className="input-current-wp">
                          <Form.Control
                            className="form-input"
                            type={!showConfirmPassword ? "password" : "text"}
                            name="confirmPassword"
                            placeholder="Masukan konfirmasi password"
                            {...register("confirmPassword", {
                              required: "Konfirmasi Password Harus Diisi!",
                              validate: validateConfirmPassword,
                              onChange: (e) => {
                                setValue("confirmPassword", e.target.value);
                                confirmPasswordRef.current = e.target.value;
                              },
                            })}
                          />
                          <div
                            className="show-password"
                            onClick={handleToggleShow(setShowConfirmPassword)}
                          >
                            <FontAwesomeIcon
                              icon={showConfirmPassword ? faEyeSlash : faEye}
                            />
                          </div>
                        </div>
                        <p className="text-danger error-message">
                          {errors.confirmPassword?.message}
                        </p>
                      </Form.Group>
                      <div className="footer">
                        <Row>
                          <Col>
                            <div className="btn-wrapper">
                              <Button
                                type="submit"
                                className="btn btn-submit submit-button"
                                disabled={loading}
                              >
                                Ganti Password
                                {loading && (
                                  <Spinner
                                    style={{ marginLeft: "8px" }}
                                    animation="border"
                                    variant="light"
                                    size="sm"
                                  />
                                )}
                              </Button>
                            </div>
                          </Col>
                        </Row>
                      </div>
                    </Col>
                  </Row>
                </Form>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
}
