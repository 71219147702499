import React from "react";
import BannerAdmin from "../../images/banner-admin.svg";
import BannerUser from "../../images/banner-user.svg";

export default function BannerImage({ isAdmin }) {
  return (
    <div className="mt-5 mb-5">
      <div className="d-flex justify-content-center">
        <img
          src={isAdmin ? BannerAdmin : BannerUser}
          alt=""
          className="w-100 mt-5"
        />
      </div>
    </div>
  );
}
