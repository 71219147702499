import { Controller } from "react-hook-form";
import NumberFormat from "react-number-format";
import "./FormInput.css";
import React from "react";
import moment from "moment";

const FormInput = ({ name, type, placeholder, register, options, control }) => {
  return (
    <FormInputType
      name={name}
      type={type}
      placeholder={placeholder}
      options={options}
      register={register}
      control={control}
    />
  );
};

const FormInputType = ({
  name,
  type,
  placeholder,
  options,
  register,
  control,
}) => {
  switch (type) {
    case "select":
      return (
        <select
          className="browser-default custom-select form-control"
          {...register(name)}
          style={{ fontSize: "14px", color: "#939393", textAlign: "center" }}
        >
          <option value="" disabled>
            {placeholder}
          </option>
          {options.map((option, index) => (
            <option key={index} value={option.value}>
              {option.label}
            </option>
          ))}
        </select>
      );

    case "date":
      return (
        <input
          type="text"
          placeholder={placeholder}
          className="form-control"
          max={
            name === "expiredDate" ? undefined : moment().format("YYYY-MM-DD")
          }
          {...register(name)}
          onFocus={(e) => (e.target.type = "date")}
          onBlur={(e) => (e.target.type = "text")}
          id={name}
        />
      );

    case "number":
      return (
        <Controller
          control={control}
          {...register(name)}
          render={({ field }) => (
            <NumberFormat
              {...field}
              name={field.name}
              id={field.name}
              thousandSeparator="."
              decimalSeparator=","
              value={field.value}
              onValueChange={(value) => {
                return field.onChange(value.formattedValue);
              }}
              onBlur={field.onBlur}
              className="form-control"
              getInputRef={field.ref}
              placeholder={placeholder}
            />
          )}
        />
      );

    default:
      return (
        <input
          type={type}
          className="form-control"
          id={name}
          placeholder={placeholder}
          {...register(name)}
        />
      );
  }
};

export default FormInput;
