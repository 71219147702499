import moment from "moment";
import "moment/locale/id";
import React from "react";
import { Accordion, Col, Row } from "react-bootstrap";
import styled from "styled-components";
import StatusFormat from "../Formating/StatusFormat";

const StyledAccordion = styled(Accordion)`
  .accordion-item:last-of-type {
    border-radius: 16px;
    border: none;
    box-shadow: #e0e0ec66 0px 0px 10px 10px;
  }

  .accordion-body {
    padding: 30px 40px;
  }

  .accordion-item:first-of-type .accordion-button {
    border-radius: 13px;
    color: #646464 !important;
  }

  .accordion-item:last-of-type > .accordion-header .accordion-button.collapsed {
    border-radius: 13px;
  }

  .accordion-button:not(.collapsed) {
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
  }
`;

const StyledStatusHistory = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const StyledCircle = styled.div`
  background: ${(props) => (props.index === 0 ? "#006881" : "#646464")};
  border-radius: 50%;
  width: 15px;
  height: 15px;
  margin: 0.3rem 0;
`;

const StyledLine = styled.div`
  height: 55px;
  width: 1px;
  border-left: 2px dashed #646464;

  @media screen and (max-width: 768px) {
    height: 45px;
  }
`;
export default function StatusHistory({ statusHistory }) {
  return (
    <>
      <StyledAccordion>
        <Accordion.Item eventKey="0">
          <Accordion.Header>Riwayat Status</Accordion.Header>
          <Accordion.Body>
            <Row>
              {statusHistory?.map((x, index) => (
                <Col md={12} key={x.id}>
                  <Row className="g-2">
                    <Col xs="4" lg="4">
                      <p
                        className={
                          index > 0 ? `mb-0 fw-bold text-muted` : `mb-0 fw-bold`
                        }
                        style={{ fontSize: "13px" }}
                      >
                        {moment(x.createdDate).format(
                          "DD MMMM YYYY HH:mm [WIB]"
                        )}
                      </p>
                    </Col>

                    <Col xs="2" lg="2">
                      <StyledStatusHistory>
                        <StyledCircle index={index} />

                        {index < statusHistory.length - 1 && <StyledLine />}
                      </StyledStatusHistory>
                    </Col>

                    <Col xs="6" lg="6">
                      <p
                        className={
                          index > 0 ? `mb-0 fw-bold text-muted` : `mb-0 fw-bold`
                        }
                        style={{ fontSize: "13px" }}
                      >
                        {StatusFormat(x.statusCode, "id")}
                      </p>
                      <p
                        className={
                          index > 0 ? `mb-0 small text-muted` : `mb-0 small`
                        }
                      >
                        {x.description
                          ? x.description === "null"
                            ? ""
                            : x.description
                          : ""}
                      </p>
                    </Col>
                  </Row>
                </Col>
              ))}
            </Row>
          </Accordion.Body>
        </Accordion.Item>
      </StyledAccordion>
    </>
  );
}
