import React, { useState } from "react";
import BannerImage from "../../components/Banner/BannerImage";
import { useSelector } from "react-redux";
import { Button, Card, Col, Form, Row, Spinner, Table } from "react-bootstrap";
import styled from "styled-components";
import { useForm } from "react-hook-form";
import * as XLSX from "xlsx";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/free-solid-svg-icons";
import axiosPost from "../../helpers/axiosPost";
import Alert from "../../components/Alert/Alert";
import { Link, useNavigate } from "react-router-dom";

const StyledInput = styled.div`
  /* background: #ffffff; */
  background: ${({ isExist }) => (isExist ? "#f5f5f5" : "#ffffff")};
  border: 1px solid #bcbcbc;
  border-radius: 5px;
  padding: 15px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 1rem;

  span {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  div {
    display: flex;
    align-items: center;
    gap: 0.5rem;
  }
`;

const StyledButton = styled.button`
  padding: 2px 20px;
  font-size: 16px;
  font-weight: 700;
  border-radius: 30px;
  color: white;
  min-height: 1.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  background: ${(props) =>
    props.isDelete ? "#F93154" : props.isDownload ? "#ADDFFF" : "#27AE60"};
  border: none;
  pointer-events: ${(props) => props.isUpload && "none"};
  min-width: ${(props) => (props.isDelete ? "40px" : "90px")};

  :disabled {
    background: #757575;
  }

  i {
    pointer-events: none;
  }
`;

export default function UploadExcel() {
  const isAdminKca = useSelector((state) => state.user.isUserAdminKCa);

  const [uploadLoading, setUploadLoading] = useState(false);
  const [error, setErr] = useState(null);
  const [showAlert, setShowAlert] = useState(false);
  const [isRead, setIsRead] = useState(false);
  const depoList = [
    {
      value: "DP001",
      label: "PT.Karya Gemilang Selatan",
    },
    {
      value: "DP002",
      label: "PT. Segara Pasific Maju",
    },
  ];
  const {
    handleSubmit,
    register,
    formState: { errors },
    watch,
  } = useForm({});

  const value = watch("docExcel");

  const [data, setData] = useState([]);
  const [dataHasAvaiable, setDataHasAvaiable] = useState([]);
  const [blNoData, setBlNoData] = useState([]);

  const handleFileUpload = (event) => {
    setErr(null);
    setIsRead(true);
    setDataHasAvaiable([]);
    const file = event.target.files[0];
    const reader = new FileReader();

    reader.onload = (e) => {
      const binaryStr = e.target.result;
      const workbook = XLSX.read(binaryStr, {
        type: "binary",
        cellDates: true,
      });
      const sheetName = workbook.SheetNames[0];
      const worksheet = workbook.Sheets[sheetName];
      let json = XLSX.utils.sheet_to_json(worksheet, { header: 1 });

      // Remove the first row if it is empty
      if (json[0] && json[0].every((cell) => cell === "")) {
        json.shift();
      }

      // Extract headers
      const headers = json[0];
      // Remove header row from data
      const rows = json.slice(1);
      // Combine headers and rows
      const formattedData = rows.map((row) => {
        let rowData = {};
        headers.forEach((header, index) => {
          let cellValue = row[index];
          // Check if the value should be a date based on header or type
          if (cellValue instanceof Date && !isNaN(cellValue)) {
            cellValue = cellValue.toLocaleDateString("id-ID", {
              year: "numeric",
              month: "long",
              day: "numeric",
            });
          } else if (cellValue === "P") {
            cellValue = (
              <FontAwesomeIcon
                icon={faCheck}
                style={{ color: "#006881", fontSize: "20px" }}
              ></FontAwesomeIcon>
            );
          }
          rowData[header] = cellValue;
        });
        return rowData;
      });

      const blNoData = formattedData.map((item) => item["B/L No."]);
      setData(formattedData);
      setBlNoData(blNoData);
    };

    reader.readAsBinaryString(file);
  };

  const navigate = useNavigate();

  const onSubmit = (data) => {
    if (data.docExcel.length === 0) {
      setShowAlert(true);
      setErr({
        message: `Maaf, file belum dipilih`,
      });
      return;
    }
    setUploadLoading(true);

    const checkData = {
      blNo: blNoData,
      depoCode: data.depoCode,
    };

    axiosPost({
      url: "/container/upload-transaction/check",
      data: checkData,
      callback: (res) => {
        const hasAvailable = res?.data?.data?.some((item) => item.isAvailable);
        if (hasAvailable) {
          setIsRead(false);
          setDataHasAvaiable(res?.data?.data);
          setShowAlert(true);
          setErr({ message: "Gagal Unggah Data Transaksi!" });
          setUploadLoading(false);
        } else {
          const fd = new FormData();
          fd.append("depoCode", data.depoCode);
          fd.append("file", data.docExcel[0]);
          axiosPost({
            url: "/container/upload-transaction",
            data: fd,
            callback: (res) => {
              setUploadLoading(false);
              setShowAlert(true);
              setTimeout(() => {
                navigate("/transaksi");
              }, 2500);
            },
            errorCallback: (err) => {
              setUploadLoading(false);
              setShowAlert(true);
              setErr(err);
            },
          });
        }
      },
      errorCallback: (err) => {
        console.log(err);
      },
    });
  };

  return (
    <div className="container">
      <BannerImage isAdmin={isAdminKca} />

      <h5 className="pl-4 pr-4 mt-2 mb-3 title-header-left">
        Form Unggah Data Transaksi
      </h5>
      <Form
        className="form-submission"
        onSubmit={handleSubmit(onSubmit)}
        noValidate
      >
        <Row>
          <Col xs={12} md={12}>
            <Card
              className="card-wrapper p-4 mb-4"
              style={{ minHeight: "100px" }}
            >
              <Card.Body className="main-card">
                <Row>
                  <Col md={12}>
                    <label htmlFor="docExcel" className="mb-2 mt-2">
                      Upload File Excel
                      <span className="text-danger">*</span>{" "}
                    </label>
                    <input
                      type="file"
                      className="form-control form-control-lg"
                      id="docExcel"
                      name="docExcel"
                      {...register(`docExcel`, {
                        onChange: handleFileUpload,
                      })}
                      style={{ display: "none" }}
                      accept={".xlsx, .xls"}
                    />

                    <StyledInput>
                      <span>
                        {value?.length ? value[0].name : "Pilih file"}
                      </span>
                      <div>
                        <label htmlFor="docExcel">
                          <div style={{ cursor: "pointer" }}>
                            <StyledButton type="button" isUpload>
                              Lampirkan
                            </StyledButton>
                          </div>
                        </label>
                      </div>
                    </StyledInput>
                    {errors[`docExcel`] && (
                      <p className="mb-0 mt-1 text-danger small">
                        *{errors[`docExcel`].message}
                      </p>
                    )}
                  </Col>
                  <Col md={12} className="mt-3">
                    <Form.Select
                      name="depoCode"
                      {...register(`depoCode`, {
                        required: "Pilih depo terlebih dahulu!",
                      })}
                    >
                      <option value="" disabled selected>
                        -- Pilih Depo --
                      </option>
                      {depoList.map((item, index) => (
                        <option value={item.value} key={index}>
                          {item.label}
                        </option>
                      ))}
                    </Form.Select>
                    {errors["depoCode"] && (
                      <p className="mb-0 mt-1 text-danger small">
                        *{errors["depoCode"].message}
                      </p>
                    )}
                  </Col>
                  <Col md={12}>
                    <div className="text-end mt-3">
                      <Button
                        type="submit"
                        color="primary"
                        size="small"
                        className="back-button"
                        disabled={uploadLoading}
                      >
                        {uploadLoading ? (
                          <Spinner
                            as="span"
                            animation="border"
                            size="sm"
                            role="status"
                            aria-hidden="true"
                          />
                        ) : (
                          "SUBMIT"
                        )}
                      </Button>
                    </div>
                  </Col>
                </Row>
              </Card.Body>
            </Card>

            <Card className="card-wrapper p-4 mb-4">
              <Card.Body>
                <Card.Header className="card-main-header">
                  Data Excel
                </Card.Header>
                <hr className="mt-2" />
                <br />
                {dataHasAvaiable?.length > 0 ? (
                  <>
                    <p
                      className="text-danger mb-1 mt-0 font-italic"
                      style={{ fontStyle: "italic" }}
                    >
                      <strong>
                        <small className="font-italic">
                          Note: Mohon lakukan perbaikan pada file excel sebelum
                          melakukan upload
                        </small>
                      </strong>
                    </p>
                    <Table striped bordered hover>
                      <thead style={{ fontSize: "12px", textAlign: "center" }}>
                        <tr>
                          <th>Company Name</th>
                          <th>BL No</th>
                          <th>Status</th>
                        </tr>
                      </thead>
                      <tbody style={{ fontSize: "12px", textAlign: "center" }}>
                        {dataHasAvaiable.map((row, index) => (
                          <tr>
                            <td>{row.companyName}</td>
                            <td>{row.blNo}</td>
                            <td
                              className={`${
                                row.isAvailable ? "text-danger" : "text-success"
                              }`}
                            >
                              {row.isAvailable
                                ? "No BL ini sudah tersedia"
                                : "No BL ini belum tersedia"}
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                  </>
                ) : isRead ? (
                  <Table striped bordered hover>
                    <thead style={{ fontSize: "12px", textAlign: "center" }}>
                      <tr>
                        {data.length > 0 &&
                          Object.keys(data[0]).map((key) => (
                            <th key={key}>{key}</th>
                          ))}
                      </tr>
                    </thead>
                    <tbody style={{ fontSize: "12px", textAlign: "center" }}>
                      {data.map((row, index) => (
                        <tr key={index}>
                          {Object.values(row).map((value, i) => (
                            <td key={i}>{value}</td>
                          ))}
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                ) : (
                  <div className="d-flex align-items-center justify-content-center mt-5">
                    Belum ada data transaksi
                  </div>
                )}
              </Card.Body>
            </Card>
          </Col>
          <Col md={12}>
            <Link to={"/transaksi"}>
              <Button
                type="button"
                color="primary"
                size="small"
                className="back-button"
              >
                KEMBALI
              </Button>
            </Link>
          </Col>
        </Row>
      </Form>

      <Alert
        message="Unggah Data Transaksi Berhasil!"
        show={showAlert}
        error={error}
        handleClose={() => setShowAlert(false)}
      />
    </div>
  );
}
