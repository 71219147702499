import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { Spinner } from "react-bootstrap";
import constants from "../../constants/constants";
import axiosPost from "../../helpers/axiosPost";
import Alert from "../Alert/Alert";

const StyledInput = styled.div`
  /* background: #ffffff; */
  background: ${({ isExist }) => (isExist ? "#f5f5f5" : "#ffffff")};
  border: 1px solid #bcbcbc;
  border-radius: 5px;
  padding: 15px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 1rem;

  span {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  div {
    display: flex;
    align-items: center;
    gap: 0.5rem;
  }
`;

const StyledButton = styled.button`
  padding: 2px 20px;
  font-size: 16px;
  font-weight: 700;
  border-radius: 30px;
  color: white;
  min-height: 1.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  background: ${(props) =>
    props.isDelete ? "#F93154" : props.isDownload ? "#ADDFFF" : "#27AE60"};
  border: none;
  pointer-events: ${(props) => props.isUpload && "none"};
  min-width: ${(props) => (props.isDelete ? "40px" : "90px")};

  :disabled {
    background: #757575;
  }

  i {
    pointer-events: none;
  }
`;

const StyledButtonSubmit = styled.button`
  background: linear-gradient(180deg, #006881, rgba(67, 186, 148, 0) 136.98%);
  border: none;
  border-radius: 20px;
  font-size: 14px;
  padding: 3px 20px;
  color: #fff;

  &.disabled {
    background: #757575;
  }
`;

export default function ModalFormRevisionEor({
  claimId,
  toggleModal,
  refId,
  claimContainerDetailId,
}) {
  const { watch, register, handleSubmit } = useForm({});
  const navigate = useNavigate();
  const eorDocValue = watch("eorDoc");
  const [loading, setLoading] = useState(false);
  const [show, setShow] = useState(false);
  const [error, setError] = useState(false);

  const handleUpload = (data) => {
    setLoading(true);
    const fd = new FormData();
    fd.append("file", data.eorDoc[0] || null);
    fd.append("type", "DOC_EOR");
    fd.append("description", "Upload Revision EOR");
    fd.append("refId", refId);
    fd.append("claimContainerId", claimId);
    fd.append("claimContainerDetailId", claimContainerDetailId ?? null);

    axiosPost({
      url: `/claim-kca/upload-eor`,
      data: fd,
      callback: () => {
        setLoading(false);
        setShow(true);
        toggleModal();
        setTimeout(() => {
          window.location.reload();
        }, 2000);
      },
      errorCallback: (err) => {
        setShow(true);
        if (
          err.statusCode &&
          (err.statusCode === constants.HTTP_STATUS_UNAUTHORIZED ||
            err.statusCode === constants.HTTP_STATUS_FAILED ||
            err.statusCode === constants.HTTP_STATUS_ERROR ||
            err.statusCode === constants.HTTP_STATUS_SERVER_ERROR)
        ) {
          setTimeout(() => {
            navigate("/");
          }, 2000);
        }
        setError(err);
        setLoading(false);
        toggleModal();
      },
    });
  };

  return (
    <>
      <form onSubmit={handleSubmit(handleUpload)}>
        <div className="mb-4">
          <label htmlFor="eorDoc" className="mb-2 mt-2">
            Upload EOR <span className="text-danger">*</span>{" "}
          </label>
          <input
            type="file"
            className="form-control form-control-lg"
            id="eorDoc"
            name="eorDoc"
            {...register("eorDoc")}
            style={{ display: "none" }}
            accept={".xlsx"}
          />

          <StyledInput>
            <span>
              {eorDocValue?.length ? eorDocValue[0].name : "Pilih file"}
            </span>
            <div>
              <label htmlFor="eorDoc">
                <div style={{ cursor: "pointer" }}>
                  <StyledButton type="button" isUpload>
                    Lampirkan
                  </StyledButton>
                </div>
              </label>
            </div>
          </StyledInput>
        </div>
        {eorDocValue && (
          <div className="d-flex justify-content-end">
            <StyledButtonSubmit type="submit" disabled={loading}>
              {loading ? <Spinner animation="border" size="sm" /> : "Submit"}
            </StyledButtonSubmit>
          </div>
        )}
      </form>
      <Alert
        message="Upload Revisi Sukses"
        show={show}
        error={error}
        handleClose={() => setShow(false)}
      />
    </>
  );
}
