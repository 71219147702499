import { saveAs } from "file-saver";
import React, { useState } from "react";
import { Button, Spinner } from "react-bootstrap";
import axiosGet from "../../helpers/axiosGet";

export default function SingleDownloadFile({ files }) {
  return (
    <>
      {files?.map((file) => (
        <DataFile file={file} />
      ))}
    </>
  );
}

const DataFile = ({ file }) => {
  const { label, fileId, fileName } = file;
  const [loading, setLoading] = useState(false);

  const handleDownload = () => {
    setLoading(true);
    axiosGet({
      url: `/claim-kca/download/file-id/${fileId}`,
      responseType: "blob",
      callback: () => {
        setLoading(false);
      },
      errorCallback: (res) => {
        setLoading(false);
        let file = new File([res], `${fileName}`);
        saveAs(file);
      },
    });
  };
  return (
    <Button
      type="button"
      color="primary"
      size="small"
      className="text-center action-button w-100 mt-3"
      onClick={() => handleDownload(file)}
      disabled={loading}
    >
      {loading ? (
        <>
          <Spinner
            style={{
              width: "15px",
              height: "15px",
            }}
          />
        </>
      ) : (
        <>Download {label}</>
      )}
    </Button>
  );
};
