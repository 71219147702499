import React, { useEffect, useState } from "react";
import { Button, Card, Col, Form, Row } from "react-bootstrap";
import { Link, useParams, useNavigate } from "react-router-dom";
import "../Claim.css";
import SpinnerPrimary from "../../../components/Spinner/Spinner";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft, faArrowRight } from "@fortawesome/free-solid-svg-icons";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import { useForm } from "react-hook-form";
// import NumberFormat from "react-number-format";
// import DetailCertificate from "../../components/DetailCertificate/DetailCertificate";
import { setClaimSubmission } from "../../../store/claim/actions";
import moment from "moment";
import "moment/locale/id";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import "react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css";
import BannerImage from "../../../components/Banner/BannerImage";
import axiosGet from "../../../helpers/axiosGet";
import axiosPost from "../../../helpers/axiosPost";
import uploadDocClaim from "../../../helpers/uploadDocClaimV2";
import axiosPut from "../../../helpers/axiosPut";

const StyledInput = styled.div`
  /* background: #ffffff; */
  background: ${({ isExist }) => (isExist ? "#f5f5f5" : "#ffffff")};
  border: 1px solid #bcbcbc;
  border-radius: 5px;
  padding: 15px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 1rem;

  span {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  div {
    display: flex;
    align-items: center;
    gap: 0.5rem;
  }
`;

const StyledButton = styled.button`
  padding: 2px 20px;
  font-size: 16px;
  font-weight: 700;
  border-radius: 30px;
  color: white;
  min-height: 1.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  background: ${(props) =>
    props.isDelete ? "#F93154" : props.isDownload ? "#ADDFFF" : "#27AE60"};
  border: none;
  pointer-events: ${(props) => props.isUpload && "none"};
  min-width: ${(props) => (props.isDelete ? "40px" : "90px")};

  :disabled {
    background: #757575;
  }

  i {
    pointer-events: none;
  }
`;

export default function ClaimSubmissionForm() {
  const dataClaim = useSelector((state) => state.claim.dataClaimSubmission);
  const isAdminKca = useSelector((state) => state.user.isUserAdminKCa);
  const dataUser = useSelector((state) => state.user.user);
  const [loading, setLoading] = useState(false);
  const [error, setErr] = useState(null);

  const params = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [detail, setDetail] = useState();
  const [filenameEor, setNameEor] = useState(dataClaim?.filenameEor ?? null);
  let docEor = localStorage.getItem("DOC_EOR");
  // const [filenameOther, setNameOther] = useState(
  //   dataClaim?.filenameOther ?? null
  // );

  const {
    handleSubmit,
    register,
    formState: { errors },
    watch,
    setError,
    clearErrors,
  } = useForm({ defaultValues: dataClaim });

  const eorDocValue = watch("eorDoc");
  // const otherDocValue = watch("otherDoc");

  const decode = window.atob(params.id);

  useEffect(() => {
    setLoading(true);
    axiosGet({
      url: `/claim-cargo-container/uniq-id/${decode}`,
      callback: (res) => {
        setLoading(false);
        setDetail(res.data);
      },
      errorCallback: (err) => {
        setLoading(false);
        setErr(err);
        console.log(error);
      },
    });
  }, [decode, error]);

  const handleFileEor = (e) => {
    const file = e.target.files[0];
    setNameEor(file.name);
    if (file.size > 2_000_000) {
      setError("eorDoc", {
        type: "manual",
        message: `ukuran file maksimal 2MB.`,
        shouldFocus: true,
      });
    } else {
      clearErrors("eorDoc");
    }
  };

  // const handleOtherDoc = (e) => {
  //   const file = e.target.files[0];
  //   setNameOther(file.name);

  //   if (file.size > 2_000_000) {
  //     setError("otherDoc", {
  //       type: "manual",
  //       message: `ukuran file maksimal 2MB.`,
  //       shouldFocus: true,
  //     });
  //   } else {
  //     clearErrors("otherDoc");
  //   }
  // };

  const onSubmit = async (data) => {
    setLoading(true);
    const today = moment().format("YYYY-MM-DD"); //for nextProgressDeadlineDate

    const body = {
      accidentDate: null,
      accidentDetail: "",
      accidentLocation: data.accidentLocation ?? null,
      nextProgressUserType: "-",
      nextProgressDeadlineDate: today,
      insuranceCode: detail.insurerCode,
      companyName: detail.companyName,
      picName: dataUser?.userFullname ?? dataUser?.username,
      picPhoneNumber: dataUser?.userPhoneNumber,
      picEmail: dataUser?.userEmail,
      policyNumber: detail.policyNumber,
      conveyance: detail.vesselName,
      certificateNo: detail.certificateNumber,
      publishDate: today,
      declarationUniqId: detail.uniqId,
      surveyDate: null,
      consigneeLocation: isAdminKca ? null : dataUser?.address,
    };

    if (dataClaim?.claimContainerId && dataClaim?.noClaim) {
      axiosPut({
        url: `/claim-kca/update-claim/${dataClaim?.noClaim}`,
        data: body,
        callback: async (res) => {
          if (eorDocValue?.length > 0) {
            await Promise.all([
              uploadDocClaim(
                data?.eorDoc[0],
                dataClaim?.refId,
                dataClaim?.claimContainerId,
                "DOC_EOR"
              ),
            ]);
            dispatch(
              setClaimSubmission({
                ...dataClaim,
                filenameEor,
              })
            );
          }
          navigate(`/v2/perbaikan-kontainer/submission/detail-attachment`);
        },
        errorCallback: (err) => {
          setErr(err);
        },
      });
    } else {
      axiosPost({
        url: `/claim-kca/create-claim`,
        data: body,
        callback: async (res) => {
          const claimContainerId = res.data.claimContainer.id;
          const attachment = detail?.attachment;
          const refId = detail?.refId;
          await Promise.all([
            uploadDocClaim(data?.eorDoc[0], refId, claimContainerId, "DOC_EOR"),
            // uploadDocClaim(
            //   data?.otherDoc[0],
            //   refId,
            //   claimContainerId,
            //   "DOC_DOCUMENTATION"
            // ),
          ]);
          dispatch(
            setClaimSubmission({
              declarationId: params.id,
              consignee: detail?.claimContainer?.consignee,
              insured: detail?.claimContainer?.insured,
              refId: refId,
              claimContainerId,
              attachment,
              filenameEor,
              insuredReceiverName: detail?.insuredReceiverName,
              noClaim: res?.data?.noClaim,
              // filenameOther,
              ...body,
            })
          );
          navigate(`/v2/perbaikan-kontainer/submission/detail-attachment`);
        },
        errorCallback: (err) => {
          console.log(err);
          setLoading(false);
        },
      });
    }
  };

  return loading ? (
    <div
      className="d-flex justify-content-center align-items-center"
      style={{ minHeight: "100vh" }}
    >
      <SpinnerPrimary />
    </div>
  ) : (
    <div className="container">
      <BannerImage isAdmin={isAdminKca} />

      <h5 className="pl-4 pr-4 mt-2 mb-3 title-header-left">
        Form Pengajuan Perbaikan
      </h5>
      <Form
        className="form-submission"
        onSubmit={handleSubmit(onSubmit)}
        noValidate
      >
        <Row>
          <Col xs={12} md={12}>
            <Card className="card-wrapper p-4 mb-4">
              <Card.Body className="main-card">
                <Form.Group className="mb-3">
                  <Form.Label>
                    Nama PIC <span className="text-danger">*</span>{" "}
                  </Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Nama PIC"
                    value={dataUser?.userFullname ?? dataUser?.username}
                    name="picName"
                    {...register("picName")}
                    disabled
                  />
                  {errors["picName"] && (
                    <p className="mb-0 mt-1 text-danger small">
                      *{errors["picName"].message}
                    </p>
                  )}
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Label>
                    Email PIC <span className="text-danger">*</span>{" "}
                  </Form.Label>
                  <Form.Control
                    type="email"
                    placeholder="Email PIC"
                    value={dataUser?.userEmail}
                    name="picEmail"
                    {...register("picEmail", {
                      pattern: {
                        value: /\S+@\S+\.\S+/,
                        message: "email tidak valid!",
                      },
                    })}
                    disabled
                  />
                  {errors["picEmail"] && (
                    <p className="mb-0 mt-1 text-danger small">
                      *{errors["picEmail"].message}
                    </p>
                  )}
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Label>
                    Nomor Telepon PIC <span className="text-danger">*</span>{" "}
                  </Form.Label>
                  <Form.Control
                    type="number"
                    placeholder="Nomor Telepon PIC"
                    value={dataUser?.userPhoneNumber}
                    name="picPhoneNumber"
                    {...register("picPhoneNumber")}
                    disabled
                  />
                  {errors["picPhoneNumber"] && (
                    <p className="mb-0 mt-1 text-danger small">
                      *{errors["picPhoneNumber"].message}
                    </p>
                  )}
                </Form.Group>
                {isAdminKca ? (
                  <>
                    <Form.Group className="mb-3">
                      <Form.Label>
                        Lokasi Kejadian <span className="text-danger">*</span>{" "}
                      </Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Lokasi Kejadian"
                        name="accidentLocation"
                        {...register("accidentLocation", {
                          required: "Lokasi kejadian wajib diisi!",
                        })}
                      />
                      {errors["accidentLocation"] && (
                        <p className="mb-0 mt-1 text-danger small">
                          *{errors["accidentLocation"].message}
                        </p>
                      )}
                    </Form.Group>
                  </>
                ) : (
                  <>
                    <Form.Group className="mb-3">
                      <Form.Label>
                        Lokasi Depo <span className="text-danger">*</span>{" "}
                      </Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Lokasi Depo"
                        value={dataUser?.address}
                        name="consigneeLocation"
                        {...register("consigneeLocation")}
                        disabled
                      />
                      {errors["consigneeLocation"] && (
                        <p className="mb-0 mt-1 text-danger small">
                          *{errors["consigneeLocation"].message}
                        </p>
                      )}
                    </Form.Group>
                  </>
                )}
                <label htmlFor="eorDoc" className="mb-2 mt-2">
                  {isAdminKca ? "Formulir Claim" : "Upload EOR"}{" "}
                  <span className="text-danger">*</span>{" "}
                </label>
                <input
                  type="file"
                  className="form-control form-control-lg"
                  id="eorDoc"
                  name="eorDoc"
                  {...register("eorDoc", {
                    onChange: handleFileEor,
                    required: dataClaim.filenameEor
                      ? false
                      : "EOR wajib diupload!",
                  })}
                  style={{ display: "none" }}
                  accept={".xlsx, .xls"}
                />

                <StyledInput>
                  <span>
                    {eorDocValue?.length
                      ? eorDocValue[0].name
                      : dataClaim.filenameEor
                      ? dataClaim.filenameEor
                      : "Pilih file"}
                  </span>
                  <div>
                    {dataClaim.filenameEor && (
                      <div style={{ cursor: "pointer" }}>
                        <a
                          className="styled-like-a-button"
                          download={dataClaim.filenameEor}
                          href={docEor}
                          style={{ textDecoration: "none" }}
                        >
                          <StyledButton type="button" isDownload>
                            Unduh
                          </StyledButton>
                        </a>
                      </div>
                    )}

                    <label htmlFor="eorDoc">
                      <div style={{ cursor: "pointer" }}>
                        <StyledButton type="button" isUpload>
                          Lampirkan
                        </StyledButton>
                      </div>
                    </label>
                  </div>
                </StyledInput>

                {errors["eorDoc"] && (
                  <p className="mb-0 mt-1 text-danger small">
                    *{errors["eorDoc"].message}
                  </p>
                )}

                {/* <Form.Group>
                  <Form.Label htmlFor="otherDoc" className="mb-2 mt-2">
                    {isAdminKca
                      ? "Laporan Kepolisian (Theft & Burglary) *hanya untuk kasus kehilangan"
                      : "Foto - foto dokumentasi dan atau rekaman video (kontainer yang rusak)"}{" "}
                    <span className="text-danger">*</span>{" "}
                  </Form.Label>
                  <Form.Control
                    type="file"
                    className="form-control form-control-lg"
                    id="otherDoc"
                    name="otherDoc"
                    {...register("otherDoc", {
                      onChange: handleOtherDoc,
                      required: dataClaim.filenameOther
                        ? false
                        : "Foto dokumentasi wajib diupload!",
                    })}
                    hidden
                    accept={
                      ".doc, .docx, .pdf, .jpg, .jpeg, .png, .zip, .7z, .rar"
                    }
                  />

                  <StyledInput>
                    <span>
                      {otherDocValue?.length
                        ? otherDocValue[0].name
                        : dataClaim.filenameOther
                        ? dataClaim.filenameOther
                        : "Pilih file"}
                    </span>
                    <div>
                      <Form.Label htmlFor="otherDoc">
                        <div style={{ cursor: "pointer" }}>
                          <StyledButton type="button" isUpload>
                            Lampirkan
                          </StyledButton>
                        </div>
                      </Form.Label>
                    </div>
                  </StyledInput>
                  {errors["otherDoc"] && (
                    <p className="mb-0 mt-1 text-danger small">
                      *{errors["otherDoc"].message}
                    </p>
                  )}

                  {!isAdminKca && (
                    <p className="mb-0 mt-1 text-danger small">
                      <small>
                        <p className="mb-0">*catatan</p>
                        <p className="mb-0">
                          Jenis format file yang diterima : PDF, ZIP, 7Z, dan
                          RAR{" "}
                        </p>
                        <p className="mb-0">Maksimum ukuran file 5MB</p>
                      </small>
                    </p>
                  )}
                </Form.Group> */}
              </Card.Body>
            </Card>
          </Col>

          {/* <Col xs={12} md={4}>
            <DetailCertificate detail={detail} />
          </Col> */}
        </Row>

        <Row className="mt-5">
          <Col lg={12} className="d-flex justify-content-between">
            <Link
              to={isAdminKca ? "/perbaikan" : "/perbaikan-kontainer"}
              style={{ textDecoration: "none" }}
            >
              <Button
                type="button"
                color="primary"
                size="small"
                className="d-flex justify-content-between align-items-center back-button"
              >
                <FontAwesomeIcon icon={faArrowLeft}></FontAwesomeIcon>
                KEMBALI
              </Button>
            </Link>
            <Button
              type="submit"
              color="primary"
              size="small"
              className="d-flex justify-content-between align-items-center back-button"
              disabled={errors["eorDoc"] || errors["otherDoc"] ? true : false}
            >
              SELANJUTNYA
              <FontAwesomeIcon icon={faArrowRight}></FontAwesomeIcon>
            </Button>
          </Col>
        </Row>
      </Form>
    </div>
  );
}
