import { combineReducers } from "redux";
import declarationReducer from "./declaration/reducers";
import claimReducer from "./claim/reducers";
import userReducer from "./user/reducers";

const rootReducers = combineReducers({
  declaration: declarationReducer,
  claim: claimReducer,
  user: userReducer,
});

export default rootReducers;
