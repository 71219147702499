import constants from "../constants/constants";
// import dataURIToBlob from "./dataURIToBlob";
import generateCorrelationId from "./generateCorrelationId";
import getBase64 from "./getBase64";
import getCurrentDatetime from "./getCurrentDatetime";

const uploadDocClaim = async (
  fileObject,
  refId,
  claimContainerId,
  type,
  claimContainerDetailId,
  otherDocIndex
) => {
  const token =
    localStorage.getItem("token") ?? sessionStorage.getItem("token");
  const user = localStorage.getItem("user") ?? sessionStorage.getItem("user");

  if (fileObject !== null) {
    getBase64(fileObject, type === "DOC_DOCUMENTATION" ? otherDocIndex : type);
    var data = new FormData();
    // const fileName = filename?.split(".");
    // const fileNameResult = fileName[fileName?.length - 1];

    // const file = dataURIToBlob(fileObject);
    // console.log("fileObject", fileObject);

    data.append("file", fileObject);
    data.append("type", type);
    data.append("description", type);
    data.append("refId", refId);
    data.append("claimContainerId", claimContainerId);
    if (claimContainerDetailId) {
      data.append("claimContainerDetailId", claimContainerDetailId);
    }
    await fetch(
      `${constants.DEFAULT_URL}/claim-kca/${
        type === "DOC_EOR" ? "upload-eor/multiple" : "upload"
      }`,
      {
        method: "POST",
        body: data,
        headers: {
          "X-Transmission-Date-Time": getCurrentDatetime(),
          "X-Correlation-Id": generateCorrelationId(),
          "X-Authorization": token,
          "X-User": user,
        },
      }
    );
  }
  //   setLoading(false);
  // const encode = window.btoa(claimId);
  // navigate(`/claim/detail/${encode}`);
  window.scrollTo(0, 0);
  return Promise.resolve();
};

export default uploadDocClaim;
