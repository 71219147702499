import React, { useState } from "react";
import { Button, Modal, Spinner } from "react-bootstrap";
import axiosPut from "../../helpers/axiosPut";
import Alert from "../Alert/Alert";
import styled from "styled-components";

const StyledActionButton = styled.button`
  background: #00bcd4;
  border: none;
  min-width: 130px;
  padding: 8px 25px;
  font-weight: 800;
  font-size: 14px;
  border-radius: 20px;
  box-shadow: 0px 3px 4px 0px rgba(196, 196, 196, 1);
  color: #fff;

  &:disabled {
    background: #8ecfd7;
  }
`;

export default function ApproveRepairs({
  detail,
  loadingRepair,
  claimAmount,
  hasDataAdjustment,
  hasDataEorSelected,
  dataEorClaim,
}) {
  const [loading, setLoading] = useState(false);
  const [show, setShow] = useState(false);
  const [error, setError] = useState(null);

  const [modal, setModal] = useState(false);
  const toggleModal = () => setModal(!modal);

  const submitClaim = () => {
    setLoading(true);
    if (hasDataEorSelected?.length <= 0) {
      setLoading(false);
      setShow(true);
      setError({
        message: "Pilih minimal 1 Data EOR!",
      });
      setModal(false);
    } else {
      setError(null);
      const body = {
        noClaim: detail.noClaim,
        claimAmount: claimAmount,
        selected: hasDataEorSelected,
      };
      axiosPut({
        url: `/claim-kca/approve-repairs`,
        data: body,
        callback: (res) => {
          setModal(false);
          setShow(true);
          setLoading(false);
          setTimeout(() => {
            window.location.reload();
          }, 3000);
        },
        errorCallback: (err) => {
          setLoading(false);
          setShow(true);
          setError(err);
        },
      });
    }
  };

  const checkAllMissingIds = () => {
    let foundMissingId = false;

    dataEorClaim.forEach((item) => {
      const { eorList } = item;
      const eorIds = eorList.map((eor) => eor.id);

      if (!eorIds.some((id) => hasDataEorSelected.includes(id))) {
        foundMissingId = true;
      }
    });

    return foundMissingId;
  };

  const allIdEorList = dataEorClaim.flatMap((item) =>
    item.eorList.map((eor) => eor.id)
  );

  const handleButton = () => {
    if (checkAllMissingIds()) {
      setShow(true);
      setError({
        message: `Minimal pilih 1 data EOR di setiap tabel EOR`,
      });
    } else {
      toggleModal();
    }
  };

  return (
    <div>
      <StyledActionButton
        type="button"
        color="primary"
        size="small"
        className="text-center w-100 mt-4 mb-3"
        onClick={handleButton}
        disabled={
          allIdEorList?.length !== hasDataEorSelected?.length ||
          loading ||
          loadingRepair ||
          hasDataAdjustment
        }
      >
        Perbaikan Disetujui
      </StyledActionButton>

      <Modal
        show={modal}
        onHide={toggleModal}
        size="md"
        backdropClassName="custom-backdrop"
      >
        <Modal.Header closeButton>
          <Modal.Title style={{ fontSize: "15px" }}>
            Konfirmasi Persetujuan
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="text-center">
            <label htmlFor="description" className="mb-2 mt-2">
              Apakah anda yakin ingin melanjutkan?
            </label>
            <div className="mt-2 mb-2">
              <Button variant="outline-secondary" onClick={toggleModal}>
                Tidak
              </Button>{" "}
              <Button
                variant="secondary"
                disabled={loading}
                onClick={submitClaim}
              >
                {loading ? <Spinner animation="border" size="sm" /> : "Ya!"}
              </Button>{" "}
            </div>
          </div>
        </Modal.Body>
      </Modal>
      <Alert
        message="Penyetujuan Perbaikan Berhasil!"
        show={show}
        error={error}
        handleClose={() => setShow(false)}
      />
    </div>
  );
}
