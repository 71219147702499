import moment from "moment";
import { Button } from "react-bootstrap";
import styled from "styled-components";
import { Fragment } from "react";
import { useNavigate } from "react-router-dom";
import StatusList from "./StatusList";

const StyledStatusBar = styled.div`
  margin-top: 60px;
  margin-bottom: 10px;
  width: 100%;
  z-index: 1;
  font-family: "Avenir", sans-serif;
  font-weight: 500;
  z-index: 10;
  background: transparent;
  border-radius: 0px;

  .code {
    border-radius: 0px;
    background: #00bcd4;
    color: white;
    padding: 0.9rem;
    gap: 0.25rem;
    text-align: center;
    font-size: 14px;

    div span:nth-child(odd) {
      color: #fff;
      padding: 10;
    }

    @media (min-width: 768px) {
      flex-direction: row;
      gap: 1rem;
    }
  }

  .desc {
    background: #ffffff;
    padding: 0.25rem;
    text-align: center;
  }
`;

const StatusBar = ({ id, statusCode, isExpired }) => {
  const navigate = useNavigate();

  let bar = {};

  StatusList.forEach((x) => {
    if (x.code === statusCode) {
      bar.color = x.color;
      bar.text = x.text;
      bar.date = isExpired;
      bar.button = statusCode === "101" || statusCode === "140" ? true : false;
      bar.buttonText = "Upload Dokumen";
    }
  });

  const handleButton = (id) => {
    const encode = window.btoa(id);
    if (statusCode === "101") {
      navigate(`/perbaikan-kontainer/${encode}/upload-dokumen-repairs`);
    } else {
      navigate(`/claim/${encode}/upload-dokumen-lod`);
    }
  };

  return (
    <Fragment>
      <StyledStatusBar color={bar.color}>
        <div className="code">
          <div className="d-flex justify-content-center align-items-center mb-2">
            <p className="mb-0">{bar.text}</p>
            {statusCode !== "121" && (
              <span>{moment(bar.date).format("DD MMM YYYY")}</span>
            )}
          </div>

          {bar.button && (
            <Button
              onClick={() => handleButton(id)}
              style={{
                background: "white",
                border: "none",
                borderRadius: "8px",
                color: "#067",
                cursor: "pointer",
              }}
              size="sm"
            >
              {bar.buttonText}
            </Button>
          )}
        </div>
      </StyledStatusBar>
    </Fragment>
  );
};

export default StatusBar;
