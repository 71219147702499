import React from "react";
import { Col, Row } from "react-bootstrap";
import "./Footer.css";

export default function Footer() {
  return (
    <div id="footer" className="mt-5">
      <div className="container">
        {/* <Row>
          <Col xs={12} md={6}>
            <h5>
              POLISMALL POWERED BY <br /> PT. PIALANG ASURANSI <br /> PROVIS
              MITRA SINERGI
            </h5>
            <p>We Had Helped Many People, and Still Adding Up.</p>
          </Col>
          <Col xs={12} md={6}>
            <h5 className="font-weight-bold mb-4">CONTACT</h5>
            <p>(021) 2967 2495/ 2496/ 2497</p>
            <p>service@provisms.com</p>
            <p className="mb-0">
              Dipo Business Center, Rukan B-02 <br /> Jl. Gatot Subroto Kav.
              50-52 Jakarta 10260
            </p>
          </Col>
          <Col xs={12} md={12} className="text-center">
            <hr />
            <p>
              &copy; {new Date().getFullYear()} Copyright:{" "}
              <span>PT. Pialang Asuransi Provis Mitra Sinergi</span>
            </p>
          </Col>
        </Row> */}
        <Row>
          <Col xs={12} md={6}>
            <h5 className="font-weight-bold mb-3">Address :</h5>
            <p className="mb-0">
              Komplek Kirana Boutique Office, Blok E3, No. 1 <br /> JL.
              Boulevard Kelapa Gading, <br /> Jakarta Utara - Indonesia 14240.
            </p>
            <p className="mb-0">Tel : (+62-21) 2928 9928 (Hunting)</p>
            <p>Fax : (+62-21) 2928 9929</p>
          </Col>
          <Col xs={12} md={12} className="text-left">
            <hr />
            <p>
              &copy; {new Date().getFullYear()} Copyright:{" "}
              <span>KCargoAgencies</span>, All Rights Reserved.
            </p>
          </Col>
        </Row>
      </div>
    </div>
  );
}
