import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

function ClaimLink({ value, id, detail, depo }) {
  const encode = window.btoa(id);
  const isAdminKca = useSelector((state) => state.user.isUserAdminKCa);
  const path = isAdminKca ? "perbaikan" : "perbaikan-kontainer";
  const linkStyle = {
    textDecoration: "none",
    color: detail ? "#fff" : "#106c85",
    fontSize: depo ? "12px" : "10px",
  };

  return value ? (
    <OverlayTrigger
      placement={"top"}
      overlay={
        <Tooltip id={`tooltip-top`}>
          <strong>Detail Perbaikan</strong>
        </Tooltip>
      }
    >
      <Link to={`/v2/${path}/detail/${encode}`} style={linkStyle}>
        <b>{value}</b>
      </Link>
    </OverlayTrigger>
  ) : (
    "-"
  );
}
export default ClaimLink;
