import React, { useEffect, useState } from "react";
import { Col, Row, Button, Spinner } from "react-bootstrap";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import FileForm from "../../components/Form/FileForm";
import SpinnerPrimary from "../../components/Spinner/Spinner";
import "./Claim.css";
import BannerImage from "../../components/Banner/BannerImage";
import axiosGet from "../../helpers/axiosGet";
import constants from "../../constants/constants";
import axiosPut from "../../helpers/axiosPut";
import Alert from "../../components/Alert/Alert";
import DetailClaimCertificate from "../../components/DetailCertificate/DetailClaimCertificate";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDownload } from "@fortawesome/free-solid-svg-icons";

const formId = "claim-doc";

export default function ClaimUpload() {
  const params = useParams();
  const decode = window.atob(params.id);
  const isAdminKca = useSelector((state) => state.user.isUserAdminKCa);
  const [detail, setDetail] = useState();
  const [dataCargoContainer, setDataCargoContainer] = useState(null);
  const [status, setStatus] = useState([]);

  const [loading, setLoading] = useState(false);
  const [loadingSubmit, setLoadingSubmit] = useState(false);
  const [loadingContainer, setLoadingContainer] = useState(false);

  const navigate = useNavigate();

  const [docs, setDocs] = useState([]);
  const [docsOther, setDocsOther] = useState([]);

  const fieldsRepairs = [
    {
      name: "DOC_EIR",
      label: "Dokumen EIR",
      required: true,
      isContainer: true,
    },
    {
      name: "DOC_REPAIR_DOCUMENTATION",
      label: "Dokumentasi Perbaikan",
      required: true,
      isContainer: true,
    },
    // {
    //   name: "DOC_INVOICE",
    //   label: "Upload Invoice",
    //   required: false,
    // },
  ];

  const fieldsLodSign = [
    {
      name: "DOC_LOD_SIGNED",
      label: "Dokumen LOD SIGN",
      required: true,
      isContainer: false,
    },
    // {
    //   name: "DOC_SKGR",
    //   label: "Dokumentasi SKGR",
    //   required: true,
    // },
  ];

  const fieldsAdditional = [
    {
      name: "DOC_CLAIM",
      label:
        "Formulir klaim yang telah diisi lengkap disertai dengan perincian jumlah kerugian yang ditanda tangani & stampel sesuai atas nama Polis (terlampir)",
      required: true,
      isContainer: true,
    },
    {
      name: "DOC_ARRIVAL_NOTICE",
      label: "Arrival Notice",
      required: true,
      isContainer: false,
    },
    {
      name: "DOC_EIR",
      label: "Equipment Interchange Receipt (EIR)",
      required: true,
      isContainer: true,
    },
    {
      name: "DOC_EOR",
      label: "Estimate of Repair (EOR) perbaikan dalam hal kerusakan",
      required: true,
      isContainer: true,
    },
    {
      name: "DOC_INVOICE",
      label: "Invoice/ tagihan perbaikan dalam hal kerusakan (partial loss)",
      required: true,
      isContainer: true,
    },
    {
      name: "DOC_DOCUMENTATION",
      label:
        "Foto - foto dokumentasi dan atau rekaman video (kontainer yang rusak)",
      required: true,
      isContainer: true,
    },
    {
      name: "DOC_POLICE_REPORT",
      label: "Laporan Kepolisian (Theft & Burglary) ",
      required: false,
      isContainer: true,
    },
  ];

  const [conversationsLoaded, setConversationsLoaded] = useState(false);

  useEffect(() => {
    setLoading(true);
    const getAllConversations = async () => {
      await axiosGet({
        url: `/claim-kca/id/${decode}`,
        callback: (res) => {
          setDetail(res.data);
          setStatus(res?.data?.claimContainerStatus);
          const claimContainerDocument = res.data.claimContainerDocument;
          setDocs(claimContainerDocument);
          setDocsOther(
            claimContainerDocument?.filter((doc) => doc.type === "DOC_OTHER")
          );
          setLoading(false);
          setConversationsLoaded(true);
        },
        errorCallback: (err) => {
          if (
            err.statusCode &&
            (err.statusCode === constants.HTTP_STATUS_UNAUTHORIZED ||
              err.statusCode === constants.HTTP_STATUS_FAILED ||
              err.statusCode === constants.HTTP_STATUS_ERROR ||
              err.statusCode === constants.HTTP_STATUS_SERVER_ERROR)
          ) {
            navigate("/");
          }
          setLoading(false);
          setErr(err);
        },
      });
    };
    getAllConversations();
  }, [decode, navigate]);

  useEffect(() => {
    setLoadingContainer(true);
    if (isAdminKca && conversationsLoaded === true) {
      const getInvConversations = async () => {
        await axiosGet({
          url: `/claim-kca/container/${detail?.noClaim}`,
          callback: (res) => {
            setDataCargoContainer(res.data);
            setLoadingContainer(false);
          },
          errorCallback: (err) => {
            if (
              err.statusCode &&
              (err.statusCode === constants.HTTP_STATUS_UNAUTHORIZED ||
                err.statusCode === constants.HTTP_STATUS_FAILED ||
                err.statusCode === constants.HTTP_STATUS_ERROR ||
                err.statusCode === constants.HTTP_STATUS_SERVER_ERROR)
            ) {
              navigate("/");
            }
            setLoadingContainer(false);
            setLoading(false);
            setErr(err);
          },
        });
      };
      getInvConversations();
    }
    //eslint-disable-next-line
  }, [detail]);

  const [error, setErr] = useState(null);
  const [showAlert, setShowAlert] = useState(null);

  const [nextDisabled, setNextDisabled] = useState(false);

  const handleNext = (data) => {
    setLoadingSubmit(true);
    const body = {
      noClaim: detail.noClaim,
      description: null,
      metadata: "",
    };

    axiosPut({
      url: `/claim-kca/approve-initial`,
      data: body,
      callback: (res) => {
        setShowAlert(true);
        setLoadingSubmit(false);
        setTimeout(() => {
          navigate(`/v2/perbaikan-kontainer/detail/${params.id}`);
        }, 2000);
      },
      errorCallback: (err) => {
        setLoadingSubmit(false);
        setShowAlert(true);
        setErr(err);
      },
    });
  };

  const handleNextUpload = (data) => {
    setLoadingSubmit(true);
    const body = {
      noClaim: detail.noClaim,
      description: null,
      metadata: "",
    };

    axiosPut({
      url:
        status[0]?.statusCode === "140"
          ? `claim-kca/submit-lod-sign`
          : `claim-kca/checking-document`,
      // url: `/claim-kca/submit-initial-to-insurance`,
      data: body,
      callback: (res) => {
        setLoadingSubmit(false);
        setShowAlert(true);
        setTimeout(() => {
          navigate(`/v2/claim/detail/${params.id}`);
        }, 2000);
      },
      errorCallback: (err) => {
        setShowAlert(true);
        setLoadingSubmit(false);
        setErr(err);
      },
    });
  };

  const disableNext = (value) => {
    setNextDisabled(value);
  };

  const allInsuredByProvis = detail?.claimContainerDetail.every(
    (item) => item.insurerBy === "PROVIS"
  );
  return loading ? (
    <div
      className="d-flex justify-content-center align-items-center"
      style={{ minHeight: "100vh" }}
    >
      <SpinnerPrimary />
    </div>
  ) : (
    <div className="container">
      <BannerImage isAdmin={isAdminKca} />
      <Row>
        <Col lg={8}>
          {(status[0]?.statusCode === "102" ||
            status[0]?.statusCode === "110" ||
            status[0]?.statusCode === "111") && (
            <div className="d-flex justify-content-between align-items-center mb-2">
              <div>
                <p className="mb-0">
                  <strong>{detail?.containerNo}</strong>
                </p>
                <span>No. Kontainer</span>
              </div>

              {!allInsuredByProvis && (
                <div>
                  <a
                    href="/Formulir_Klaim.doc"
                    download
                    className="download-form-wrapper px-3 py-1"
                  >
                    <span>Download Dokumen Form Klaim</span>
                    <div className="download-icon">
                      <FontAwesomeIcon icon={faDownload}></FontAwesomeIcon>
                    </div>
                  </a>
                </div>
              )}
            </div>
          )}
          <FileForm
            statusCode={status[0]?.statusCode}
            fields={
              status[0]?.statusCode === "101"
                ? fieldsRepairs
                : status[0]?.statusCode === "102" ||
                  status[0]?.statusCode === "110" ||
                  status[0]?.statusCode === "111"
                ? fieldsAdditional
                : status[0]?.statusCode === "140"
                ? fieldsLodSign
                : []
            }
            formId={formId}
            handleNext={
              status[0]?.statusCode === "102" ||
              status[0]?.statusCode === "110" ||
              status[0]?.statusCode === "111" ||
              status[0]?.statusCode === "140"
                ? handleNextUpload
                : handleNext
            }
            data={1}
            detail={detail}
            docs={docs}
            docsOther={docsOther}
            disableNext={disableNext}
          />
        </Col>
        <Col lg={4} className="detail-cert-mb">
          <DetailClaimCertificate
            dataContainer={dataCargoContainer}
            dataClaim={detail}
            statusHistory={status}
            isAdmin={isAdminKca}
            isLoadingContainer={loadingContainer}
          />
        </Col>
      </Row>
      <Row>
        <Col md={12} className="text-end mt-3">
          <Button
            className="back-button"
            form={formId}
            type="submit"
            disabled={nextDisabled}
          >
            {loadingSubmit ? (
              <Spinner animation="border" size="sm" />
            ) : (
              "Selanjutnya"
            )}
          </Button>
        </Col>
      </Row>

      <Alert
        message={`Submit Berhasil!`}
        show={showAlert}
        error={error}
        handleClose={() => setShowAlert(false)}
      />
    </div>
  );
}
