export const setDataUser = (payload) => {
  return {
    type: "SET_DATA_USER",
    payload,
  };
};

export const setIsUserAdminKca = (payload) => {
  return {
    type: "SET_USER_ADMIN_KCA",
    payload,
  };
};

export const resetUser = (payload) => {
  return {
    type: "RESET_USER",
    payload,
  };
};
