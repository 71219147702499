import React, { useState } from "react";
import { Button, Modal, Spinner } from "react-bootstrap";
import axiosPut from "../../helpers/axiosPut";
import Alert from "../Alert/Alert";
import styled from "styled-components";

const StyledActionButton = styled.button`
  background: linear-gradient(180deg, #006881, rgba(67, 186, 148, 0) 136.98%);
  border: none;
  min-width: 130px;
  padding: 8px 25px;
  font-weight: 800;
  font-size: 14px;
  border-radius: 20px;
  box-shadow: 0px 3px 4px 0px rgba(196, 196, 196, 1);
  color: #fff;

  &:disabled {
    background: #8ecfd7;
  }
`;

export default function RequestRepairs({
  detail,
  loadingRepair,
  dataEorClaim,
  selectedId,
}) {
  const [loading, setLoading] = useState(false);
  const [show, setShow] = useState(false);
  const [error, setError] = useState(null);

  const [modal, setModal] = useState(false);
  const toggleModal = () => setModal(!modal);

  const handleAjukanRepair = () => {
    setLoading(true);

    // Filter objek eorList berdasarkan selectedId
    const lists = dataEorClaim.reduce((acc, curr) => {
      const filteredEorList = curr.eorList.filter((item) =>
        selectedId.includes(item.id)
      );
      return acc.concat(filteredEorList);
    }, []);

    const body = {
      claimContainerId: detail?.id,
      list: lists,
    };
    axiosPut({
      url: "/claim-kca/adjust-eor",
      data: body,
      callback: (res) => {
        setModal(false);
        setShow(true);
        setLoading(false);
        setTimeout(() => {
          window.location.reload();
        }, 3000);
      },
      errorCallback: (err) => {
        setLoading(false);
        setShow(true);
        setError(err);
      },
    });
  };
  return (
    <div>
      <StyledActionButton
        type="button"
        color="primary"
        size="small"
        className="text-center w-100 mb-3"
        onClick={() => toggleModal()}
        disabled={loading || loadingRepair}
      >
        Ajukan Revisi EOR
      </StyledActionButton>

      <Modal
        show={modal}
        onHide={toggleModal}
        size="md"
        backdropClassName="custom-backdrop"
      >
        <Modal.Header closeButton>
          <Modal.Title style={{ fontSize: "15px" }}>
            Konfirmasi Ajukan Revisi EOR
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="text-center">
            <label htmlFor="description" className="mb-2 mt-2">
              Apakah anda yakin ingin melanjutkan?
            </label>
            <div className="mt-2 mb-2">
              <Button variant="outline-secondary" onClick={toggleModal}>
                Tidak
              </Button>{" "}
              <Button
                variant="secondary"
                disabled={loading}
                onClick={handleAjukanRepair}
              >
                {loading ? <Spinner animation="border" size="sm" /> : "Ya!"}
              </Button>{" "}
            </div>
          </div>
        </Modal.Body>
      </Modal>
      <Alert
        message="Ajukan Revisi EOR Berhasil!"
        show={show}
        error={error}
        handleClose={() => setShow(false)}
      />
    </div>
  );
}
