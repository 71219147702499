import React, { useEffect, useState } from "react";
import SpinnerPrimary from "../../../components/Spinner/Spinner";
import BannerImage from "../../../components/Banner/BannerImage";
import { useSelector } from "react-redux";
import { Button, Card, Col, Form, Row } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft, faArrowRight } from "@fortawesome/free-solid-svg-icons";
import moment from "moment";
import TableContainer from "../../../components/DataTable/TableContainer";
import "../Claim.css";
import axiosPut from "../../../helpers/axiosPut";
import Alert from "../../../components/Alert/Alert";
import CreateColumnEor from "../../../components/Formating/CreateColumnEor";

export default function ClaimSummary() {
  const isAdminKca = useSelector((state) => state.user.isUserAdminKCa);
  const dataClaim = useSelector((state) => state.claim.dataClaimSubmission);
  const [loading, setLoading] = useState(false);
  const [attachmentEor, setAttachmentEor] = useState([]);
  const [show, setShow] = useState(false); //toast
  const [error, setError] = useState(null);
  const navigate = useNavigate();

  const docEor = localStorage.getItem("DOC_EOR");
  const getDocDocumentationItems = () => {
    const items = {};
    for (let i = 0; i < localStorage.length; i++) {
      const key = localStorage.key(i);
      if (key.startsWith("docDocumentation")) {
        items[key] = localStorage.getItem(key);
      }
    }
    return items;
  };

  // Retrieve the docDocumentation items
  const docItems = getDocDocumentationItems();

  useEffect(() => {
    setLoading(true);
    const attachment = dataClaim?.attachment;
    const details = dataClaim?.details;
    const dataEor = dataClaim?.dataEor;

    const result = details.map((detail) => {
      const attachmentItem = attachment.find(
        (attach) => attach.id === detail.attachmentId
      );
      const eorItem = dataEor.find((eor) => eor.id === detail.id);
      return {
        ...eorItem,
        attachmentId: detail.attachmentId,
        containerNo: attachmentItem.containerNo,
        gateInDate: attachmentItem.gateInDate,
      };
    });

    setAttachmentEor(result);
    setTimeout(() => {
      setLoading(false);
    }, 2000);
    // eslint-disable-next-line
  }, []);

  const onSubmit = () => {
    setLoading(true);
    const claimContainerId = dataClaim?.claimContainerId;
    const attachment = dataClaim?.attachment;
    const details = dataClaim?.details;

    const result = details.map((detail) => {
      const matchedAttachment = attachment.find(
        (att) => att.id === detail.attachmentId
      );
      return {
        id: detail.id,
        attachmentId: detail.attachmentId,
        gateInDate: matchedAttachment.gateInDate,
        sizeType: matchedAttachment.sizeType,
        sealNo: matchedAttachment.sealNo,
        jobNo: matchedAttachment.jobNo,
        containerNo: matchedAttachment.containerNo,
      };
    });

    const body = {
      claimContainerId,
      details: result,
    };

    axiosPut({
      url: `/claim-kca/detail/attachment`,
      data: body,
      callback: (res) => {
        const encode = window.btoa(res?.data?.id);
        setLoading(false);
        setShow(true);
        localStorage.removeItem("DOC_EOR");
        for (let i = localStorage.length - 1; i >= 0; i--) {
          const key = localStorage.key(i);

          if (key && key.startsWith("docDocumentation")) {
            localStorage.removeItem(key);
          }
        }
        setTimeout(() => {
          navigate(
            `${
              isAdminKca ? "/v2/perbaikan" : "/v2/perbaikan-kontainer"
            }/detail/${encode}`
          );
        }, 3000);
      },
      errorCallback: (err) => {
        setLoading(false);
        setShow(true);
        console.log(err);
        setError(err);
      },
    });
  };

  // const columns = [
  //   {
  //     dataField: "resp",
  //     text: "RESP",
  //   },
  //   {
  //     dataField: "comp",
  //     text: "COMP",
  //   },
  //   {
  //     dataField: "compName",
  //     text: "COMPONENT NAME",
  //     headerStyle: { width: "20%" },
  //     editor: {
  //       type: Type.TEXTAREA,
  //     },
  //   },
  //   {
  //     dataField: "lcNo",
  //     text: "LC",
  //   },
  //   {
  //     dataField: "dc",
  //     text: "DC",
  //   },
  //   {
  //     dataField: "rc",
  //     text: "RC",
  //   },
  //   {
  //     dataField: "dms",
  //     text: "DMS",
  //   },
  //   {
  //     dataField: "qty",
  //     text: "QTY",
  //   },
  //   {
  //     dataField: "matl",
  //     text: "MATL",
  //   },
  //   {
  //     dataField: "hr",
  //     text: "HR",
  //   },
  //   {
  //     dataField: "subTtl",
  //     text: "SUBTTL",
  //     headerStyle: { width: "10%" },
  //     formatter: CurrencyFormat,
  //   },
  // ];

  const columns = dataClaim?.headerEor.map(CreateColumnEor);

  return loading ? (
    <div
      className="d-flex justify-content-center align-items-center"
      style={{ minHeight: "100vh" }}
    >
      <SpinnerPrimary />
    </div>
  ) : (
    <div className="container">
      <BannerImage isAdmin={isAdminKca} />

      <Row>
        <Col xs={12} md={12}>
          <Card className="card-wrapper p-4 mb-4">
            <Card.Header className="card-main-header">
              Detail Perbaikan
            </Card.Header>
            <Card.Body className="main-card">
              <p className="title">Consignee</p>
              <div className="insured">
                <h5 className="m-0">{dataClaim?.insuredReceiverName}</h5>
              </div>

              <Row className="mt-4">
                <Col md={6}>
                  <p className="title">Gate In Depo</p>
                  <p className="value">
                    {moment(attachmentEor[0]?.gateInDate).format(
                      "DD MMMM YYYY"
                    )}
                  </p>
                  <p className="title">Nama PIC</p>
                  <p className="value">{dataClaim?.picName ?? "-"}</p>

                  <p className="title">Vessel</p>
                  <p className="value">{dataClaim?.conveyance ?? "-"}</p>
                </Col>
                <Col md={6}>
                  <p className="title">Email PIC</p>
                  <p className="value">{dataClaim?.picEmail ?? "-"}</p>

                  <p className="title">No Handphone PIC</p>
                  <p className="value">{dataClaim?.picPhoneNumber ?? "-"}</p>
                </Col>
              </Row>
            </Card.Body>
          </Card>

          <Card className="card-wrapper p-4 mb-4">
            <Card.Body className="main-card">
              <Row>
                {attachmentEor?.map((item, index) => (
                  <Col md={12} key={index}>
                    <Row className="mb-3">
                      <Col md={12} className="d-flex justify-content-between">
                        <Form.Label>
                          Container No : <strong>{item.containerNo}</strong>
                        </Form.Label>
                        <Form.Label>
                          Gate In Depo :{" "}
                          <strong>
                            {moment(item.gateInDate).format("DD MMMM YYYY")}
                          </strong>
                        </Form.Label>
                      </Col>
                      <Col md={12} className="mt-2 text-end">
                        <small>
                          SubTotal : <strong>{item.amount}</strong>
                        </small>
                      </Col>
                    </Row>

                    <div className="tb-eor mt-3">
                      <TableContainer
                        data={item.eorList}
                        cols={columns}
                        eor
                        form
                      />
                    </div>
                    <hr />
                  </Col>
                ))}
              </Row>
            </Card.Body>
          </Card>

          <Card
            className="card-wrapper p-4 mb-4"
            style={{ minHeight: "100px" }}
          >
            <Card.Header className="card-main-header">
              Daftar Lampiran
            </Card.Header>
            <Card.Body className="main-card">
              <div className="d-flex justify-content-between">
                <div>
                  <div>Dokumen EOR</div>
                  <small style={{ color: "#6b6b6b" }}>
                    {dataClaim?.filenameEor}
                  </small>
                </div>
                <div>
                  <a
                    className="download-doc"
                    href={docEor}
                    download={dataClaim?.filenameEor}
                  >
                    Unduh
                  </a>
                </div>
              </div>
              <hr />
              {attachmentEor?.map((item, index) => (
                <>
                  <div className="d-flex justify-content-between align-items-center">
                    <div>
                      <div>
                        Foto - foto dokumentasi dan atau rekaman video
                        (kontainer yang rusak){" "}
                        <strong>({item.containerNo})</strong>
                      </div>
                      <small style={{ color: "#6b6b6b" }}>
                        {dataClaim[`docDocumentation${index}`]}
                      </small>
                    </div>
                    <div>
                      <a
                        className="download-doc"
                        download={dataClaim[`docDocumentation${index}`]}
                        href={docItems[`docDocumentation${index}`]}
                      >
                        Unduh
                      </a>
                    </div>
                  </div>
                  <hr />
                </>
              ))}
            </Card.Body>
          </Card>
        </Col>
      </Row>

      <Row className="mt-3">
        <Col lg={12} className="d-flex justify-content-between">
          <Link
            to={"/v2/perbaikan-kontainer/submission/detail-attachment"}
            style={{ textDecoration: "none" }}
          >
            <Button
              type="button"
              color="primary"
              size="small"
              className="d-flex justify-content-between align-items-center back-button"
            >
              <FontAwesomeIcon icon={faArrowLeft}></FontAwesomeIcon>
              KEMBALI
            </Button>
          </Link>
          <Button
            type="submit"
            color="primary"
            size="small"
            className="d-flex justify-content-between align-items-center back-button"
            onClick={onSubmit}
          >
            SELANJUTNYA
            <FontAwesomeIcon icon={faArrowRight}></FontAwesomeIcon>
          </Button>
        </Col>
      </Row>

      <Alert
        message="Submit Berhasil. Mohon menunggu..."
        show={show}
        error={error}
        handleClose={() => setShow(false)}
      />
    </div>
  );
}
