export default function FormatSpmData(data) {
  // hilangkan value "NO" dari array
  const filteredData = data.filter((item) => item !== "NO");

  // cari  WIDTH dan LENGTH
  const widthIndex = filteredData.indexOf("WIDTH");
  const lengthIndex = filteredData.indexOf("LENGTH");

  // Replace "WIDTH" dan "LENGTH" menjadi "WIDTH | LENGTH"
  if (widthIndex !== -1 && lengthIndex !== -1) {
    filteredData[widthIndex] = "WIDTH | LENGTH";
    filteredData.splice(lengthIndex, 1); // Remove "LENGTH"
  }

  filteredData.unshift("COMPONENT NAME");

  return filteredData;
}
