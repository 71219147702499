import React from "react";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import SearchForm from "../../components/Form/SearchForm";
import { Accordion, Container, Card, Nav } from "react-bootstrap";
import ScrollToHere from "../../components/Scroll/ScrollToHere";
import axiosGet from "../../helpers/axiosGet";
import DataTable from "../../components/DataTable/DataTable";
import styled from "styled-components";
import {
  resetClaim,
  setDataEorClaim,
  setSearchClaim,
} from "../../store/claim/actions";
import constants from "../../constants/constants";
import { useNavigate } from "react-router-dom";
import BannerImage from "../../components/Banner/BannerImage";
import StatusClaim from "../../locales/ListStatusClaim";

const StyledAccordion = styled(Accordion)`
  .accordion-item:last-of-type {
    border-radius: 16px;
    border: none;
  }

  .accordion-body {
    padding: 30px 40px;
  }

  .accordion-item:first-of-type .accordion-button {
    border-radius: 10px;
    background: #fff;
    border: none;
    box-shadow: none;
  }

  .accordion-item:first-of-type .accordion-button:not(.collapsed) {
    border-radius: 10px 10px 0px 0px;
    border-bottom: 1px solid #eaeaea;
  }
`;

const ListClaimUser = () => {
  const dataSearchClaim = useSelector((state) => state.claim.dataSearchClaim);
  const isAdminKca = useSelector((state) => state.user.isUserAdminKCa);
  const { storedValues, storedPage } = dataSearchClaim;
  const currentPath = window.location.pathname;

  const [dataClaim, setDataClaim] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [totalPage, setTotalPage] = useState(0);
  const [active, setActive] = useState("");
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [filteredStatus, setFilteredStatus] = useState([]);

  useEffect(() => {
    setLoading(true);
    setError(null);
    setDataEorClaim();

    const valuesToRemove = [
      "110",
      "111",
      "120",
      "121",
      "130",
      "140",
      "150",
      "200",
      "201",
      "210",
      "220",
      "221",
      "230",
      "300",
      "310",
    ];

    const filteredStatusList = StatusClaim?.filter(
      (item) => !valuesToRemove.includes(item.value)
    );
    setFilteredStatus(filteredStatusList);
    axiosGet({
      url: `/claim-kca/page/${storedPage}?containerNo=${
        storedValues.containerNo
      }&accidentType=&minAmount=&maxAmount=&startDate=${
        storedValues.startDateClaim
      }&endDate=${storedValues.endDateClaim}&statusCode=${
        active !== "" ? active : storedValues.status
      }&blNo=${storedValues.blNo}&insuredReceiverName=${
        storedValues.consignee
      }&vesselName=${storedValues.vessel}&principle=${
        storedValues.principle
      }&route=${storedValues.route}&startSurveyDate=${
        storedValues.surveyDate
      }&endSurveyDate=${storedValues.surveyDate}`,
      callback: (res) => {
        setLoading(false);
        setDataClaim(res.data.data);
        setTotalPage(res?.data?.totalPage);
      },
      errorCallback: (err) => {
        if (
          err.statusCode &&
          (err.statusCode === constants.HTTP_STATUS_UNAUTHORIZED ||
            err.statusCode === constants.HTTP_STATUS_FAILED ||
            err.statusCode === constants.HTTP_STATUS_ERROR ||
            err.statusCode === constants.HTTP_STATUS_SERVER_ERROR)
        ) {
          navigate("/");
        }
        setLoading(false);
        setError(err);
      },
    });
  }, [
    active,
    navigate,
    storedPage,
    storedValues.containerNo,
    storedValues.blNo,
    storedValues.consignee,
    storedValues.vessel,
    storedValues.principle,
    storedValues.route,
    storedValues.premium,
    storedValues.startDateClaim,
    storedValues.endDateClaim,
    storedValues.status,
    storedValues.surveyDate,
  ]);

  const handleSearch = (data) => {
    ScrollToHere("search-form");
    dispatch(
      setSearchClaim({
        storedValues: data,
        storedPage: 1,
      })
    );
  };

  const handlePageClick = (e) => {
    ScrollToHere("search-form");

    const page = e.selected + 1;
    dispatch({
      type: "SET_DATA_SEARCH_CLAIM",
      payload: {
        ...dataSearchClaim,
        storedPage: page,
      },
    });
  };

  const handleReset = () => {
    ScrollToHere("search-form");
    setActive("");
    dispatch(resetClaim());
  };

  const inputs = [
    {
      name: "blNo",
      label: "No. BL",
      type: "text",
      placeholder: "No. BL",
    },
    {
      name: "containerNo",
      label: "No Kontainer",
      type: "text",
      placeholder: "No Kontainer",
    },
    {
      name: "consignee",
      label: "Consignee",
      type: "text",
      placeholder: "Consignee",
    },
    {
      name: "vessel",
      label: "Vessel",
      type: "text",
      placeholder: "Vessel",
    },
    {
      name: "principle",
      label: "Principle",
      type: "text",
      placeholder: "Principle",
    },
    {
      name: "route",
      label: "Rute",
      type: "text",
      placeholder: "Rute",
    },
    {
      name: "surveyDate",
      label: "Gate In Depo",
      type: "date",
      placeholder: "Gate In Depo",
    },
    // {
    //   name: "endDateClaim",
    //   label: "Tanggal Akhir Perbaikan",
    //   type: "date",
    //   placeholder: "Tanggal Akhir Perbaikan",
    // },
    {
      name: "status",
      label: "Status",
      type: "select",
      placeholder: "Status",
      options: filteredStatus,
    },
  ];

  const headers = [
    {
      title: ["No. BL"],
    },
    {
      title: ["No. Container"],
    },
    {
      title: ["Consignee", "Vessel", "Principle"],
    },
    {
      title: ["Rute", "Gate In Depo"],
    },
    {
      title: ["Status"],
    },
  ];

  const isDirty = () => {
    const obj = { ...storedValues };

    const keys = Object.keys(obj);

    let dirty = false;
    keys.forEach((key) => {
      if (obj[key] !== "") {
        dirty = true;
      }
    });

    return dirty;
  };

  const tabs = [
    { label: "Container List In Depo", statusCode: "100" },
    { label: "Repair Approved", statusCode: "101" },
    { label: "Repair Rejected", statusCode: "400" },
    { label: "Approval MNR", statusCode: "103" },
    { label: "Done", statusCode: "102,110,120,200,130,140,150,300,310" },
  ];

  const tabsClaim = [
    { label: "All List", statusCode: "" },
    { label: "Ready to Claim", statusCode: "110" },
    { label: "Claim Requested", statusCode: "120,130,140,150,200,300" },
    { label: "Finish Claim", statusCode: "310" },
  ];

  return (
    <section>
      <Container className="py-4">
        <BannerImage isAdmin={isAdminKca} />
        <div id="search-form">
          <StyledAccordion title="Search" isDirty={isDirty()}>
            <Accordion.Item eventKey="0">
              <Accordion.Header>Cari</Accordion.Header>
              <Accordion.Body>
                <SearchForm
                  inputs={inputs}
                  defaultValues={storedValues}
                  handleSearch={handleSearch}
                  handleReset={handleReset}
                />
              </Accordion.Body>
            </Accordion.Item>
          </StyledAccordion>
        </div>

        <div id="tab-page" className="mt-5">
          <Card
            className="w-100"
            style={{ border: "none", borderRadius: "10px" }}
          >
            <Card.Body>
              <Nav justify variant="pills">
                {currentPath === "/claim"
                  ? tabsClaim.map((item, index) => (
                      <Nav.Item key={index}>
                        <Nav.Link
                          eventKey={`link-${index}`}
                          onClick={() => setActive(item.statusCode)}
                        >
                          {item.label}
                        </Nav.Link>
                      </Nav.Item>
                    ))
                  : tabs.map((item, index) => (
                      <Nav.Item key={index}>
                        <Nav.Link
                          eventKey={`link-${index}`}
                          onClick={() => setActive(item.statusCode)}
                        >
                          {item.label}
                        </Nav.Link>
                      </Nav.Item>
                    ))}
              </Nav>
            </Card.Body>
          </Card>
        </div>

        <div className="mt-4">
          <DataTable
            active="list-claim-depo"
            headers={headers}
            data={dataClaim}
            loading={loading}
            error={error}
            totalPage={totalPage}
            onPageChange={handlePageClick}
            page={storedPage}
            isAdminKca={isAdminKca}
          />
        </div>
      </Container>
    </section>
  );
};

export default ListClaimUser;
