import moment from "moment";
import "moment/locale/id";
import React from "react";
import { Card, Col, Row } from "react-bootstrap";
import StatusHistory from "../StatusHistory.js/StatusHistory";
import CurrencyFormat from "../Formating/CurrencyFormat";

export default function DetailClaimCertificate({
  dataClaim,
  dataContainer,
  statusHistory,
  isAdmin,
  docSubmission,
  isLoadingContainer,
}) {
  return (
    <div>
      {isAdmin && (
        <>
          {isLoadingContainer ? (
            <Card
              className="card-wrapper p-4 mb-3"
              style={{ minHeight: "100px" }}
            >
              <Card.Body className="main-card">
                {[1, 2, 3].map((item) => (
                  <p className="placeholder-glow mb-2">
                    <span className="placeholder col-12"></span>
                  </p>
                ))}
              </Card.Body>
            </Card>
          ) : (
            <>
              <Card className="card-wrapper p-4" style={{ minHeight: "100px" }}>
                <Card.Body className="main-card">
                  <p className="title mb-0">No. Sertifikat</p>
                  <p className="value-left">
                    {dataContainer?.policyNumber}/
                    {dataContainer?.certificateNumber ?? "-"}
                  </p>
                  <hr />

                  <p className="title mb-0">Rute</p>
                  <p className="value-left">{dataClaim?.route}</p>

                  <p className="title mb-0">Kendaraan</p>
                  <p className="value-left">{dataClaim?.vesselName ?? "-"}</p>

                  <p className="title mb-0">Tanggal Order</p>
                  <p className="value-left">
                    {moment(dataClaim?.createdDate).format("DD MMM YYYY")}
                  </p>

                  <p className="title mb-0">Tanggal Kedatangan</p>
                  <p className="value-left">
                    {moment(dataClaim?.claim?.estDepartureDate).format(
                      "DD MMM YYYY"
                    )}
                  </p>
                </Card.Body>
              </Card>
              {!docSubmission && (
                <Card
                  className="px-3 py-4 mt-4 mb-3 card-wrapper"
                  style={{ minHeight: "90px" }}
                >
                  <Card.Body className="main-card">
                    <p className="title mb-0">Premi & Biaya Layanan</p>
                    <p className="value-left">
                      {CurrencyFormat(dataContainer?.netPremium)}
                    </p>

                    <p className="title mb-0">Biaya Materai</p>
                    <p className="value-left">
                      {CurrencyFormat(dataContainer?.stampDuty ?? 0)}
                    </p>

                    <hr />
                    <p className="title mb-0">Estimasi Biaya Perbaikan</p>
                    <p className="value-left mb-0">
                      {dataClaim?.claim?.currencyCode}{" "}
                      {CurrencyFormat(dataClaim?.claim?.claimAmount) ?? 0}
                    </p>
                  </Card.Body>
                </Card>
              )}

              {statusHistory[0]?.statusCode === "310" && (
                <Card
                  className="card-wrapper p-4 mb-3"
                  style={{ minHeight: "100px" }}
                >
                  <Card.Body className="main-card">
                    <h6 style={{ color: "#646464", fontWeight: "800" }}>
                      Bukti Bayar
                    </h6>
                    <hr />
                    <p className="title mb-0">Tanggal Pembayaran</p>
                    <p className="value-left">
                      {moment(dataClaim?.claim?.paymentDate).format(
                        "DD MMMM YYYY HH:mm [WIB]"
                      )}
                    </p>
                    <p className="title mb-0">Note</p>
                    <p className="value-left">
                      {dataClaim?.claimContainerDocument[0]?.description ?? "-"}
                    </p>
                  </Card.Body>
                </Card>
              )}
            </>
          )}
        </>
      )}

      {statusHistory && (
        <Row>
          <Col lg={12}>
            <StatusHistory statusHistory={statusHistory} />
          </Col>
        </Row>
      )}
    </div>
  );
}
