import React from "react";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import "./Declaration.css";
import SearchForm from "../../components/Form/SearchForm";
import {
  Accordion,
  Container,
  Card,
  Nav,
  Button,
  Spinner,
} from "react-bootstrap";
import ScrollToHere from "../../components/Scroll/ScrollToHere";
import axiosGet from "../../helpers/axiosGet";
import DataTable from "../../components/DataTable/DataTable";
import styled from "styled-components";
import {
  resetDeclaration,
  setSearchDeclaration,
} from "../../store/declaration/actions";
import { resetClaim } from "../../store/claim/actions";
import constants from "../../constants/constants";
import { Link, useNavigate } from "react-router-dom";
import BannerImage from "../../components/Banner/BannerImage";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faList } from "@fortawesome/free-solid-svg-icons";
import moment from "moment";
import { saveAs } from "file-saver";

const StyledAccordion = styled(Accordion)`
  .accordion-item:last-of-type {
    border-radius: 16px;
    border: none;
  }

  .accordion-body {
    padding: 30px 40px;
  }

  .accordion-item:first-of-type .accordion-button {
    border-radius: 10px;
    background: #fff;
    border: none;
    box-shadow: none;
  }

  .accordion-item:first-of-type .accordion-button:not(.collapsed) {
    border-radius: 10px 10px 0px 0px;
    border-bottom: 1px solid #eaeaea;
  }
`;

const ExportButton = styled(Button)`
  border-radius: 20px;
  border: none;
  background: #eaeaea;
  color: #646464;
  padding: 5px 25px;
  margin-right: 10px;
`;

const HistoryExportButton = styled(Button)`
  border-radius: 50%;
  border: none;
  background: #eaeaea;
  color: #646464;
  padding: 5px 10px;
`;

const Declaration = () => {
  const dataSearchDeclaration = useSelector(
    (state) => state.declaration.dataSearchDeclaration
  );
  const isAdminKca = useSelector((state) => state.user.isUserAdminKCa);
  const user = useSelector((state) => state.user);
  const { storedValues, storedPage } = dataSearchDeclaration;

  const [dataDeclaration, setDataDeclaration] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [totalPage, setTotalPage] = useState(0);
  const [activeTab, setActiveTab] = useState("");
  const dispatch = useDispatch();
  const navigate = useNavigate();
  useEffect(() => {
    dispatch(resetClaim());
    setLoading(true);
    setError(null);
    axiosGet({
      url: `/claim-cargo-container/page/${storedPage}?minDate&maxDate&insuredName=&containerNo=${
        storedValues.containerNo
      }&blNo=${storedValues.blNo}&insuredReceiverName=${
        storedValues.consignee
      }&vesselName=${storedValues.vessel}&etdFrom=${
        storedValues.arrivalDate
      }&etdTo=${storedValues.arrivalDate}&startExpiredDate=${
        storedValues.startExpiredDate
      }&endExpiredDate=${storedValues.startExpiredDate}&isClaimed=${
        storedValues.attachmentStatus === "diajukan"
          ? true
          : storedValues.isClaimed
      }&companyName=${
        isAdminKca ? storedValues.depo : user?.user?.userFullname
      }&statusCodeClaim=${
        activeTab !== "available" ? activeTab : ""
      }&attachmentStatus=${
        storedValues.attachmentStatus !== "diajukan"
          ? storedValues.attachmentStatus
          : ""
      }&startGateInDate=${storedValues.startGateInDate}&endGateInDate=${
        storedValues.startGateInDate
      }&orderBy=${activeTab === "available" ? "gateInDate" : ""}&sortBy=${
        activeTab === "available" ? "ASC" : ""
      }`,
      callback: (res) => {
        setLoading(false);
        setDataDeclaration(res.data.data);
        setTotalPage(res.data.totalPage);
      },
      errorCallback: (err) => {
        if (
          err.statusCode &&
          (err.statusCode === constants.HTTP_STATUS_UNAUTHORIZED ||
            err.statusCode === constants.HTTP_STATUS_SERVER_ERROR)
        ) {
          navigate("/");
        }
        setLoading(false);
        setError(err);
      },
    });
  }, [
    dispatch,
    navigate,
    activeTab,
    storedPage,
    storedValues.containerNo,
    storedValues.blNo,
    storedValues.consignee,
    storedValues.vessel,
    storedValues.arrivalDate,
    storedValues.isClaimed,
    storedValues.startExpiredDate,
    isAdminKca,
    user.user.userFullname,
    storedValues.depo,
    storedValues.attachmentStatus,
    storedValues.startGateInDate,
  ]);

  const handleSearch = (data) => {
    ScrollToHere("search-form");
    dispatch(
      setSearchDeclaration({
        storedValues: data,
        storedPage: 1,
      })
    );
  };

  const handlePageClick = (e) => {
    ScrollToHere("search-form");

    const page = e.selected + 1;
    dispatch(
      setSearchDeclaration({
        ...dataSearchDeclaration,
        storedPage: page,
      })
    );
  };

  const handleReset = () => {
    ScrollToHere("search-form");
    dispatch(resetDeclaration());
    setActiveTab("");
  };

  const depoList = [
    {
      value: "PT. Karya Gemilang Selatan",
      label: "PT. Karya Gemilang Selatan",
    },
    {
      value: "PT. Segara Pasific Maju",
      label: "PT. Segara Pasific Maju",
    },
  ];

  const inputs = [
    {
      name: "blNo",
      label: "No B/L",
      type: "text",
      placeholder: "No B/L",
    },
    {
      name: "containerNo",
      label: "No. Container",
      type: "text",
      placeholder: "No. Container",
    },
    {
      name: "consignee",
      label: "Consignee",
      type: "text",
      placeholder: "Consignee",
    },
    {
      name: "vessel",
      label: "Vessel",
      type: "text",
      placeholder: "Vessel",
    },
    {
      name: "arrivalDate",
      label: "ATA",
      type: "date",
      placeholder: "ATA",
    },
    {
      name: "startExpiredDate",
      label: "Valid D/O",
      type: "date",
      placeholder: "Valid D/O",
    },
    {
      name: "isClaimed",
      label: "Status",
      type: "select",
      placeholder: "Status",
      options: [
        {
          label: "List Container Damage",
          value: true,
        },
        {
          label: "Waiting Gate In Depo",
          value: false,
        },
      ],
    },
    {
      name: "startGateInDate",
      label: "Gate In Depo",
      type: "date",
      placeholder: "Gate In Depo",
    },
    {
      name: "attachmentStatus",
      label: "Status Kontainer",
      type: "select",
      placeholder: "Status Kontainer",
      options: [
        {
          label: "Rusak (damaged)",
          value: "damaged",
        },
        {
          label: "Tersedia (available)",
          value: "available",
        },
        {
          label: "Diajukan",
          value: "diajukan",
        },
      ],
    },
  ];

  const headers = [
    {
      title: ["No. B/L", "No. Container"],
    },
    {
      title: ["Consignee", "Vessel"],
    },
    {
      title: ["ATA", "Valid D/O", "Gate In Depo"],
    },
    {
      title: ["Idle"],
    },
  ];

  if (isAdminKca) {
    headers.splice(2, 0, {
      title: ["Depo"],
    });

    inputs.splice(7, 0, {
      name: "depo",
      label: "Depo",
      type: "select",
      placeholder: "Depo",
      options: depoList,
    });
  }

  const isdirty = () => {
    const obj = { ...storedValues };

    const keys = Object.keys(obj);

    let dirty = false;
    keys.forEach((key) => {
      if (obj[key] !== "") {
        dirty = true;
      }
    });

    return dirty;
  };
  const tabs = [
    { label: "Available Container", type: "available" },
    { label: "Import List", type: "0" },
    { label: "Container List In Depo", type: "100" },
    { label: "Approval MNR", type: "103" },
    { label: "Finish Approval", type: "102,110,120,200,130,140,150,300,310" },
  ];

  const [loadingGenerate, setLoadingGenerate] = useState(false);
  const [dataHistoryExport, setDataHistoryExport] = useState([]);
  const [isModalHistory, setModalHistory] = useState(false);
  const [loadingReport, setLoadingReport] = useState(false);
  const [indexLoad, setIndexLoad] = useState("");
  const [flag, setFlag] = useState(0);
  const [flagHistory, setFlagHistory] = useState(0);

  const handleGenerate = () => {
    setLoadingGenerate(true);
    axiosGet({
      url: `/transaction/report/generate?containerNo=${
        storedValues.containerNo
      }&blNo=${storedValues.blNo}&insuredReceiverName=${
        storedValues.consignee
      }&vesselName=${storedValues.vessel}&etdFrom=${
        storedValues.arrivalDate
      }&etdTo=${storedValues.arrivalDate}&startExpiredDate=${
        storedValues.startExpiredDate
      }&endExpiredDate=${storedValues.startExpiredDate}&isClaimed=${
        storedValues.attachmentStatus === "diajukan"
          ? true
          : storedValues.isClaimed
      }&companyName=${
        isAdminKca ? storedValues.depo : user?.user?.userFullname
      }&statusCodeClaim=${
        activeTab !== "available" ? activeTab : ""
      }&attachmentStatus=${
        storedValues.attachmentStatus !== "diajukan"
          ? storedValues.attachmentStatus
          : ""
      }&startGateInDate=${storedValues.startGateInDate}&endGateInDate=${
        storedValues.startGateInDate
      }&orderBy=${activeTab === "available" ? "gateInDate" : ""}&sortBy=${
        activeTab === "available" ? "ASC" : ""
      }`,
      callback: (res) => {
        setFlag((prev) => prev + 1);
        setLoadingGenerate(false);
        setModalHistory(true);
      },
      errorCallback: (err) => {
        setLoadingGenerate(false);
        setError(err);
      },
    });
  };

  useEffect(() => {
    axiosGet({
      url: `/report/page/1?maxCount=5&source=TRANSACTION`,
      callback: (res) => {
        setDataHistoryExport(res.data.data);
      },
      errorCallback: (err) => {
        console.log(err);
      },
    });
  }, [flag, dispatch, flagHistory, isModalHistory]);

  useEffect(() => {
    const timer = setTimeout(() => {
      if (dataHistoryExport[0]?.status !== 100) {
        setFlagHistory((prev) => prev + 1);
      }
    }, 5000);
    return () => clearTimeout(timer);
  }, [dataHistoryExport]);

  const handleOpenModalHistory = () => {
    if (isModalHistory) {
      return setModalHistory(false);
    } else {
      return setModalHistory(true);
    }
  };

  const downloadReport = (uniqId, type, createdDate) => {
    setLoadingReport(true);
    setIndexLoad(uniqId);
    axiosGet({
      url: `/report/download/${uniqId}`,
      responseType: "blob",
      callback: () => {
        setLoadingReport(false);
      },
      errorCallback: (res) => {
        setLoadingReport(false);
        let file = new File(
          [res],
          `${moment(createdDate).format("DD-MM-YYYY")}-${type}`
        );
        saveAs(file);
      },
    });
  };

  return (
    <section>
      <Container className="py-4">
        <BannerImage isAdmin={isAdminKca} />

        <div id="search-form">
          <StyledAccordion title="Search" isDirty={isdirty()}>
            <Accordion.Item eventKey="0">
              <Accordion.Header>Cari</Accordion.Header>
              <Accordion.Body>
                <SearchForm
                  inputs={inputs}
                  defaultValues={storedValues}
                  handleSearch={handleSearch}
                  handleReset={handleReset}
                />
              </Accordion.Body>
            </Accordion.Item>
          </StyledAccordion>
        </div>

        <div id="tab-page" className="mt-5">
          <Card
            className="w-100"
            style={{ border: "none", borderRadius: "10px" }}
          >
            <Card.Body>
              <Nav justify variant={activeTab === "" ? "" : "pills"}>
                {tabs.map((item, index) => (
                  <Nav.Item key={index}>
                    <Nav.Link
                      eventKey={`link-${index}`}
                      onClick={() => setActiveTab(item.type)}
                    >
                      {item.label}
                    </Nav.Link>
                  </Nav.Item>
                ))}
              </Nav>
            </Card.Body>
          </Card>
        </div>

        <div className="text-end mt-3">
          <div className="d-flex align-items-center justify-content-end">
            <div className="export-wrapper">
              <ExportButton onClick={handleGenerate} disabled={loadingGenerate}>
                Export{" "}
                {loadingGenerate && <Spinner animation="border" size="sm" />}
              </ExportButton>
              <HistoryExportButton onClick={handleOpenModalHistory}>
                <FontAwesomeIcon icon={faList}></FontAwesomeIcon>
              </HistoryExportButton>

              {isModalHistory && (
                <div className="export-history">
                  {dataHistoryExport.length > 0 ? (
                    <>
                      {dataHistoryExport?.map((item) => (
                        <div className="history-content">
                          <div className="history-title">
                            {item?.filename},{" "}
                            {moment(item?.createdDate).format("DD MMMM YYYY")}
                          </div>
                          <Button
                            onClick={() =>
                              downloadReport(
                                item?.uniqId,
                                item?.filename,
                                item?.createdDate
                              )
                            }
                            disabled={item?.status !== 100 || loadingReport}
                            className={`history-badge ${
                              item?.status === 100 ? "complete" : "waiting"
                            }`}
                          >
                            {item?.status === 100 &&
                            loadingReport &&
                            indexLoad === item?.uniqId
                              ? "Memuat..."
                              : item?.status === 100
                              ? "Unduh"
                              : "Menunggu Generate"}
                          </Button>
                        </div>
                      ))}
                    </>
                  ) : (
                    <>
                      <div className="history-content">
                        <div className="history-title">Tidak Ada History</div>
                      </div>
                    </>
                  )}
                </div>
              )}
            </div>
            {isAdminKca && (
              <div style={{ marginLeft: "10px" }}>
                <Link to="/list-excel">
                  <Button variant="outline-danger">History Upload</Button>{" "}
                </Link>
                <Link to="/upload-excel">
                  <Button variant="outline-success">
                    Upload Container List
                  </Button>{" "}
                </Link>
              </div>
            )}
          </div>
        </div>

        <div className="mt-4">
          <DataTable
            active="declaration"
            headers={headers}
            data={dataDeclaration}
            loading={loading}
            error={error}
            totalPage={totalPage}
            onPageChange={handlePageClick}
            page={storedPage}
            isAdminKca={isAdminKca}
          />
        </div>
      </Container>
    </section>
  );
};

export default Declaration;
