const constants = {};

constants.DEFAULT_URL = process.env.REACT_APP_API_URL;
constants.SSO_URL = process.env.REACT_APP_SSO_URL;

// user access
constants.ADMIN_KCA = "container-kca-admin";
constants.USER_KCA = "container-kca-user";

constants.HTTP_STATUS_SUCCESS = 200;
constants.HTTP_STATUS_UNAUTHORIZED = 403;
constants.HTTP_STATUS_FAILED = 401;
constants.HTTP_STATUS_ERROR = 400;
constants.HTTP_STATUS_SERVER_ERROR = 500;

constants.REJECT_REASON = [
  {
    label: "Kerusakan lebih dari 75%",
    value: "Kerusakan lebih dari 75%",
  },
  {
    label: "Container masih bisa dipakai",
    value: "Container masih bisa dipakai",
  },
  {
    label: "Lainnya",
    value: "Lainnya",
  },
];

constants.STATUS_DECLARATION = [
  {
    label: "Tersedia (available)",
    value: "available",
  },
  {
    label: "Rusak (damaged)",
    value: "damaged",
  },
];

export default constants;
