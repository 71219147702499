import React, { useEffect, useRef, useState } from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import { useForm } from "react-hook-form";
// import NumberFormat from "react-number-format";
import { useDispatch } from "react-redux";
import { setDataEorClaim } from "../../store/claim/actions";

export default function ModalFormEditEor({ data, toggleModal, dataEorClaim }) {
  const inputRef = useRef(null);
  const dispatch = useDispatch();

  const [modalData, setModalData] = useState({});

  //state form
  // const [subTotal, setSubTotal] = useState(data?.subTtl?.replace(".", ","));
  const [compName, setCompName] = useState(data?.compName);

  const { handleSubmit } = useForm({});

  useEffect(() => {
    setModalData(data);
    if (inputRef.current) {
      inputRef.current.focus();
    }
  }, [data, inputRef]);

  // const handleSubtotalChange = (event) => {
  //   const data = event.target.value;
  //   const dataResult = data.replace(/\./g, "").replace(",", ".");
  //   setModalData({ ...modalData, subTtl: dataResult });
  //   setSubTotal(data);
  // };

  const handleComponentNameChange = (event) => {
    setCompName(event.target.value);
    setModalData({ ...modalData, compName: event.target.value });
  };

  const handleNoteChange = (event) => {
    setModalData({ ...modalData, note: event.target.value });
  };

  const handleRespChange = (event) => {
    setModalData({ ...modalData, resp: event.target.value });
  };

  const handleForm = () => {
    const updatedOwner = dataEorClaim?.map((item) => {
      if (item.id === modalData.id) {
        return {
          ...item,
          subTtl: modalData.subTtl,
          compName: modalData.compName,
          resp: modalData.resp,
          note: modalData.note,
        };
      }
      return item;
    });
    dispatch(setDataEorClaim(updatedOwner));
    toggleModal();
  };
  return (
    <form onSubmit={handleSubmit(handleForm)}>
      <Row>
        <Col size="12" className="modal-eor">
          <Form.Group className="mb-3">
            <Form.Label>RESP</Form.Label>
            <Form.Select
              name="resp"
              defaultValue={data?.resp}
              onChange={handleRespChange}
            >
              <option value="">Currency Code</option>
              <option value="U">User</option>
              <option value="O">Owner</option>
            </Form.Select>
          </Form.Group>

          {/* <Form.Group className="mb-3">
            <Form.Label>Edit Sub Total</Form.Label>
            <NumberFormat
              thousandSeparator="."
              decimalSeparator=","
              value={subTotal}
              defaultValue={data?.subTtl?.replace(".", ",")}
              onChange={handleSubtotalChange}
              allowEmptyFormatting
              className={subTotal ? "form-control" : "form-control is-invalid"}
            />
            <Form.Control.Feedback type="invalid">
              *sub total tidak boleh dikosongkan!
            </Form.Control.Feedback>
          </Form.Group> */}

          <Form.Group className="mb-3">
            <Form.Label>Component Name</Form.Label>
            <Form.Control
              ref={inputRef}
              type="text"
              name="compName"
              as="textarea"
              defaultValue={data?.compName}
              rows={3}
              onChange={handleComponentNameChange}
              isInvalid={!compName}
            />
            <Form.Control.Feedback type="invalid">
              *component name tidak boleh dikosongkan!
            </Form.Control.Feedback>
          </Form.Group>

          <Form.Group className="mb-3">
            <Form.Label>Note</Form.Label>
            <Form.Control
              ref={inputRef}
              type="text"
              name="note"
              as="textarea"
              defaultValue={data?.note}
              rows={3}
              onChange={handleNoteChange}
            />
          </Form.Group>
          <div className="d-flex justify-content-end mt-4">
            <Button type="submit" disabled={!compName}>
              Submit
            </Button>
          </div>
        </Col>
      </Row>
    </form>
  );
}
