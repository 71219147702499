import React from "react";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import SearchForm from "../../components/Form/SearchForm";
import { Accordion, Container } from "react-bootstrap";
import ScrollToHere from "../../components/Scroll/ScrollToHere";
import axiosGet from "../../helpers/axiosGet";
import DataTable from "../../components/DataTable/DataTable";
import styled from "styled-components";
import {
  resetDeclaration,
  setSearchDeclaration,
} from "../../store/declaration/actions";
import { resetClaim } from "../../store/claim/actions";
import constants from "../../constants/constants";
import { useNavigate } from "react-router-dom";
import BannerImage from "../../components/Banner/BannerImage";

const StyledAccordion = styled(Accordion)`
  .accordion-item:last-of-type {
    border-radius: 16px;
    border: none;
  }

  .accordion-body {
    padding: 30px 40px;
  }

  .accordion-item:first-of-type .accordion-button {
    border-radius: 10px;
    background: #fff;
    border: none;
    box-shadow: none;
  }

  .accordion-item:first-of-type .accordion-button:not(.collapsed) {
    border-radius: 10px 10px 0px 0px;
    border-bottom: 1px solid #eaeaea;
  }
`;

const ListExcel = () => {
  const dataSearchDeclaration = useSelector(
    (state) => state.declaration.dataSearchDeclaration
  );
  const isAdminKca = useSelector((state) => state.user.isUserAdminKCa);
  const { storedValues, storedPage } = dataSearchDeclaration;

  const [dataDeclaration, setDataDeclaration] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [totalPage, setTotalPage] = useState(0);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  useEffect(() => {
    dispatch(resetClaim());
    setLoading(true);
    setError(null);
    axiosGet({
      url: `/container/excel/page/${storedPage}?startDate=${storedValues.startDate}&endDate=${storedValues.endDate}&filename=${storedValues.fileName}&userEmail=${storedValues.userEmail}`,
      callback: (res) => {
        setLoading(false);
        setDataDeclaration(res.data.data);
        setTotalPage(res.data.totalPage);
      },
      errorCallback: (err) => {
        if (
          err.statusCode &&
          (err.statusCode === constants.HTTP_STATUS_UNAUTHORIZED ||
            err.statusCode === constants.HTTP_STATUS_SERVER_ERROR)
        ) {
          navigate("/");
        }
        setLoading(false);
        setError(err);
      },
    });
  }, [
    dispatch,
    navigate,
    storedPage,
    storedValues.fileName,
    storedValues.startDate,
    storedValues.endDate,
    storedValues.userEmail,
  ]);

  const handleSearch = (data) => {
    ScrollToHere("search-form");
    dispatch(
      setSearchDeclaration({
        storedValues: data,
        storedPage: 1,
      })
    );
  };

  const handlePageClick = (e) => {
    ScrollToHere("search-form");

    const page = e.selected + 1;
    dispatch(
      setSearchDeclaration({
        ...dataSearchDeclaration,
        storedPage: page,
      })
    );
  };

  const handleReset = () => {
    ScrollToHere("search-form");
    dispatch(resetDeclaration());
  };

  const inputs = [
    {
      name: "fileName",
      label: "Nama File",
      type: "text",
      placeholder: "Nama File Excel",
    },
    {
      name: "startDate",
      label: "Tanggal Awal",
      type: "date",
      placeholder: "Tanggal Upload Awal",
    },
    {
      name: "endDate",
      label: "Tanggal Akhir",
      type: "date",
      placeholder: "Tanggal Upload Akhir",
    },
    {
      name: "userEmail",
      label: "Dibuat Oleh",
      type: "text",
      placeholder: "Dibuat Oleh",
    },
  ];

  const headers = [
    {
      title: ["No"],
    },
    {
      title: ["Nama File"],
    },
    {
      title: ["Tanggal Upload"],
    },
    {
      title: ["Dibuat Oleh"],
    },
  ];

  const isdirty = () => {
    const obj = { ...storedValues };

    const keys = Object.keys(obj);

    let dirty = false;
    keys.forEach((key) => {
      if (obj[key] !== "") {
        dirty = true;
      }
    });

    return dirty;
  };

  return (
    <section>
      <Container className="py-4">
        <BannerImage isAdmin={isAdminKca} />

        <div id="search-form">
          <StyledAccordion title="Search" isDirty={isdirty()}>
            <Accordion.Item eventKey="0">
              <Accordion.Header>Cari</Accordion.Header>
              <Accordion.Body>
                <SearchForm
                  inputs={inputs}
                  defaultValues={storedValues}
                  handleSearch={handleSearch}
                  handleReset={handleReset}
                />
              </Accordion.Body>
            </Accordion.Item>
          </StyledAccordion>
        </div>

        <div className="mt-4">
          <DataTable
            active="excel"
            headers={headers}
            data={dataDeclaration}
            loading={loading}
            error={error}
            totalPage={totalPage}
            onPageChange={handlePageClick}
            page={storedPage}
            isAdminKca={isAdminKca}
          />
        </div>
      </Container>
    </section>
  );
};

export default ListExcel;
