import moment from "moment";
import "moment/locale/id";
import React, { useEffect, useState } from "react";
import {
  Button,
  Card,
  Col,
  Modal,
  Row,
  Accordion,
  OverlayTrigger,
  Tooltip,
  Spinner,
  Badge,
} from "react-bootstrap";
import { Link, useParams, useNavigate } from "react-router-dom";
import axiosGet from "../../../helpers/axiosGet";
import "../Claim.css";
import { faArrowLeft, faPenToSquare } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import BootstrapTable from "react-bootstrap-table-next";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import paginationFactory from "react-bootstrap-table2-paginator";
import "react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css";
import { Controller, useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import TableContainer from "../../../components/DataTable/TableContainer";
import CurrencyFormat from "../../../components/Formating/CurrencyFormat";
import SpinnerPrimary from "../../../components/Spinner/Spinner";
import {
  setClaimSubmission,
  setDataEorClaim,
} from "../../../store/claim/actions";
import DetailClaimCertificate from "../../../components/DetailCertificate/DetailClaimCertificate";
import constants from "../../../constants/constants";
import DownloadDocClaim from "../../../components/DownloadDoc/DownloadDocClaim";
import BannerImage from "../../../components/Banner/BannerImage";
import StatusBar from "../../../components/StatusBar/StatusBar";
import ApproveRepairs from "../../../components/Action/ApproveRepairs";
import SubmitToInsurance from "../../../components/Action/SubmitToInsurance";
import RejectRepairs from "../../../components/Action/RejectRepairs";
import DownloadHistoryDocEor from "../../../components/DownloadDoc/DownloadHistoryDocEor";
import ModalFormEditEorV2 from "../../../components/Form/ModalFormEditEorV2";
import ModalFormRevisionEorV2 from "../../../components/Form/ModalFormRevisionEorV2";
import ReviewProvis from "../../../components/Action/ReviewProvis";
import RequestRepairs from "../../../components/Action/RequestRepairs";
import SingleDownloadFile from "../../../components/DownloadDoc/SingleDownloadFile";
import FormatSpmData from "../../../components/Formating/FormatSpmData";
import CreateColumnEor from "../../../components/Formating/CreateColumnEor";

export default function ClaimDetail() {
  const dataEorClaim = useSelector((state) => state.claim.dataEorClaim);
  const isAdminKca = useSelector((state) => state.user.isUserAdminKCa);

  const [loading, setLoading] = useState(false);
  const [loadingContainer, setLoadingContainer] = useState(false);
  const [loadingEor, setLoadingEor] = useState(false);
  const [error, setErr] = useState(null);
  const [statusHistory, setStatusHistory] = useState([]);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const params = useParams();
  const [detail, setDetail] = useState();
  const [dataCargoContainer, setDataCargoContainer] = useState(null);

  const [idClaim, setIdClaim] = useState(null);
  // const [subtotalEor, setSubtotalEor] = useState(0);
  const [selectedId, setSelectedId] = useState([]);
  const [selectedSubtotal, setSelectedSubtotal] = useState([]);
  const [currentSelectedTotal, setCurrentSelectedTotal] = useState(0);

  //data adjust eor
  const [dataAdjustEor, setDataAdjustEor] = useState([]);
  const [headerEor, setHeaderEor] = useState([
    "Comp",
    "RESP",
    "LC",
    "Component Name",
    "RC",
    "DMS",
    "DC",
    "MATL",
    "HR",
    "GRN TTL",
    "QTY",
  ]); //adjust header eor

  // state modal edit eor
  const [modalDataEor, setModalDataEor] = useState(null);

  const decode = window.atob(params.id);

  // download file
  const [docPayment, setDocPayment] = useState();
  const [docLodSigned, setDocLodSigned] = useState();
  const [docSkgr, setDocSkgr] = useState();
  const [docLod, setDocLod] = useState();

  const { control, register } = useForm();

  const fetchDetailClaim = async () => {
    setErr(null);
    dispatch(setClaimSubmission());
    await axiosGet({
      url: `/claim-kca/id/${decode}`,
      callback: (res) => {
        setLoading(false);
        setDetail(res.data);
        setIdClaim(res.data.id);
        setStatusHistory(res.data.claimContainerStatus);
        if (res.data.eorDone === false && res.data.type === "partial-loss") {
          setTimeout(fetchDetailClaim, 2000);
        }

        if (res.data.depoCode === "DP002") {
          setHeaderEor(FormatSpmData(res.data.headerEor));
        } else {
          setHeaderEor(res.data.headerEor);
        }

        const documentType = [
          "DOC_PAYMENT",
          "DOC_LOD_SIGNED",
          "DOC_SKGR",
          "DOC_LOD",
        ];
        const document = res.data.claimContainerDocument.filter((value) =>
          documentType.includes(value.type)
        );
        const docPayment = document.filter(
          (value) => value.type === documentType[0]
        );
        const docLodSigned = document.filter(
          (value) => value.type === documentType[1]
        );
        const docSkgr = document.filter(
          (value) => value.type === documentType[2]
        );
        const docLod = document.filter(
          (value) => value.type === documentType[3]
        );
        setDocPayment([
          {
            label: "Bukti Pembayaran",
            fileId: docPayment[0]?.fileId,
            fileName: docPayment[0]?.originalName,
          },
        ]);
        setDocLod([
          {
            label: "Dokumen LOD",
            fileId: docLod[0]?.fileId,
            fileName: docLod[0]?.originalName,
          },
        ]);
        setDocSkgr([
          {
            label: "Dokumen SKGR",
            fileId: docSkgr[0]?.fileId,
            fileName: docSkgr[0]?.originalName,
          },
        ]);
        setDocLodSigned([
          {
            label: "Dokumen LOD yang ditandatangani",
            fileId: docLodSigned?.fileId,
            fileName: docLodSigned?.originalName,
          },
        ]);
      },
      errorCallback: (err) => {
        if (
          err.statusCode &&
          (err.statusCode === constants.HTTP_STATUS_UNAUTHORIZED ||
            err.statusCode === constants.HTTP_STATUS_FAILED ||
            err.statusCode === constants.HTTP_STATUS_ERROR ||
            err.statusCode === constants.HTTP_STATUS_SERVER_ERROR)
        ) {
          navigate("/");
        }
        setLoading(false);
        setErr(err);
      },
    });
  };

  const fetchGetListEor = async () => {
    await axiosGet({
      url: `/claim-kca/list-eor/${decode}`,
      callback: (res) => {
        setLoadingEor(false);
        const dataEor = res?.data;
        dispatch(setDataEorClaim(dataEor)); //save to reducer (localStorage)

        // get selected id for selected all
        const result = dataEor.flatMap((item) =>
          item.eorList.map((eor) => eor.id)
        );
        setSelectedId(result);
        // end

        //get array of subtotal EOR
        const subtotal = dataEor.flatMap((item) =>
          item.eorList.map((eor) => eor.subTtl)
        );
        const totalInt = subtotal.map(parseFloat); //change to float
        setSelectedSubtotal(totalInt);
        // end

        // setSubtotalEor(countSubtotalFunc(dataEor));
        setCurrentSelectedTotal(countSubtotalFunc(dataEor));
        // end

        // data adjust eor array
        const newDataAdjust = dataEor.map((container) => {
          const newContainer = { ...container };
          const adjustArray = newContainer.eorList
            .map((eor) => eor.adjust)
            .filter(Boolean);
          newContainer.eorList.forEach((eor) => delete eor.adjust);
          newContainer.adjust = adjustArray;

          return newContainer;
        });

        // check data adjust allIsEmpty
        const allAdjustEmpty = newDataAdjust.every(
          (item) => item.adjust.length <= 0
        );

        if (!allAdjustEmpty) {
          setDataAdjustEor(newDataAdjust);
        } else {
          setDataAdjustEor([]);
        }
        // end

        // fetch function get data cargoContainer
        if (isAdminKca) {
          fetchGetCargoContainer();
        }
        // end
      },
      errorCallback: (err) => {
        if (
          err.statusCode &&
          (err.statusCode === constants.HTTP_STATUS_UNAUTHORIZED ||
            err.statusCode === constants.HTTP_STATUS_FAILED ||
            err.statusCode === constants.HTTP_STATUS_ERROR ||
            err.statusCode === constants.HTTP_STATUS_SERVER_ERROR)
        ) {
          navigate("/");
        }
        setLoading(false);
        setErr(err);
      },
    });
  };

  const fetchGetCargoContainer = async () => {
    if (detail) {
      await axiosGet({
        url: `/claim-kca/container/${detail?.noClaim}`,
        callback: (res) => {
          setDataCargoContainer(res.data);
          setLoadingContainer(false);
        },
        errorCallback: (err) => {
          if (
            err.statusCode &&
            (err.statusCode === constants.HTTP_STATUS_UNAUTHORIZED ||
              err.statusCode === constants.HTTP_STATUS_FAILED ||
              err.statusCode === constants.HTTP_STATUS_ERROR ||
              err.statusCode === constants.HTTP_STATUS_SERVER_ERROR)
          ) {
            navigate("/");
          }
          setLoadingContainer(false);
          setLoading(false);
          setErr(err);
        },
      });
    }
  };

  useEffect(() => {
    setLoading(true);
    setLoadingEor(true);
    setLoadingContainer(true);
    fetchDetailClaim();

    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (detail?.eorDone && detail?.type === "partial-loss") {
      fetchGetListEor();
      fetchGetCargoContainer();
    } else if (detail?.type === "total-loss") {
      fetchGetCargoContainer();
    }
    // eslint-disable-next-line
  }, [detail]);

  const countSubtotalFunc = (data) => {
    const totalAmount = data.reduce((total, item) => {
      const amount = parseFloat(item.amount) || 0;
      return total + amount;
    }, 0);

    return totalAmount;
  };

  const countSelectedSubtotalFunc = (data) => {
    const total = data?.reduce((accumulator, currentValue) => {
      return accumulator + parseFloat(currentValue);
    }, 0);

    return total;
  };

  const [modal, setModal] = useState(false);
  const toggleModal = () => setModal(!modal);

  const handleEditEor = (row) => {
    toggleModal();
    setModalDataEor(row);
  };

  const columns = headerEor.map(CreateColumnEor);

  if (
    isAdminKca &&
    (statusHistory[0]?.statusCode === "100" ||
      statusHistory[0]?.statusCode === "103")
  ) {
    columns?.push({
      text: "ACTION",
      headerStyle: { width: "8%" },
      formatter: (cell, row) => (
        <OverlayTrigger
          placement="right"
          delay={{ show: 250, hide: 400 }}
          overlay={<Tooltip id="button-tooltip">Edit Eor</Tooltip>}
        >
          <button
            onClick={() => handleEditEor(row)}
            className="text-primary"
            style={{
              background: "transparent",
              border: "none",
              fontSize: "15px",
            }}
          >
            <FontAwesomeIcon
              icon={faPenToSquare}
              style={{ color: "#006881" }}
            ></FontAwesomeIcon>
          </button>
        </OverlayTrigger>
      ),
    });
  }

  const customTotal = (from, to, size) => (
    <span
      className="react-bootstrap-table-pagination-total"
      style={{ fontSize: "12px" }}
    >
      {" "}
      Menampilkan {from} hingga {to} dari {size} Hasil
    </span>
  );

  const options = {
    paginationSize: 4,
    pageStartIndex: 1,
    alwaysShowAllBtns: true,
    withFirstAndLast: false,
    hideSizePerPage: true,
    hidePageListOnlyOnePage: true,
    showTotal: true,
    paginationTotalRenderer: customTotal,
    disablePageTitle: true,
    sizePerPageList: [
      {
        text: "5",
        value: 5,
      },
      {
        text: "10",
        value: 10,
      },
      //   {
      //     text: "All",
      //     value: dataEorClaim.length,
      //   },
    ],
  };

  const handleOnSelect = (row, isSelect) => {
    const totalInt = parseFloat(row.subTtl);
    if (isSelect) {
      setSelectedId([...selectedId, row.id]);
      setSelectedSubtotal([...selectedSubtotal, totalInt]);
      const currentSelected = [...selectedSubtotal, totalInt];
      setCurrentSelectedTotal(countSelectedSubtotalFunc(currentSelected));
    } else {
      const currentSelected = selectedSubtotal?.filter(
        (item) => item !== totalInt
      );

      setSelectedId(selectedId.filter((item) => item !== row.id));
      setSelectedSubtotal(currentSelected);
      setCurrentSelectedTotal(countSelectedSubtotalFunc(currentSelected));
    }
  };

  // const handleOnSelectAll = (isSelect, rows) => {
  //   const dataId = rows.map((r) => r.id);
  //   const dataSubtotal = rows.map((r) => parseFloat(r.subTtl));
  //   if (isSelect) {
  //     setSelectedId(dataId);
  //     setSelectedSubtotal(dataSubtotal);
  //     const total = dataSubtotal.reduce((accumulator, currentValue) => {
  //       return accumulator + parseFloat(currentValue);
  //     }, 0);
  //     setCurrentSelectedTotal(total);
  //   } else {
  //     // dataEorClaim.forEach((item, index) => {
  //     //   const eorIds = item.eorList.map((eor) => eor.id);
  //     //   const missingIds = eorIds.filter((id) => !selectedId.includes(id));
  //     //   console.log("eorIds", eorIds);
  //     //   console.log("missingIds", missingIds);
  //     //   console.log("missingIds.length", missingIds.length);

  //     //   if (missingIds.length > 0) {
  //     //     alert(`Pilih salah 1 row di array ke ${index}`);
  //     //   }
  //     // });

  //     const array = [];
  //     setSelectedId([]);
  //     setSelectedSubtotal([]);
  //     const total = array.reduce((accumulator, currentValue) => {
  //       return accumulator + parseFloat(currentValue);
  //     }, 0);
  //     setCurrentSelectedTotal(total);
  //   }
  // };

  const selectRowOptions =
    isAdminKca &&
    (statusHistory[0]?.statusCode === "100" ||
      statusHistory[0]?.statusCode === "103")
      ? {
          selectRow: {
            mode: "checkbox",
            selected: selectedId,
            onSelect: handleOnSelect,
            // onSelectAll: handleOnSelectAll,
            hideSelectAll: true,
          },
        }
      : {};

  const expandRowOptions = {
    expandRow: {
      renderer: (row) => (
        <div>
          <p className="mb-0 text-danger">Note: {row.note ?? "-"}</p>
        </div>
      ),
      expanded: dataEorClaim.flatMap((container) => {
        return container.eorList
          .filter((item) => item.note !== null)
          .map((item) => item.id);
      }),
      showExpandColumn: true,
      expandByColumnOnly: true,
    },
  };

  const handleRevisiEor = () => {
    toggleModal();
  };

  const countTotalSelectedEOR = (listEor, selectedId) => {
    let totalSubTtl = 0;

    listEor.forEach((item) => {
      if (selectedId.includes(item.id)) {
        totalSubTtl += parseFloat(item.subTtl);
      }
    });

    return totalSubTtl;
  };

  return loading ? (
    <div
      className="d-flex justify-content-center align-items-center"
      style={{ minHeight: "100vh" }}
    >
      <SpinnerPrimary />
    </div>
  ) : error ? (
    <div
      className="d-flex justify-content-center align-items-center text-danger"
      style={{ minHeight: "100vh" }}
    >
      <Badge bg="danger">{error?.message}</Badge>
    </div>
  ) : (
    <>
      {((!isAdminKca && statusHistory[0]?.statusCode === "101") ||
        (isAdminKca && statusHistory[0]?.statusCode === "140") ||
        statusHistory[0]?.statusCode === "121") && (
        <StatusBar
          id={detail?.id}
          statusCode={statusHistory[0]?.statusCode}
          isExpired={detail?.claim?.expiredDate}
          v2
        />
      )}
      <div className="container">
        {!isAdminKca && statusHistory[0]?.statusCode === "101" && (
          <>
            <br />
            <br />
          </>
        )}
        <BannerImage isAdmin={isAdminKca} />
        <Row>
          <Col xs={12} md={8}></Col>
          <Col xs={12} md={4} className="">
            <h5 className="pl-4 pr-4 mt-2 title-header-right">
              {isAdminKca ? "Detail Sertifikat" : "Update Status"}
            </h5>
          </Col>
          <Col xs={12} md={8}>
            <Card className="card-wrapper p-4 mb-4">
              <Card.Header className="card-main-header d-flex justify-content-between">
                <div>Detail Perbaikan</div>
                {isAdminKca && detail?.type === "total-loss" && (
                  <div className="head-container">
                    {detail?.claimContainerDetail[0]?.containerNo} (
                    {detail?.claimContainerDetail[0]?.sizeType})
                  </div>
                )}
              </Card.Header>
              <Card.Body className="main-card">
                <p className="title">Consignee</p>
                {loadingContainer ? (
                  <p className="placeholder-glow mb-2">
                    <span className="placeholder col-12"></span>
                  </p>
                ) : (
                  <div className="insured">
                    <h5 className="m-0">
                      {dataCargoContainer?.insuredReceiverName ?? "-"}
                    </h5>
                  </div>
                )}

                <Row className="mt-4">
                  <Col md={6}>
                    <p className="title">Gate In Depo</p>
                    <p className="value">
                      {dataEorClaim[0]?.gateInDate
                        ? moment(dataEorClaim[0]?.gateInDate).format(
                            "DD MMMM YYYY"
                          )
                        : "-"}
                    </p>

                    <p className="title">Nama PIC</p>
                    <p className="value">{detail?.claim?.picName ?? "-"}</p>
                  </Col>
                  <Col md={6}>
                    <p className="title">Email PIC</p>
                    <p className="value">{detail?.claim?.picEmail ?? "-"}</p>

                    <p className="title">No Handphone PIC</p>
                    <p className="value">
                      {detail?.claim?.picPhoneNumber ?? "-"}
                    </p>
                  </Col>
                </Row>
              </Card.Body>
            </Card>

            <Card
              className="card-wrapper p-4 mb-4"
              style={{ minHeight: "100px" }}
            >
              <Card.Header className="card-main-header">
                Detail Kronologi
              </Card.Header>
              <Card.Body className="main-card">
                {detail?.type === "total-loss" ? (
                  <>
                    <p className="title">Lokasi Kejadian</p>
                    <p className="value">{detail?.accidentLocation}</p>
                  </>
                ) : (
                  <>
                    <p className="title">Lokasi Depo</p>
                    <p className="value">{detail?.consigneeLocation}</p>
                  </>
                )}

                {isAdminKca && (
                  <>
                    <p className="title">Jenis Kerugian</p>
                    <p className="value">
                      {detail?.type === "partial-loss"
                        ? "Partial Loss"
                        : "Total Loss" ?? "-"}
                    </p>
                  </>
                )}

                {detail?.type !== "total-loss" && (
                  <>
                    <p className="title">Total Estimasi Perbaikan</p>
                    <p className="value">
                      {detail?.currencyCode}{" "}
                      {CurrencyFormat(detail?.claim?.claimAmount) ?? "-"}
                    </p>
                  </>
                )}

                {detail?.type !== "total-loss" && (
                  <>
                    <hr />
                    {loadingEor ? (
                      <div className="text-center">
                        Data Eor sedang dimuat. Mohon menunggu{" "}
                        {[1, 2, 3].map((item, index) => (
                          <Spinner
                            key={index}
                            animation="grow"
                            size="sm"
                            style={{
                              width: "4px",
                              height: "4px",
                              marginRight: "5px",
                            }}
                          />
                        ))}
                      </div>
                    ) : (
                      <>
                        <Row className="mb-2">
                          <Col md={5} className="d-flex align-items-center">
                            <div className="card-main-header mb-3">
                              Detail EOR
                            </div>
                          </Col>
                          <Col
                            md={7}
                            className="d-flex align-items-center justify-content-end"
                          >
                            <div>
                              {isAdminKca &&
                                (statusHistory[0]?.statusCode === "100" ||
                                  statusHistory[0]?.statusCode === "103") && (
                                  <div className="count">
                                    <strong>
                                      Total Terpilih : {detail?.currencyCode}{" "}
                                      {CurrencyFormat(
                                        currentSelectedTotal.toFixed(2)
                                      )}
                                    </strong>
                                  </div>
                                )}
                            </div>
                          </Col>
                        </Row>

                        <Row>
                          {dataEorClaim.map((item, index) => (
                            <>
                              <Col md={12} className="mb-2" key={index}>
                                <div className="count">
                                  <p className="mb-0">
                                    No Kontainer Declaration (Size Type) :{" "}
                                    <strong>
                                      {item.containerNo} ({item.sizeType ?? "-"}
                                      )
                                    </strong>
                                  </p>
                                  <p className="mb-0">
                                    No Kontainer EOR :{" "}
                                    <strong>{item.containerNoEor}</strong>
                                  </p>
                                  <p className="mb-0">
                                    {detail?.type === "total-loss"
                                      ? "Tanggal Kejadian "
                                      : "Gate In Depo "}
                                    :{" "}
                                    <strong>
                                      {moment(item.gateInDate).format(
                                        "DD MMMM YYYY"
                                      )}
                                    </strong>
                                  </p>
                                  <p className="mb-0">
                                    Sub Total :{" "}
                                    <strong>
                                      {statusHistory[0]?.statusCode === "100" ||
                                      statusHistory[0]?.statusCode === "103" ? (
                                        <>
                                          {CurrencyFormat(
                                            countTotalSelectedEOR(
                                              item.eorList,
                                              selectedId
                                            )
                                          )}
                                        </>
                                      ) : (
                                        <>{CurrencyFormat(item.amount)}</>
                                      )}
                                    </strong>
                                  </p>
                                  {/* {detail?.type !== "total-loss" &&
                                (statusHistory[0]?.statusCode !== "100" ||
                                  statusHistory[0]?.statusCode !== "103") && (
                                  <p className="mb-0">
                                    Sub Total Terpilih :{" "}
                                    <strong>
                                      {CurrencyFormat(item.amountSelected)}
                                    </strong>
                                  </p>
                                )} */}
                                </div>
                              </Col>
                              <Col md={12} className="tb-eor mt-2">
                                <Controller
                                  control={control}
                                  name="eorList"
                                  {...register("eorList")}
                                  render={({ field }) => (
                                    <BootstrapTable
                                      {...field}
                                      name={field.name}
                                      id={field.name}
                                      value={field.value}
                                      keyField="id"
                                      data={item.eorList}
                                      columns={columns}
                                      {...expandRowOptions}
                                      {...selectRowOptions}
                                      striped
                                      hover
                                      condensed
                                      onBlur={field.onBlur}
                                      getInputRef={field.ref}
                                      pagination={paginationFactory(options)}
                                    />
                                  )}
                                />
                                <hr />
                              </Col>
                            </>
                          ))}
                        </Row>
                      </>
                    )}
                  </>
                )}
              </Card.Body>
            </Card>

            {detail?.type !== "total-loss" && (
              <div className="accordion-eor">
                <Accordion className="mb-4">
                  <Accordion.Item eventKey="0">
                    <Accordion.Header>
                      Data Permintaan Revisi EOR
                    </Accordion.Header>
                    <Accordion.Body className="tb-eor">
                      {!isAdminKca &&
                        dataAdjustEor?.length > 0 &&
                        (statusHistory[0].statusCode === "100" ||
                          statusHistory[0]?.statusCode === "103") && (
                          <div className="text-end">
                            <Button
                              type="button"
                              color="primary"
                              size="small"
                              className="back-button mb-2"
                              onClick={handleRevisiEor}
                              style={{ minWidth: "195px" }}
                            >
                              Upload Revisi EOR
                            </Button>
                          </div>
                        )}
                      {dataAdjustEor?.length > 0 ? (
                        <Row>
                          {dataAdjustEor.map((item, index) => (
                            <>
                              <Col md={12} className="mb-2" key={index}>
                                <div className="count">
                                  <p className="mb-0">
                                    No Kontainer Declaration (Size Type) :{" "}
                                    <strong>
                                      {item.containerNo} ({item.sizeType ?? "-"}
                                      )
                                    </strong>
                                  </p>
                                  <p className="mb-0">
                                    No Kontainer EOR :{" "}
                                    <strong>{item.containerNoEor}</strong>
                                  </p>
                                  <p className="mb-0">
                                    {detail?.type === "total-loss"
                                      ? "Tanggal Kejadian"
                                      : "Gate In Depo"}
                                    :{" "}
                                    <strong>
                                      {moment(item.gateInDate).format(
                                        "DD MMMM YYYY"
                                      )}
                                    </strong>
                                  </p>
                                  <p className="mb-0">
                                    Sub Total :{" "}
                                    <strong>
                                      {detail?.currencyCode}{" "}
                                      {CurrencyFormat(
                                        item.adjust.reduce(
                                          (total, adjustItem) =>
                                            total +
                                            parseFloat(adjustItem.subTtl),
                                          0
                                        )
                                      )}
                                    </strong>
                                  </p>
                                  <p>
                                    Sub Total Yang Terpilih :{" "}
                                    <strong>
                                      {detail?.currencyCode}{" "}
                                      {CurrencyFormat(
                                        item.adjust.reduce(
                                          (total, adjustItem) => {
                                            if (
                                              adjustItem.note !==
                                              "Perbaikan belum memenuhi kriteria"
                                            ) {
                                              return (
                                                total +
                                                parseFloat(adjustItem.subTtl)
                                              );
                                            } else {
                                              return total;
                                            }
                                          },
                                          0
                                        )
                                      )}
                                    </strong>
                                  </p>
                                </div>
                              </Col>
                              <Col md={12} key={index}>
                                <TableContainer
                                  data={item?.adjust}
                                  cols={columns}
                                  eor
                                />
                                <hr />
                              </Col>
                            </>
                          ))}
                        </Row>
                      ) : (
                        <Row>
                          <Col xs={12} className="text-center">
                            <small>Tidak ada data revisi</small>
                          </Col>
                        </Row>
                      )}
                    </Accordion.Body>
                  </Accordion.Item>
                </Accordion>
              </div>
            )}

            <Card
              className="card-wrapper p-4 mb-4"
              style={{ minHeight: "100px" }}
            >
              <Card.Header className="card-main-header">
                Daftar Lampiran Kontainer
              </Card.Header>
              <Card.Body className="main-card">
                <DownloadDocClaim
                  data={detail?.claimContainerDocument}
                  noClaim={detail?.noClaim}
                  isAdminKca={isAdminKca}
                  containerDetails={detail?.claimContainerDetail}
                  isContainer
                />
              </Card.Body>
            </Card>

            <Card
              className="card-wrapper p-4 mb-4"
              style={{ minHeight: "100px" }}
            >
              <Card.Header className="card-main-header">
                Daftar Lampiran
              </Card.Header>
              <Card.Body className="main-card">
                <DownloadDocClaim
                  data={detail?.claimContainerDocument}
                  noClaim={detail?.noClaim}
                  isAdminKca={isAdminKca}
                />
              </Card.Body>
            </Card>

            {detail?.type !== "total-loss" && (
              <div className="accordion-eor">
                <DownloadHistoryDocEor id={detail?.id} headerEor={headerEor} />
              </div>
            )}
          </Col>

          <Col xs={12} md={4}>
            <DetailClaimCertificate
              dataContainer={dataCargoContainer}
              dataClaim={detail}
              statusHistory={statusHistory}
              isAdmin={isAdminKca}
              isLoadingContainer={loadingContainer}
            />

            {isAdminKca &&
            (statusHistory[0]?.statusCode === "100" ||
              statusHistory[0]?.statusCode === "103") ? (
              <>
                <ApproveRepairs
                  detail={detail}
                  claimAmount={currentSelectedTotal}
                  hasDataAdjustment={dataAdjustEor?.length > 0}
                  hasDataEorSelected={selectedId}
                  dataEorClaim={dataEorClaim}
                />
                <RequestRepairs
                  detail={detail}
                  dataEorClaim={dataEorClaim}
                  selectedId={selectedId}
                />
                <RejectRepairs detail={detail} />
                <ReviewProvis detail={detail} />
              </>
            ) : isAdminKca &&
              (statusHistory[0]?.statusCode === "102" ||
                statusHistory[0]?.statusCode === "110" ||
                statusHistory[0]?.statusCode === "111") ? (
              <>
                {/* {detail?.type === "total-loss" && (
                  <SubmitToInsurance detail={detail} />
                )} */}
                <Button
                  type="button"
                  color="primary"
                  size="small"
                  className="text-center action-button w-100 mt-3"
                  onClick={() =>
                    navigate(
                      `/claim/${window.btoa(idClaim)}/upload-dokumen-tambahan`
                    )
                  }
                >
                  Unggah Dokumen Tambahan
                </Button>
              </>
            ) : isAdminKca &&
              statusHistory[0]?.statusCode === "120" &&
              detail?.type === "total-loss" ? (
              <>
                <SubmitToInsurance detail={detail} />
              </>
            ) : isAdminKca && statusHistory[0]?.statusCode === "140" ? (
              <>
                <SingleDownloadFile files={docLod} />
                {docSkgr[0]?.fileId !== undefined && (
                  <SingleDownloadFile files={docSkgr} />
                )}
                {/* <RequestRepairs
                detail={detail}
                dataEorClaim={dataEorClaim}
                selectedId={selectedId}
              /> */}
              </>
            ) : isAdminKca && statusHistory[0]?.statusCode === "310" ? (
              <>
                <SingleDownloadFile files={docPayment} />
                {docSkgr[0]?.fileId !== undefined && (
                  <SingleDownloadFile files={docSkgr} />
                )}
                <SingleDownloadFile files={docLodSigned} />
              </>
            ) : (
              <></>
            )}
          </Col>
        </Row>

        <Row>
          <Col lg={12} className="d-flex justify-content-between">
            <Link
              to={`${isAdminKca ? "/perbaikan" : "/perbaikan-kontainer"}`}
              style={{ textDecoration: "none" }}
            >
              <Button
                type="button"
                color="primary"
                size="small"
                className="d-flex justify-content-between align-items-center back-button"
              >
                <FontAwesomeIcon icon={faArrowLeft}></FontAwesomeIcon>
                KEMBALI
              </Button>
            </Link>
          </Col>
        </Row>
      </div>

      <Modal show={modal} onHide={toggleModal} size={isAdminKca ? "md" : "lg"}>
        <Modal.Header closeButton>
          <Modal.Title style={{ fontSize: "15px" }}>
            {isAdminKca ? "Form Edit Data EOR" : "Upload Revisi EOR"}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {isAdminKca ? (
            <ModalFormEditEorV2
              data={modalDataEor}
              toggleModal={toggleModal}
              dataEorClaim={dataEorClaim}
            />
          ) : (
            <ModalFormRevisionEorV2
              toggleModal={toggleModal}
              claimId={detail?.id}
              refId={detail?.claim?.refId}
            />
          )}
        </Modal.Body>
      </Modal>
    </>
  );
}
