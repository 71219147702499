import React, { useState } from "react";
import { Button, Modal, Spinner } from "react-bootstrap";
import axiosPut from "../../helpers/axiosPut";
import Alert from "../Alert/Alert";
import styled from "styled-components";
import "./Action.css";

const StyledActionButton = styled.button`
  background: transparent;
  border: 1px solid #006881;
  min-width: 130px;
  padding: 8px 25px;
  font-weight: 800;
  font-size: 14px;
  border-radius: 20px;
  box-shadow: 0px 3px 4px 0px rgba(196, 196, 196, 1);
  color: #006881;
`;

export default function ReviewProvis({ detail, loadingRepair }) {
  const [loading, setLoading] = useState(false);
  const [show, setShow] = useState(false);
  const [error, setError] = useState(null);

  const [modal, setModal] = useState(false);
  const toggleModal = () => setModal(!modal);

  const onSubmit = () => {
    setLoading(true);
    const body = {
      noClaim: detail.noClaim,
      description: "Klaim ditinjau Provis",
      metadata: "",
    };
    axiosPut({
      url: `/claim-kca/review-provis`,
      data: body,
      callback: (res) => {
        setModal(false);
        setShow(true);
        setLoading(false);
        setTimeout(() => {
          window.location.reload();
        }, 3000);
      },
      errorCallback: (err) => {
        setLoading(false);
        setShow(true);
        setError(err);
      },
    });
  };
  return (
    <div>
      <StyledActionButton
        type="button"
        color="primary"
        size="small"
        className="text-center w-100"
        onClick={() => toggleModal()}
        disabled={loading || loadingRepair}
      >
        Tinjau Ke Provis
      </StyledActionButton>

      <Modal
        show={modal}
        onHide={toggleModal}
        size="md"
        backdropClassName="custom-backdrop"
      >
        <Modal.Header closeButton>
          <Modal.Title style={{ fontSize: "15px" }}>
            Konfirmasi Persetujuan Tinjau ke Provis
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="text-center">
            <label htmlFor="description" className="mb-2 mt-2">
              Apakah anda yakin ingin melanjutkan?
            </label>
            <div className="mt-2 mb-2">
              <Button variant="outline-secondary" onClick={toggleModal}>
                Tidak
              </Button>{" "}
              <Button variant="secondary" disabled={loading} onClick={onSubmit}>
                {loading ? <Spinner animation="border" size="sm" /> : "Ya!"}
              </Button>{" "}
            </div>
          </div>
        </Modal.Body>
      </Modal>
      <Alert
        message="Tinjau Ke Provis Berhasil!"
        show={show}
        error={error}
        handleClose={() => setShow(false)}
      />
    </div>
  );
}
