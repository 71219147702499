import React from "react";
import { Toast } from "react-bootstrap";

export default function Alert({ message, show, error, handleClose }) {
  return (
    <div>
      <Toast
        onClose={handleClose}
        show={show}
        delay={5000}
        autohide
        style={{ zIndex: "1050" }}
      >
        <Toast.Header
          style={{ backgroundColor: error ? "#c6171766" : "#17c69666" }}
        >
          <img src="holder.js/20x20?text=%20" className="rounded me-2" alt="" />
          <strong className="me-auto">{error ? "Error" : "Success"}</strong>
        </Toast.Header>
        <Toast.Body>{error ? error.message : message}</Toast.Body>
      </Toast>
    </div>
  );
}
