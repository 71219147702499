import { Container, Modal, Nav, Navbar, NavDropdown } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBars,
  faClose,
  faKey,
  faSortDown,
  faUser,
} from "@fortawesome/free-solid-svg-icons";

import { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import LogoKca from "../../logos/logo-kca.png";
import "./Navbar.css";
import { resetUser } from "../../store/user/actions";
import { resetClaim } from "../../store/claim/actions";
import { resetDeclaration } from "../../store/declaration/actions";

export default function NavbarPrimary({ isAdmin }) {
  const [show, setShow] = useState(false);
  const [scroll, setScroll] = useState(false);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const currentPath = window.location.pathname;
  const user = useSelector((state) => state.user.user);
  // change navbar color when scroll
  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 56) {
        setScroll(true);
      } else {
        setScroll(false);
      }
    };

    handleScroll();

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  // cek route
  const location = useLocation();
  const { pathname } = location;

  const handleLogout = () => {
    sessionStorage.removeItem("token");
    sessionStorage.removeItem("user");
    localStorage.removeItem("token");
    localStorage.removeItem("user");
    localStorage.removeItem("otherDoc");
    localStorage.removeItem("eorDoc");
    dispatch(resetUser());
    dispatch(resetClaim());
    dispatch(resetDeclaration());
    navigate("/");
  };

  return (
    <>
      <Navbar
        style={{ boxShadow: "none" }}
        collapseOnSelect
        expand="lg"
        fixed="top"
        className={
          scroll && pathname === "/"
            ? "scroll shadow-sm"
            : scroll && pathname !== "/"
            ? "second-scroll"
            : pathname !== "/"
            ? "second-scroll"
            : "default-navbar"
        }
      >
        <Container>
          <img alt="Logo" src={LogoKca} style={{ height: 55 }} />

          <button className="toggle-nav" onClick={handleShow}>
            <FontAwesomeIcon icon={faBars}></FontAwesomeIcon>
          </button>

          <Navbar.Collapse
            id="responsive-navbar-nav"
            className="justify-content-end"
          >
            {user && (
              <>
                <NavDropdown
                  id="nav-dropdown-dark-example"
                  title={
                    <>
                      <span className="active text-small">
                        <FontAwesomeIcon
                          icon={faUser}
                          style={{ marginRight: "8px" }}
                        ></FontAwesomeIcon>{" "}
                        {user?.userEmail}
                        <FontAwesomeIcon
                          icon={faSortDown}
                          style={{ marginLeft: "8px" }}
                        ></FontAwesomeIcon>{" "}
                      </span>
                    </>
                  }
                  menuVariant="light"
                  className="custom-dropdown"
                >
                  <NavDropdown.Item
                    href="/change-password"
                    className="dropdown-item"
                  >
                    <span>
                      <FontAwesomeIcon
                        icon={faKey}
                        style={{ marginRight: "8px" }}
                      ></FontAwesomeIcon>{" "}
                      Ganti Password
                    </span>
                  </NavDropdown.Item>
                </NavDropdown>
              </>
            )}
            <Nav.Link href="/transaksi" className="nav-link-text">
              <span
                className={`${currentPath.includes("transaksi") && "active"}`}
              >
                Transaksi
              </span>
            </Nav.Link>
            {!isAdmin ? (
              <Nav.Link href="/perbaikan-kontainer" className="nav-link-text">
                <span
                  className={`${
                    currentPath.includes("perbaikan-kontainer") && "active"
                  }`}
                >
                  Perbaikan
                </span>
              </Nav.Link>
            ) : (
              <>
                <Nav.Link href="/perbaikan" className="nav-link-text">
                  <span
                    className={`${
                      currentPath.includes("perbaikan") && "active"
                    }`}
                  >
                    Perbaikan
                  </span>
                </Nav.Link>

                <Nav.Link href="/claim" className="nav-link-text">
                  <span
                    className={`${currentPath.includes("claim") && "active"}`}
                  >
                    Klaim
                  </span>
                </Nav.Link>
              </>
            )}
            <div onClick={handleLogout} className="logout">
              <span>Keluar</span>
            </div>
          </Navbar.Collapse>
        </Container>
        <div className="navbar-modal">
          <Modal show={show} onHide={handleClose} className="custom-modal">
            <Modal.Header className="border-0 d-flex justify-content-between">
              <Link to="/">
                <img alt="Logo" src={LogoKca} style={{ height: 30 }} />
              </Link>

              <button className="toggle-nav" onClick={handleClose}>
                <FontAwesomeIcon icon={faClose}></FontAwesomeIcon>
              </button>
            </Modal.Header>
            <Modal.Body>
              {!isAdmin ? (
                <>
                  <Link
                    to="/perbaikan-kontainer"
                    className="text-white px-3 d-block mt-5"
                    style={{ fontSize: 30 }}
                  >
                    Perbaikan
                  </Link>
                </>
              ) : (
                <>
                  <Link
                    to="/perbaikan"
                    className="text-white px-3 d-block mt-5"
                    style={{ fontSize: 30 }}
                  >
                    Perbaikan
                  </Link>
                  <Link
                    to="/claim"
                    className="text-white px-3 d-block"
                    style={{ fontSize: 30 }}
                  >
                    Klaim
                  </Link>
                </>
              )}
              <div
                onClick={handleLogout}
                className="text-white px-3 d-block"
                style={{ fontSize: 30 }}
              >
                Keluar
              </div>
            </Modal.Body>
          </Modal>
        </div>
      </Navbar>
    </>
  );
}
