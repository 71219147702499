import moment from "moment";
import React, { Fragment, useState } from "react";
import { Pagination } from "../Pagination/Pagination";
import { Card, Table, Badge, Spinner } from "react-bootstrap";
import CurrencyFormat from "../Formating/CurrencyFormat";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAngleDoubleLeft,
  faChevronCircleRight,
  faFileArrowDown,
} from "@fortawesome/free-solid-svg-icons";
import "./DataTable.css";
import SpinnerPrimary from "../Spinner/Spinner";
import axiosGet from "../../helpers/axiosGet";
import ClaimLink from "../ClaimLink/ClaimLink";
import "moment/locale/id";
import StatusFormat from "../Formating/StatusFormat";
import { saveAs } from "file-saver";

const DataTable = ({
  headers,
  data,
  loading,
  error,
  page,
  totalPage,
  onPageChange,
  active,
  isAdminKca,
}) => {
  return (
    <>
      <Card className="w-100" style={{ border: "none", borderRadius: "10px" }}>
        {loading ? (
          <div style={{ marginTop: "200px", marginBottom: "200px" }}>
            <SpinnerPrimary />
          </div>
        ) : error ? (
          <Card.Body>
            <Card.Text color="danger" align="center">
              {error.message}
            </Card.Text>
          </Card.Body>
        ) : data.length ? (
          <Card.Body>
            <Fragment>
              <Table
                className="w-100 text-center table-striped"
                responsive="lg"
              >
                <thead>
                  <Headers headers={headers} active={active} />
                </thead>
                <tbody>
                  {data.map((x, index) => (
                    <Body
                      row={x}
                      active={active}
                      key={x.id}
                      isAdminKca={isAdminKca}
                      index={index}
                    />
                  ))}
                </tbody>
              </Table>
            </Fragment>
          </Card.Body>
        ) : (
          <Card.Body style={{ textAlign: "center" }}>
            <Card.Text>NO DATA</Card.Text>
          </Card.Body>
        )}

        <>
          {totalPage > 1 && (
            <div className="mt-5 d-flex justify-content-end">
              <Pagination
                pageCount={totalPage}
                onPageChange={onPageChange}
                onPageActive={page}
              />
            </div>
          )}
        </>
      </Card>
    </>
  );
};

function Headers({ headers, active }) {
  switch (active) {
    case "declaration":
      return (
        <tr>
          <SubHeaders headers={headers} />
          <th className="th-table">
            <div>Detail</div>
            <div>Action</div>
          </th>
        </tr>
      );
    case "excel":
      return (
        <tr>
          <SubHeaders headers={headers} />
          <th className="th-table">
            <div>Download</div>
          </th>
        </tr>
      );
    default:
      return (
        <tr>
          <SubHeaders headers={headers} />
          <th className="th-table">
            <div>Detail</div>
          </th>
        </tr>
      );
  }
}

function SubHeaders({ headers }) {
  return (
    <>
      {headers.map((header, index) => (
        <th key={index} className="th-table">
          {header.title.map((title) => (
            <div key={title}>{title}</div>
          ))}
        </th>
      ))}
    </>
  );
}

function Body({ row, active, isAdminKca, index }) {
  const navigate = useNavigate();
  const [expanded, setExpanded] = useState(false);
  const [loadingExpanded, setLoadingExpanded] = useState(false);
  const [error, setError] = useState(false);
  const [attachment, setAttachment] = useState(null);
  const [hasDamaged, setHasDamaged] = useState(false);
  const [loadingDownload, setLoadingDownload] = useState(null);

  const handleDetail = () => {
    const encode = window.btoa(row.id);
    const encodeUniqId = window.btoa(row.uniqId);

    const statusCodeAsInt = parseInt(row?.claim?.statusCode);

    switch (active) {
      case "declaration":
        return navigate(
          `/perbaikan-kontainer/detail-kontainer/${encodeUniqId}`
        );
      case "list-claim":
        const path = statusCodeAsInt > 101 ? "v2/claim" : "v2/perbaikan";
        navigate(`/${path}/detail/${encode}`);
        break;
      case "list-claim-depo":
        return navigate(`/v2/perbaikan-kontainer/detail/${encode}`);
      default:
        return navigate("/");
    }
  };

  const handleDownload = (uniqId, filename) => {
    setLoadingDownload(uniqId);
    axiosGet({
      url: `/container/excel/uniq-id/${uniqId}`,
      responseType: "blob",
      callback: (res) => {
        setLoadingDownload("");
      },
      errorCallback: (res) => {
        setLoadingDownload("");
        let file = new File([res], filename);
        saveAs(file);
      },
    });
  };

  const showContainer = () => {
    if (expanded) {
      setExpanded(false);
    } else {
      setExpanded(true);
      setLoadingExpanded(true);
      axiosGet({
        url: `/claim-cargo-container/attachment/uniq-id/${row.uniqId}`,
        callback: (res) => {
          setLoadingExpanded(false);
          setAttachment(res.data);
          const attachment = res.data;
          const isHasDamaged = attachment.some(
            (item) => item.status === "damaged" && item?.isClaimed === false
          );
          setHasDamaged(isHasDamaged);
        },
        errorCallback: (err) => {
          setLoadingExpanded(false);
          setError(err);
        },
      });
    }
  };

  const handleClaim = (uniqId) => {
    const encode = window.btoa(uniqId);
    if (isAdminKca) {
      navigate(`/v2/perbaikan/submission/${encode}`);
    } else {
      navigate(`/v2/perbaikan-kontainer/submission/${encode}`);
    }
  };

  const rowStyle = {
    cursor: active === "declaration" ? "pointer" : "auto",
  };

  return (
    <Fragment>
      <tr
        {...(active === "declaration" && {
          onClick: () => showContainer(row.uniqId),
        })}
        style={rowStyle}
      >
        <Cell
          active={active}
          row={row}
          expanded={expanded}
          index={index}
          isAdminKca={isAdminKca}
        />
        {active === "declaration" ? (
          <td className="td-table">
            <div className="dec-detail" onClick={handleDetail}>
              Detail
            </div>
            <>
              {expanded && !loadingExpanded && hasDamaged && (
                <div
                  className="action-detail-claim"
                  onClick={() => handleClaim(row.uniqId)}
                >
                  Ajukan Perbaikan
                </div>
              )}
            </>
          </td>
        ) : active === "excel" ? (
          <td className="td-table" style={{ verticalAlign: "middle" }}>
            <div
              className=""
              onClick={() => handleDownload(row?.uniqId, row?.filename)}
              style={{ cursor: "pointer" }}
            >
              {loadingDownload === row?.uniqId ? (
                <Spinner
                  animation="border"
                  style={{
                    width: "15px",
                    height: "15px",
                  }}
                />
              ) : (
                <FontAwesomeIcon
                  icon={faFileArrowDown}
                  style={{ color: "#006881", fontSize: "25px" }}
                ></FontAwesomeIcon>
              )}
            </div>
          </td>
        ) : (
          <td className="td-table" style={{ verticalAlign: "middle" }}>
            <div className="action-detail" onClick={handleDetail}>
              <FontAwesomeIcon
                icon={faChevronCircleRight}
                style={{ color: "#006881", fontSize: "20px" }}
              ></FontAwesomeIcon>
            </div>
          </td>
        )}
      </tr>

      {expanded && (
        <>
          {loadingExpanded ? (
            <tr>
              <ExpandedCell active="loading" isAdminKca={isAdminKca} />
            </tr>
          ) : error ? (
            <>
              <ExpandedCell
                active="error"
                message={error.message}
                isAdminKca={isAdminKca}
              />
            </>
          ) : (
            <>
              {attachment.length > 0 ? (
                <>
                  {attachment.map((x, index) => (
                    <tr key={index}>
                      <ExpandedCell
                        active={active}
                        row={x}
                        isAdminKca={isAdminKca}
                      />
                      <td
                        className="td-expanded-table"
                        style={{ background: "#106c85" }}
                      >
                        {!x.isClaimed ? (
                          <FontAwesomeIcon
                            icon={faAngleDoubleLeft}
                            style={{ color: "#fff", fontSize: "12px" }}
                          ></FontAwesomeIcon>
                        ) : (
                          <Badge bg="light" text="dark">
                            <ClaimLink
                              id={x?.claimContainerId}
                              value="Diajukan"
                            />
                          </Badge>
                        )}
                      </td>
                    </tr>
                  ))}
                </>
              ) : (
                <>
                  <tr>
                    <ExpandedCell active="not-found" isAdminKca={isAdminKca} />
                  </tr>
                </>
              )}
            </>
          )}
        </>
      )}
    </Fragment>
  );
}

function ExpandedCell({ active, row, message, isAdminKca }) {
  switch (active) {
    case "declaration":
      return (
        <Fragment>
          <td className="td-expanded-table">{row.containerNo ?? "-"}</td>
          <td className="td-expanded-table"></td>
          {isAdminKca && <td className="td-expanded-table"></td>}
          <td className="td-expanded-table">
            <div>
              {row.gateInDate
                ? moment(row.gateInDate).format("DD MMMM YYYY")
                : "-"}
            </div>
            <div>
              <small>
                <em>
                  (
                  {row.status === "damaged"
                    ? "Rusak"
                    : row.status === "available"
                    ? "Tersedia"
                    : "-"}
                  )
                </em>
              </small>
            </div>
          </td>
          <td className="td-expanded-table"></td>
        </Fragment>
      );
    case "loading":
      return (
        <Fragment>
          <td colSpan={isAdminKca ? 6 : 5} className="td-expanded-table">
            {[1, 2, 3].map((index) => (
              <Spinner
                animation="grow"
                className="loading-expanded"
                key={index}
              />
            ))}
          </td>
        </Fragment>
      );
    case "not-found":
      return (
        <Fragment>
          <td colSpan={isAdminKca ? 6 : 5} className="td-expanded-table">
            Data container tidak ditemukan
          </td>
        </Fragment>
      );
    case "error":
      return (
        <Fragment>
          <td colSpan={isAdminKca ? 6 : 5} className="td-expanded-table">
            {message}
          </td>
        </Fragment>
      );
    default:
      return "-";
  }
}

function Cell({ active, row, index, isAdminKca }) {
  const calculateDateDifference = (estDepartureDate) => {
    const today = moment().startOf("day");
    const departure = moment(estDepartureDate).startOf("day");
    const differenceInDays = departure.diff(today, "days");

    const adjustedDifference =
      differenceInDays >= 0 ? differenceInDays + 1 : differenceInDays - 1;

    return adjustedDifference >= 0
      ? `-${adjustedDifference}`
      : `+${Math.abs(adjustedDifference)}`;
  };

  switch (active) {
    case "declaration":
      return (
        <Fragment>
          <td className="td-table">
            <div>{row.blNo ?? "-"}</div>
          </td>
          <td className="td-table">
            <div>{row.insuredReceiverName}</div>
            <div>{row.vesselName ?? "-"}</div>
          </td>
          {isAdminKca && (
            <td className="td-table">
              <div>{row.companyName}</div>
            </td>
          )}
          <td className="td-table">
            <div>{moment(row.estDepartureDate).format("DD MMMM YYYY")}</div>
            <div>
              {row.expiredDate === null
                ? "-"
                : moment(row.expiredDate).format("DD MMMM YYYY")}
            </div>
            <div>
              {row?.attachment[0]?.gateInDate === null
                ? "-"
                : moment(row?.attachment[0]?.gateInDate).format("DD MMMM YYYY")}
            </div>
          </td>
          <td className="td-table">
            <div>{calculateDateDifference(row?.estDepartureDate)} Hari</div>
          </td>
        </Fragment>
      );
    case "list-claim":
      return (
        <Fragment>
          <td className="td-table">
            <div>{row?.claim?.noClaim ?? "-"}</div>
            <div>
              {row?.claim?.policyNumber ?? "-"}/{row?.certificateNo ?? "-"}
            </div>
            <div>{row?.blNo ?? "-"}</div>
          </td>
          <td className="td-table">
            <div>{row.insured ?? "-"}</div>
            <div>{row?.containerNo ?? "-"}</div>
          </td>
          <td className="td-table">
            <div>{row.insuredReceiverName ?? "-"}</div>
            <div>{row.vesselName ?? "-"}</div>
            <div>{row?.principle ?? "-"}</div>
          </td>
          <td className="td-table">
            <div>{row?.route}</div>
            <div>
              {row?.gateInDate
                ? moment(row?.gateInDate).format("DD MMMM YYYY")
                : "-"}
            </div>
          </td>
          <td className="td-table">
            <div>{CurrencyFormat(row?.claim?.netPremium)}</div>
            <div>
              {row?.type === "partial-loss" ? "Partial Loss" : "Total Loss"}
            </div>
            <div>{StatusFormat(row?.claim?.statusCode, "id")}</div>
          </td>
        </Fragment>
      );
    case "list-claim-depo":
      return (
        <Fragment>
          <td className="td-table">
            <div>{row?.blNo ?? "-"}</div>
          </td>
          <td className="td-table">
            <div>{row?.containerNo ?? "-"}</div>
          </td>
          <td className="td-table">
            <div>{row.insuredReceiverName ?? "-"}</div>
            <div>{row.vesselName ?? "-"}</div>
            <div>{row?.principle ?? "-"}</div>
          </td>
          <td className="td-table">
            <div>{row?.route}</div>
            <div>
              {row?.gateInDate
                ? moment(row?.gateInDate).format("DD MMMM YYYY")
                : "-"}
            </div>
          </td>
          <td className="td-table">
            <div>{StatusFormat(row?.claim?.statusCode, "id")}</div>
          </td>
        </Fragment>
      );
    case "excel":
      return (
        <Fragment>
          <td className="td-table">
            <div>{index + 1 ?? "-"}</div>
          </td>
          <td className="td-table">
            <div>{row.filename}</div>
          </td>
          <td className="td-table">
            <div>{moment(row.createdDate).format("DD MMMM YYYY")}</div>
          </td>
          <td className="td-table">
            <div>{row.userEmail}</div>
          </td>
        </Fragment>
      );
    default:
      return "-";
  }
}

export default DataTable;
