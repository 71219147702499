import React, { useState } from "react";
import { Spinner, Modal, Form } from "react-bootstrap";
import axiosPut from "../../helpers/axiosPut";
import Alert from "../Alert/Alert";
import styled from "styled-components";
import { useForm } from "react-hook-form";
import constants from "../../constants/constants";

const StyledActionButton = styled.button`
  background: #f51926;
  border: none;
  min-width: 130px;
  padding: 8px 25px;
  font-weight: 800;
  font-size: 14px;
  border-radius: 20px;
  box-shadow: 0px 3px 4px 0px rgba(196, 196, 196, 1);
  color: #fff;

  &:disabled {
    background: #eabbbb;
  }
`;

const StyledButtonSubmit = styled.button`
  background: linear-gradient(180deg, #006881, rgba(67, 186, 148, 0) 136.98%);
  border: none;
  border-radius: 20px;
  font-size: 14px;
  padding: 3px 20px;
  color: #fff;

  &:disabled {
    background: #757575;
  }
`;

export default function RejectRepairs({ detail, loadingRepair }) {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({});

  const [modal, setModal] = useState(false);
  const toggleModal = () => setModal(!modal);

  const [loading, setLoading] = useState(false);
  const [show, setShow] = useState(false);
  const [error, setError] = useState(null);

  const [selectValue, setSelectValue] = useState("");

  const handleReject = (data) => {
    setLoading(true);
    const body = {
      noClaim: detail.noClaim,
      metadata: "",
      description:
        selectValue === "Lainnya" ? data.otherDescription : data.description,
    };
    axiosPut({
      url: `/claim-kca/claim-rejected`,
      data: body,
      callback: (res) => {
        toggleModal();
        setShow(true);
        setLoading(false);
        setTimeout(() => {
          window.location.reload();
        }, 3000);
      },
      errorCallback: (err) => {
        setLoading(false);
        setShow(true);
        setError(err);
      },
    });
  };

  const handleSelect = (e) => {
    setSelectValue(e.target.value);
  };

  return (
    <div>
      <StyledActionButton
        type="button"
        color="primary"
        size="small"
        className="text-center w-100 mb-3"
        onClick={() => toggleModal()}
        disabled={loading || loadingRepair}
      >
        Perbaikan Dibatalkan
      </StyledActionButton>

      <Modal
        show={modal}
        onHide={toggleModal}
        size="md"
        backdropClassName="custom-backdrop"
      >
        <Modal.Header closeButton>
          <Modal.Title style={{ fontSize: "15px" }}>
            Perbaikan Dibatalkan
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form onSubmit={handleSubmit(handleReject)}>
            <div className="mb-4">
              <label htmlFor="description" className="mb-2 mt-2">
                Alasan Pembatalan Perbaikan{" "}
                <span className="text-danger">*</span>{" "}
              </label>
              <Form.Select
                name="description"
                {...register(`description`, {
                  required: "Pilih alasan pembatalan terlebih dahulu!",
                })}
                onChange={handleSelect}
              >
                <option value="" disabled selected>
                  -- Pilih alasan pembatalan --
                </option>
                {constants.REJECT_REASON.map((item, index) => (
                  <option value={item.value} key={index}>
                    {item.label}
                  </option>
                ))}
              </Form.Select>
              {errors["description"] && (
                <p className="mb-0 mt-1 text-danger small">
                  *{errors["description"].message}
                </p>
              )}

              {selectValue === "Lainnya" && (
                <Form.Control
                  as="textarea"
                  rows={3}
                  className="mt-3"
                  {...register(`otherDescription`, {
                    required: "Deskripsi harus diisi!",
                  })}
                  style={{
                    width: "100%",
                    background: "transparent",
                    border: "1px solid #dee2e6",
                    borderRadius: "5px",
                  }}
                />
              )}

              {errors["otherDescription"] && (
                <p className="mb-0 mt-1 text-danger small">
                  *{errors["otherDescription"].message}
                </p>
              )}
            </div>

            <div className="text-end">
              <StyledButtonSubmit type="submit" disabled={loading}>
                {loading ? <Spinner animation="border" size="sm" /> : "Submit"}
              </StyledButtonSubmit>
            </div>
          </form>
        </Modal.Body>
      </Modal>
      <Alert
        message="Pembatalan Perbaikan Berhasil!"
        show={show}
        error={error}
        handleClose={() => setShow(false)}
      />
    </div>
  );
}
