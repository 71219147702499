export function FilenameFormat(value) {
  switch (value) {
    case "DOC_EOR":
      return "Dokumen EOR";
    case "DOC_EIR":
      return "Dokumen EIR";
    case "DOC_REPAIR_DOCUMENTATION":
      return "Dokumentasi Perbaikan";
    case "DOC_INVOICE":
      return "Invoice";
    case "DOC_LOD":
      return "Dokumen LOD";
    case "DOC_SKGR":
      return "Dokumen SKGR";
    case "DOC_CLAIM":
      return "Formulir Klaim";
    case "DOC_CHRONOLOGY":
      return "File Laporan Kronologis Kejadian";
    case "DOC_DELIVERY_RECEIPT":
      return "Surat Jalan";
    case "DOC_PARTIAL_LOSS":
      return "Invoice Perbaikan kerusakan sebagian";
    case "DOC_TOTAL_LOSS":
      return "Invoice Perbaikan kerusakan menyeluruh";
    case "DOC_DOCUMENTATION":
      return "Foto - foto dokumentasi";
    case "DOC_BEFORE_ACCIDENT":
      return "Foto - foto dokumentasi sebelum pengiriman";
    case "DOC_POLICE_REPORT":
      return "Dokumen Laporan Kepolisian";
    case "DOC_ARRIVAL_NOTICE":
      return "Dokumen Arrival Notice";
    case "DOC_PAYMENT":
      return "Bukti Bayar";
    case "DOC_LOD_SIGNED":
      return "Dokumen LOD yang sudah ditandatangani";
    case "DOC_OTHER":
      return "Dokumen Lainnya";
    default:
      return value;
  }
}
