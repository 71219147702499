import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import axiosGet from "../../helpers/axiosGet";
import { useNavigate, useParams } from "react-router-dom";
import constants from "../../constants/constants";
import SpinnerPrimary from "../../components/Spinner/Spinner";
import BannerImage from "../../components/Banner/BannerImage";
import { Button, Card, Col, Form, Row, Spinner } from "react-bootstrap";
import styled from "styled-components";
import { useForm } from "react-hook-form";
import uploadDocClaim from "../../helpers/uploadDocClaim";
import DetailClaimCertificate from "../../components/DetailCertificate/DetailClaimCertificate";
import Alert from "../../components/Alert/Alert";

const StyledInput = styled.div`
  /* background: #ffffff; */
  background: ${({ isExist }) => (isExist ? "#f5f5f5" : "#ffffff")};
  border: 1px solid #bcbcbc;
  border-radius: 5px;
  padding: 15px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 1rem;

  span {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  div {
    display: flex;
    align-items: center;
    gap: 0.5rem;
  }
`;

const StyledButton = styled.button`
  padding: 2px 20px;
  font-size: 16px;
  font-weight: 700;
  border-radius: 30px;
  color: white;
  min-height: 1.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  background: ${(props) =>
    props.isDelete ? "#F93154" : props.isDownload ? "#ADDFFF" : "#27AE60"};
  border: none;
  pointer-events: ${(props) => props.isUpload && "none"};
  min-width: ${(props) => (props.isDelete ? "40px" : "90px")};

  :disabled {
    background: #757575;
  }

  i {
    pointer-events: none;
  }
`;

export default function ClaimUploadDocSubmission() {
  const params = useParams();
  const decode = window.atob(params.id);
  const navigate = useNavigate();
  const isAdminKca = useSelector((state) => state.user.isUserAdminKCa);
  const [detail, setDetail] = useState();
  const [loading, setLoading] = useState(false);
  const [loadingSubmit, setLoadingSubmit] = useState(false);
  const [error, setErr] = useState(null);
  const [showAlert, setShowAlert] = useState(null);

  useEffect(() => {
    setLoading(true);
    axiosGet({
      url: `/claim-kca/id/${decode}`,
      callback: (res) => {
        setDetail(res.data);
        setLoading(false);
      },
      errorCallback: (err) => {
        if (
          err.statusCode &&
          (err.statusCode === constants.HTTP_STATUS_UNAUTHORIZED ||
            err.statusCode === constants.HTTP_STATUS_FAILED ||
            err.statusCode === constants.HTTP_STATUS_ERROR ||
            err.statusCode === constants.HTTP_STATUS_SERVER_ERROR)
        ) {
          navigate("/");
        }
        setLoading(false);
        setErr(err);
      },
    });
    // eslint-disable-next-line
  }, [decode]);

  const {
    handleSubmit,
    register,
    formState: { errors },
    setError,
  } = useForm({});

  const [eorDocValues, setEorDocValues] = useState(
    Array(detail?.claimContainerDetail?.length).fill(null)
  );
  const [otherDocValue, setOtherDocValue] = useState(null);
  const [eorDocFilenames, setEorDocFilenames] = useState(
    Array(detail?.claimContainerDetail?.length).fill("")
  );
  const [otherDocFilename, setOtherDocFilename] = useState("");

  const handleFileEor = (event, index) => {
    const file = event.target.files[0];
    if (file.size <= 5 * 1024 * 1024) {
      // Check ukuran file <= 5MB
      const updatedEorDocValues = [...eorDocValues];
      updatedEorDocValues[index] = file;
      setEorDocValues(updatedEorDocValues);

      // Simpan nama file ke dalam state jika file terpilih
      const updatedFilenames = [...eorDocFilenames];
      updatedFilenames[index] = file.name;
      setEorDocFilenames(updatedFilenames);
    } else {
      setError(`eorDoc-${index}`, {
        type: "manual",
        message: "Ukuran file tidak boleh melebihi 5MB",
      });
    }
  };

  const handleOtherFile = (event) => {
    const file = event.target.files[0];
    if (file.size <= 5 * 1024 * 1024) {
      // Check ukuran file <= 5MB
      setOtherDocValue(file);
      setOtherDocFilename(file.name);
    } else {
      setError("otherDoc", {
        type: "manual",
        message: "Ukuran file tidak boleh melebihi 5MB",
      });
    }
  };

  const onSubmit = async (data) => {
    setLoadingSubmit(true);
    try {
      const uploadPromises = [];

      eorDocValues.forEach((eorDocValue, index) => {
        if (eorDocValue) {
          const claimContainerDetailId = detail.claimContainerDetail[index].id;
          const promise = uploadDocClaim(
            eorDocValue,
            detail.cargoContainer?.refId,
            detail?.id,
            "DOC_EOR",
            eorDocFilenames[index],
            claimContainerDetailId
          );
          uploadPromises.push(promise);
        }
      });

      // Upload file otherDoc jika ada
      if (otherDocValue) {
        const promise = uploadDocClaim(
          otherDocValue,
          detail.cargoContainer?.refId,
          detail?.id,
          "DOC_DOCUMENTATION",
          otherDocFilename
        );
        uploadPromises.push(promise);
      }

      await Promise.all(uploadPromises);
      setShowAlert(true);
      setTimeout(() => {
        navigate(
          `${isAdminKca ? "/perbaikan" : "/perbaikan-kontainer"}/detail/${
            params.id
          }`
        );
      }, 5000);
    } catch (error) {
      console.error("Terjadi kesalahan:", error);
    }
  };

  return loading ? (
    <div
      className="d-flex justify-content-center align-items-center"
      style={{ minHeight: "100vh" }}
    >
      <SpinnerPrimary />
    </div>
  ) : (
    <div className="container">
      <BannerImage isAdmin={isAdminKca} />
      <form onSubmit={handleSubmit(onSubmit)}>
        <Row>
          <Col xs={12} md={isAdminKca ? 8 : 12}>
            <Card className="card-wrapper p-4 mb-4">
              <Card.Header className="card-main-header">
                Detail Perbaikan
              </Card.Header>
              <Card.Body className="main-card">
                {detail?.claimContainerDetail?.map((item, index) => (
                  <div key={index}>
                    <label htmlFor={`eorDoc-${index}`} className="mb-2 mt-2">
                      Upload EOR ({item.containerNo}){" "}
                      <span className="text-danger">*</span>{" "}
                    </label>
                    <input
                      type="file"
                      className="form-control form-control-lg"
                      id={`eorDoc-${index}`}
                      name={`eorDoc-${index}`}
                      {...register(`eorDoc-${index}`, {
                        required: "EOR wajib diupload!",
                      })}
                      onChange={(event) => handleFileEor(event, index)}
                      style={{ display: "none" }}
                      accept={".xlsx"}
                    />

                    <StyledInput>
                      <span>
                        {eorDocValues[index]
                          ? eorDocValues[index].name
                          : "Pilih file"}
                      </span>
                      <div>
                        <label htmlFor={`eorDoc-${index}`}>
                          <div style={{ cursor: "pointer" }}>
                            <StyledButton type="button" isUpload>
                              Lampirkan
                            </StyledButton>
                          </div>
                        </label>
                      </div>
                    </StyledInput>

                    {errors[`eorDoc-${index}`] && (
                      <p className="mb-0 mt-1 text-danger small">
                        {errors[`eorDoc-${index}`].message}
                      </p>
                    )}
                  </div>
                ))}
                <>
                  <Form.Group>
                    <Form.Label htmlFor="otherDoc" className="mb-2 mt-2">
                      Foto - foto dokumentasi dan atau rekaman video (kontainer
                      yang rusak) <span className="text-danger">*</span>{" "}
                    </Form.Label>
                    <Form.Control
                      type="file"
                      className="form-control form-control-lg"
                      id="otherDoc"
                      name="otherDoc"
                      {...register("otherDoc", {
                        required: "Foto dokumentasi wajib diupload!",
                      })}
                      onChange={handleOtherFile}
                      hidden
                      accept={".doc, .docx, .pdf, .jpg, .jpeg, .png"}
                    />

                    <StyledInput>
                      <span>
                        {otherDocValue ? otherDocValue.name : "Pilih file"}
                      </span>
                      <div>
                        <Form.Label htmlFor="otherDoc">
                          <div style={{ cursor: "pointer" }}>
                            <StyledButton type="button" isUpload>
                              Lampirkan
                            </StyledButton>
                          </div>
                        </Form.Label>
                      </div>
                    </StyledInput>
                    {errors["otherDoc"] && (
                      <p className="mb-0 mt-1 text-danger small">
                        *{errors["otherDoc"].message}
                      </p>
                    )}
                  </Form.Group>
                </>
              </Card.Body>
            </Card>
          </Col>
          {isAdminKca && (
            <Col xs={12} lg={4} className="detail-cert-mb">
              <DetailClaimCertificate
                data={detail}
                isAdmin={isAdminKca}
                docSubmission
              />
            </Col>
          )}
        </Row>
        <Row>
          <Col md={12} className="text-end mt-3">
            <Button className="back-button" type="submit">
              {loadingSubmit ? (
                <Spinner animation="border" size="sm" />
              ) : (
                "Submit"
              )}
            </Button>
          </Col>
        </Row>
      </form>

      <Alert
        message={`Submit Berhasil!`}
        show={showAlert}
        error={error}
        handleClose={() => setShowAlert(false)}
      />
    </div>
  );
}
