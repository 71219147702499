import { faArrowLeft, faArrowRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import moment from "moment";
import "moment/locale/id";
import React, { useEffect, useState } from "react";
import { Button, Card, Col, Row } from "react-bootstrap";
import { useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import SpinnerPrimary from "../../components/Spinner/Spinner";
import axiosGet from "../../helpers/axiosGet";
import axiosPost from "../../helpers/axiosPost";
import "./Claim.css";
import Alert from "../../components/Alert/Alert";
import BannerImage from "../../components/Banner/BannerImage";
import uploadDocClaim from "../../helpers/uploadDocClaim";

export default function ClaimSummary() {
  const dataClaim = useSelector((state) => state.claim.dataClaimSubmission);
  const isAdminKca = useSelector((state) => state.user.isUserAdminKCa);
  const claimDoc = localStorage.getItem("claimDoc");
  const reportDoc = localStorage.getItem("reportDoc");
  const [loading, setLoading] = useState(false);
  const [err, setErr] = useState(null);
  const [show, setShow] = useState(false); //toast

  const navigate = useNavigate();
  const [detail, setDetail] = useState();

  const encode = window.btoa(dataClaim.declarationUniqId);

  useEffect(() => {
    setLoading(true);
    setErr(null);
    axiosGet({
      url: `/claim-cargo-container/uniq-id/${dataClaim.declarationUniqId}`,
      callback: (res) => {
        setDetail(res.data);
        setLoading(false);
      },
      errorCallback: (err) => {
        setLoading(false);
        setErr(err);
      },
    });
  }, [dataClaim.declarationUniqId]);

  async function processDocs(docs, refId, claimContainerId) {
    // Map over the docs array to create an array of promises
    console.log("docs process", docs);
    const uploadPromises = docs.map(
      (doc) =>
        doc.files
          ? uploadDocClaim(
              doc.files,
              refId,
              claimContainerId,
              doc.type,
              doc.filename
            )
          : Promise.resolve() // Skip null/undefined file objects
    );

    // Use Promise.all to wait for all promises to resolve
    await Promise.all(uploadPromises);
  }

  const handleSubmitButton = () => {
    setLoading(true);

    const docs = [
      {
        files: claimDoc,
        type: "DOC_CLAIM",
        filename: dataClaim.filenameClaim,
      },
    ];

    if (reportDoc !== null) {
      docs.push({
        files: reportDoc,
        type: "DOC_POLICE_REPORT",
        filename: dataClaim.filenameReport,
      });
    }

    console.log("docs", docs);

    const body = {
      // accidentType: dataClaim.accidentType,
      accidentDate: dataClaim.accidentDate ?? null,
      accidentLocation: dataClaim.accidentLocation ?? null,
      nextProgressUserType: "-",
      nextProgressDeadlineDate: dataClaim.nextProgressDeadlineDate,
      insuranceCode: dataClaim.insuranceCode,
      companyName: dataClaim.companyName,
      picName: dataClaim.picName,
      picPhoneNumber: dataClaim.picPhoneNumber,
      picEmail: dataClaim.picEmail,
      containerNo: dataClaim?.containerNo,
      policyNumber: dataClaim.policyNumber,
      conveyance: dataClaim.conveyance,
      certificateNo: dataClaim.certificateNo,
      publishDate: dataClaim.publishDate,
      declarationUniqId: dataClaim.declarationUniqId,
      declarationAttachmentId: dataClaim.containerId,
      details: [{ id: dataClaim.containerId }],
      consigneeLocation: dataClaim.consigneeLocation ?? null,
    };
    axiosPost({
      url: `/claim-kca/save`,
      data: body,
      callback: async (res) => {
        const claimContainerId = res.data.claimContainer.id;
        const encode = window.btoa(claimContainerId);
        const refId = detail?.refId;
        setShow(true);
        setLoading(false);

        await processDocs(docs, refId, claimContainerId)
          .then(() => {
            console.log("All documents have been uploaded successfully.");
          })
          .catch((error) => {
            console.error(
              "An error occurred while uploading documents:",
              error
            );
          });
        navigate(`/v2/claim/detail/${encode}`);
      },
      errorCallback: (err) => {
        setErr(err);
        setLoading(false);
        setShow(true);
        console.log(err);
      },
    });
  };

  return loading ? (
    <div
      className="d-flex justify-content-center align-items-center"
      style={{ minHeight: "100vh" }}
    >
      <SpinnerPrimary />
    </div>
  ) : (
    <div className="container">
      <BannerImage isAdmin={isAdminKca} />
      <Row>
        <Col xs lg={8}>
          <div className="d-flex justify-content-end">
            <div className="pl-5 pr-5">
              <span className="title-header-left">
                No Container (Size Type)
              </span>
              <p>
                {dataClaim.containerNo} ({dataClaim.sizeType})
              </p>
            </div>
          </div>
        </Col>
        <Col xs lg={4}></Col>
      </Row>
      <Row>
        <Col xs={12} md={12}>
          <Card className="card-wrapper p-4 mb-4">
            <Card.Header className="card-main-header">
              Detail Perbaikan
            </Card.Header>
            <Card.Body className="main-card">
              <p className="title">Penerima</p>
              <div className="insured">
                <h5 className="m-0">{detail?.consignee ?? "-"}</h5>
              </div>

              <Row className="mt-4">
                <Col md={6}>
                  <p className="title">Tanggal Pengajuan</p>
                  <p className="value">
                    {moment(dataClaim?.publishDate).format("DD MMM YYYY") ??
                      "-"}
                  </p>
                  <p className="title">Nama PIC</p>
                  <p className="value">{dataClaim?.picName ?? "-"}</p>

                  <p className="title">Conveyance</p>
                  <p className="value">{dataClaim?.conveyance ?? "-"}</p>
                </Col>
                <Col md={6}>
                  <p className="title">Email PIC</p>
                  <p className="value">{dataClaim?.picEmail ?? "-"}</p>

                  <p className="title">No Handphone PIC</p>
                  <p className="value">{dataClaim?.picPhoneNumber ?? "-"}</p>
                </Col>
              </Row>
            </Card.Body>
          </Card>

          <Card
            className="card-wrapper p-4 mb-4"
            style={{ minHeight: "100px" }}
          >
            <Card.Header className="card-main-header">
              Detail Kronologi
            </Card.Header>
            <Card.Body className="main-card">
              <p className="title">Lokasi Kejadian</p>
              <p className="value">{dataClaim?.accidentLocation ?? "-"}</p>
            </Card.Body>
          </Card>

          <Card
            className="card-wrapper p-4 mb-4"
            style={{ minHeight: "100px" }}
          >
            <Card.Header className="card-main-header">
              Daftar Lampiran
            </Card.Header>
            <Card.Body className="main-card">
              <div className="d-flex justify-content-between">
                <div>Lampiran Formulir Klaim</div>
                <div>
                  <a
                    className="download-doc"
                    href={claimDoc}
                    download={"Formulir-Klaim"}
                  >
                    Download
                  </a>
                </div>
              </div>
              {reportDoc !== null && (
                <>
                  <hr />
                  <div className="d-flex justify-content-between">
                    <div>Lampiran Laporan Kepolisian kepolisian</div>
                    <div>
                      <a
                        className="download-doc"
                        href={reportDoc}
                        download={"Dokumen-lainnya"}
                      >
                        Download
                      </a>
                    </div>
                  </div>
                  <hr />
                </>
              )}
            </Card.Body>
          </Card>
        </Col>
      </Row>

      <Row>
        <Col lg={12} className="d-flex justify-content-between">
          <Link
            to={`/perbaikan-kontainer/submission/${encode}`}
            style={{ textDecoration: "none" }}
          >
            <Button
              type="button"
              color="primary"
              size="small"
              className="d-flex justify-content-between align-items-center back-button"
            >
              <FontAwesomeIcon icon={faArrowLeft}></FontAwesomeIcon>
              KEMBALI
            </Button>
          </Link>

          <Button
            onClick={handleSubmitButton}
            type="button"
            color="primary"
            size="small"
            className="d-flex justify-content-between align-items-center back-button"
          >
            SUBMIT
            <FontAwesomeIcon icon={faArrowRight}></FontAwesomeIcon>
          </Button>
        </Col>
      </Row>

      <Alert
        message="Submit Berhasil. Mohon menunggu..."
        show={show}
        error={err}
        handleClose={() => setShow(false)}
      />
    </div>
  );
}
