import status from "@provis/provis-common-be-module/dist/constants/claim/status";

const StatusClaim = Object.values(status)
  .filter((item) => typeof item === "object" && item.code)
  .map((item) => ({
    label: item.desc.id,
    value: item.code,
  }));

export default StatusClaim;
