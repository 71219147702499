const StatusList = [
  {
    code: "100",
    color: "#4E9F3D",
    text: "Selamat Klaim Anda Berhasil Terkirim Pada",
  },
  {
    code: "101",
    color: "#3A9288",
    text: "Selamat Upload Perbaikan Telah disetujui, Upload Dokumen After Repair Sebelum : ",
  },
  {
    code: "121",
    color: "#3A9288",
    text: "Dokumen Anda sedang ditinjau oleh Provis",
  },
  {
    code: "140",
    color: "#3A9288",
    text: "Selamat klaim kamu telah disetujui, segera upload dokumen LOD yang telah ditandatangani untuk memproses pembayaran klaim :  ",
  },
  { code: "400", color: "#FF1E56", text: "Maaf Klaim Belum Memenuhi Kriteria" },
];

export default StatusList;
