import { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { Container } from "react-bootstrap";
import SpinnerPrimary from "../../components/Spinner/Spinner";
import constants from "../../constants/constants";
import _ from "lodash";
import axiosGet from "../../helpers/axiosGet";
import { useDispatch } from "react-redux";
import {
  resetUser,
  setDataUser,
  setIsUserAdminKca,
} from "../../store/user/actions";
import { resetClaim } from "../../store/claim/actions";
import { resetDeclaration } from "../../store/declaration/actions";
import Alert from "../../components/Alert/Alert";

const Home = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();

  //alert
  const [show, setShow] = useState(false);
  const [error, setError] = useState(false);

  // get queries
  const token = searchParams.get("token");
  const user = searchParams.get("user");

  const localToken = localStorage.getItem("token");
  const localUser = localStorage.getItem("user");
  const val = searchParams.get("val");
  const decode = window.atob(val);
  const decodeTokenResult = decode.split("%20").join(" ");

  const [conversationsLoaded, setConversationsLoaded] = useState(false);

  useEffect(() => {
    const getAllConversations = async () => {
      sessionStorage.removeItem("token");
      sessionStorage.removeItem("user");
      localStorage.removeItem("token");
      localStorage.removeItem("user");
      localStorage.removeItem("otherDoc");
      localStorage.removeItem("eorDoc");
      localStorage.removeItem("claimDoc");
      localStorage.removeItem("reportDoc");
      dispatch(resetUser());
      dispatch(resetClaim());
      dispatch(resetDeclaration());
      setConversationsLoaded(true);
    };

    getAllConversations();
  }, [dispatch]);

  useEffect(() => {
    if (conversationsLoaded === true) {
      const getInvConversations = async () => {
        if (val) {
          sessionStorage.setItem("token", decodeTokenResult);
          sessionStorage.setItem("user", user);
          localStorage.setItem("token", decodeTokenResult);
          localStorage.setItem("user", user);
          axiosGet({
            url: `/claim-kca/auth/profile`,
            callback: (res) => {
              const auth = res?.data?.authorization;
              dispatch(setDataUser(res.data));
              if (auth.includes(constants.ADMIN_KCA)) {
                dispatch(setIsUserAdminKca(true));
                setTimeout(() => {
                  navigate("/perbaikan");
                }, 2000);
              } else if (auth.includes(constants.USER_KCA)) {
                dispatch(setIsUserAdminKca(false));
                setTimeout(() => {
                  navigate("/perbaikan-kontainer");
                }, 2000);
              } else {
                setShow(true);
                setError({
                  message: "Akun Anda Tidak Memiliki Akses!",
                });
                setTimeout(() => {
                  const fullUrl =
                    window.location.origin + window.location.pathname;
                  const redirectUrl = `${constants.SSO_URL}?redirectUrl=${fullUrl}&type=kca`;
                  window.location.replace(redirectUrl);
                }, 3000);
              }
            },
            errorCallback: (err) => {
              setError(err);
              setShow(true);
              if (
                err.statusCode &&
                (err.statusCode === constants.HTTP_STATUS_UNAUTHORIZED ||
                  err.statusCode === constants.HTTP_STATUS_FAILED ||
                  err.statusCode === constants.HTTP_STATUS_ERROR ||
                  err.statusCode === constants.HTTP_STATUS_SERVER_ERROR)
              ) {
                navigate("/");
              }
            },
          });
        } else if (
          (!_.isEmpty(localToken) && !_.isEmpty(localUser)) ||
          (!_.isEmpty(token) && !_.isEmpty(user))
        ) {
          sessionStorage.setItem("token", token);
          sessionStorage.setItem("user", user);
          localStorage.setItem("token", token);
          localStorage.setItem("user", user);

          setTimeout(() => {
            navigate("/perbaikan");
          }, 2000);
        } else {
          const fullUrl = window.location.origin + window.location.pathname;
          const redirectUrl = `${constants.SSO_URL}?redirectUrl=${fullUrl}&type=kca`;
          window.location.replace(redirectUrl);
        }
      };
      getInvConversations();
    }
  }, [
    navigate,
    dispatch,
    token,
    user,
    localToken,
    localUser,
    val,
    decodeTokenResult,
    conversationsLoaded,
  ]);

  return (
    <Container>
      <div
        className="mt-5 mb-5 pt-5 pb-5 d-flex align-items-center justify-content-center"
        style={{ height: "90vh" }}
      >
        <SpinnerPrimary />
      </div>
      <Alert error={error} show={show} handleClose={() => setShow(false)} />
    </Container>
  );
};

export default Home;
