import moment from "moment";
import "moment/locale/id";
import React, { useEffect, useState } from "react";
import {
  Button,
  Card,
  Col,
  Modal,
  Row,
  Spinner,
  Accordion,
} from "react-bootstrap";
import { Link, useParams, useNavigate } from "react-router-dom";
import axiosGet from "../../helpers/axiosGet";
import "./Claim.css";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import BootstrapTable from "react-bootstrap-table-next";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import { Type } from "react-bootstrap-table2-editor";
import paginationFactory from "react-bootstrap-table2-paginator";
import "react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css";
import { Controller, useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import TableContainer from "../../components/DataTable/TableContainer";
import CurrencyFormat from "../../components/Formating/CurrencyFormat";
import SpinnerPrimary from "../../components/Spinner/Spinner";
import { setClaimSubmission, setDataEorClaim } from "../../store/claim/actions";
import DetailClaimCertificate from "../../components/DetailCertificate/DetailClaimCertificate";
import axiosPut from "../../helpers/axiosPut";
import Alert from "../../components/Alert/Alert";
import constants from "../../constants/constants";
import DownloadDocClaim from "../../components/DownloadDoc/DownloadDocClaim";
import BannerImage from "../../components/Banner/BannerImage";
import ModalFormEditEor from "../../components/Form/ModalFormEditEor";
import ModalFormRevisionEor from "../../components/Form/ModalFormRevisionEor";
import StatusBar from "../../components/StatusBar/StatusBar";
import ApproveRepairs from "../../components/Action/ApproveRepairs";
import SubmitToInsurance from "../../components/Action/SubmitToInsurance";
import RejectRepairs from "../../components/Action/RejectRepairs";
import DownloadHistoryDocEor from "../../components/DownloadDoc/DownloadHistoryDocEor";

export default function DetailClaim() {
  const dataEorClaim = useSelector((state) => state.claim.dataEorClaim);
  const isAdminKca = useSelector((state) => state.user.isUserAdminKCa);

  const [loading, setLoading] = useState(false);
  const [loadingRepair, setLoadingRepair] = useState(false);
  const [error, setErr] = useState(null);
  const [statusHistory, setStatusHistory] = useState([]);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const params = useParams();
  const [detail, setDetail] = useState();
  const [idClaim, setIdClaim] = useState(null);
  const [subtotalEor, setSubtotalEor] = useState(0);
  const [selectedId, setSelectedId] = useState([]);
  const [selectedSubtotal, setSelectedSubtotal] = useState([]);
  const [currentSelectedTotal, setCurrentSelectedTotal] = useState(0);

  //data adjust eor
  const [dataAdjustEor, setDataAdjustEor] = useState([]);

  // state modal edit eor
  const [modalDataEor, setModalDataEor] = useState(null);

  const decode = window.atob(params.id);

  //show toast
  const [show, setShow] = useState(false);
  const [errorRepair, setErrRepair] = useState(false);
  const [message, setMessage] = useState("");

  const { control, register } = useForm();

  const [conversationsLoaded, setConversationsLoaded] = useState(false);
  const [secondConversationsLoaded, setSecondConversationsLoaded] =
    useState(false);

  useEffect(() => {
    const getAllConversations = async () => {
      setLoading(true);
      setErr(null);
      dispatch(setClaimSubmission());
      await axiosGet({
        url: `/claim-kca/id/${decode}`,
        callback: (res) => {
          setDetail(res.data);
          setIdClaim(res.data.id);
          const claimCargoStatus = res.data.claimContainerStatus;
          setStatusHistory(claimCargoStatus);
          setConversationsLoaded(true);
        },
        errorCallback: (err) => {
          if (
            err.statusCode &&
            (err.statusCode === constants.HTTP_STATUS_UNAUTHORIZED ||
              err.statusCode === constants.HTTP_STATUS_FAILED ||
              err.statusCode === constants.HTTP_STATUS_ERROR ||
              err.statusCode === constants.HTTP_STATUS_SERVER_ERROR)
          ) {
            navigate("/");
          }
          setLoading(false);
          setErr(err);
        },
      });
    };
    getAllConversations();
    // eslint-disable-next-line
  }, [decode, error, dispatch]);

  useEffect(() => {
    if (conversationsLoaded === true) {
      const getEorConversations = async () => {
        await axiosGet({
          url: `/claim-kca/eor/${idClaim}`,
          callback: (res) => {
            const dataEor = res?.data;
            dataEor.sort((a, b) => parseInt(a.id) - parseInt(b.id));
            dispatch(setDataEorClaim(dataEor)); //save to reducer (localStorage)
            setSelectedId(dataEor.map((item) => item.id));

            const countingTotalEor = dataEor;
            setSecondConversationsLoaded(true);
            setSubtotalEor(countSubtotalFunc(countingTotalEor));
            setCurrentSelectedTotal(countSubtotalFunc(countingTotalEor));
          },
          errorCallback: (err) => {
            if (
              err.statusCode &&
              (err.statusCode === constants.HTTP_STATUS_UNAUTHORIZED ||
                err.statusCode === constants.HTTP_STATUS_FAILED ||
                err.statusCode === constants.HTTP_STATUS_ERROR ||
                err.statusCode === constants.HTTP_STATUS_SERVER_ERROR)
            ) {
              navigate("/");
            }
            setLoading(false);
            setErr(err);
          },
        });
      };
      getEorConversations();
    }
    // eslint-disable-next-line
  }, [idClaim]);

  useEffect(() => {
    if (secondConversationsLoaded === true) {
      const getAdjustEorConversations = async () => {
        await axiosGet({
          url: `/claim-kca/eor-adjust/${idClaim}`,
          callback: (res) => {
            setLoading(false);
            const dataAdjust = res?.data;
            setDataAdjustEor(dataAdjust);

            const selectedResult = dataAdjust
              .map((item2) => {
                const matchingItem = dataEorClaim.find(
                  (item) => item.id === item2.claimContainerEorId
                );
                return matchingItem ? matchingItem.id : null;
              })
              .filter(Boolean);

            if (dataAdjust.length > 0 && selectedId.length > 0) {
              setSelectedId(selectedResult);
            }
          },
          errorCallback: (err) => {
            if (
              err.statusCode &&
              (err.statusCode === constants.HTTP_STATUS_UNAUTHORIZED ||
                err.statusCode === constants.HTTP_STATUS_FAILED ||
                err.statusCode === constants.HTTP_STATUS_ERROR ||
                err.statusCode === constants.HTTP_STATUS_SERVER_ERROR)
            ) {
              navigate("/");
            }
            setErr(err);
          },
        });
      };
      getAdjustEorConversations();
    }
    // eslint-disable-next-line
  }, [secondConversationsLoaded]);

  const countSubtotalFunc = (data) => {
    let total = 0;
    data.forEach((item) => {
      if (item.subTtl) {
        total += parseFloat(item.subTtl);
      }
    });
    return total;
  };

  const countSelectedSubtotalFunc = (data) => {
    const total = data?.reduce((accumulator, currentValue) => {
      return accumulator + parseFloat(currentValue);
    }, 0);

    return total;
  };

  const [modal, setModal] = useState(false);
  const toggleModal = () => setModal(!modal);

  const handleEditEor = (row) => {
    toggleModal();
    setModalDataEor(row);
  };

  const columns = [
    {
      dataField: "resp",
      text: "RESP",
    },
    {
      dataField: "comp",
      text: "COMP",
    },
    {
      dataField: "compName",
      text: "COMPONENT NAME",
      headerStyle: { width: "20%" },
      editor: {
        type: Type.TEXTAREA,
      },
    },
    {
      dataField: "lcNo",
      text: "LC",
    },
    {
      dataField: "dc",
      text: "DC",
    },
    {
      dataField: "rc",
      text: "RC",
    },
    {
      dataField: "dms",
      text: "DMS",
    },
    {
      dataField: "qty",
      text: "QTY",
    },
    {
      dataField: "matl",
      text: "MATL",
    },
    {
      dataField: "hr",
      text: "HR",
    },
    {
      dataField: "subTtl",
      text: "SUBTTL",
      headerStyle: { width: "10%" },
      formatter: CurrencyFormat,
    },
  ];

  if (isAdminKca) {
    columns.push({
      text: "Action",
      formatter: (cell, row) => (
        <button
          onClick={() => handleEditEor(row)}
          className="text-primary"
          style={{
            textDecoration: "underline",
            background: "transparent",
            border: "none",
          }}
        >
          edit
        </button>
      ),
    });
  }

  const customTotal = (from, to, size) => (
    <span
      className="react-bootstrap-table-pagination-total"
      style={{ fontSize: "12px" }}
    >
      {" "}
      Menampilkan {from} hingga {to} dari {size} Hasil
    </span>
  );

  const options = {
    paginationSize: 4,
    pageStartIndex: 1,
    alwaysShowAllBtns: true,
    withFirstAndLast: false,
    hideSizePerPage: true,
    hidePageListOnlyOnePage: true,
    showTotal: true,
    paginationTotalRenderer: customTotal,
    disablePageTitle: true,
    sizePerPageList: [
      {
        text: "5",
        value: 5,
      },
      {
        text: "10",
        value: 10,
      },
      {
        text: "All",
        value: dataEorClaim.length,
      },
    ],
  };

  const handleOnSelect = (row, isSelect) => {
    const totalInt = parseFloat(row.subTtl);
    if (isSelect) {
      setSelectedId([...selectedId, row.id]);
      setSelectedSubtotal([...selectedSubtotal, totalInt]);
      const currentSelected = [...selectedSubtotal, totalInt];
      setCurrentSelectedTotal(countSelectedSubtotalFunc(currentSelected));
    } else {
      const currentSelected = selectedSubtotal?.filter(
        (item) => item !== totalInt
      );
      setSelectedId(selectedId.filter((item) => item !== row.id));
      setSelectedSubtotal(currentSelected);
      setCurrentSelectedTotal(countSelectedSubtotalFunc(currentSelected));
    }
  };

  const handleOnSelectAll = (isSelect, rows) => {
    const dataId = rows.map((r) => r.id);
    const dataSubtotal = rows.map((r) => parseFloat(r.subTtl));
    if (isSelect) {
      setSelectedId(dataId);
      setSelectedSubtotal(dataSubtotal);
      const total = dataSubtotal.reduce((accumulator, currentValue) => {
        return accumulator + parseFloat(currentValue);
      }, 0);
      setCurrentSelectedTotal(total);
    } else {
      const array = [];
      setSelectedId([]);
      setSelectedSubtotal([]);
      const total = array.reduce((accumulator, currentValue) => {
        return accumulator + parseFloat(currentValue);
      }, 0);
      setCurrentSelectedTotal(total);
    }
  };

  const selectRowOptions = isAdminKca
    ? {
        selectRow: {
          mode: "checkbox",
          selected: selectedId,
          onSelect: handleOnSelect,
          onSelectAll: handleOnSelectAll,
        },
      }
    : {};

  const expandRowOptions = isAdminKca
    ? {
        expandRow: {
          renderer: (row) => (
            <div>
              <p className="mb-0 text-danger">Note: {row.note ?? "-"}</p>
            </div>
          ),
          expanded: dataEorClaim
            .filter((item) => item.note !== undefined)
            .map((item) => item.id),
          showExpandColumn: true,
          expandByColumnOnly: true,
        },
      }
    : {};

  const handleAjukanRepair = () => {
    setLoadingRepair(true);
    const body = {
      claimContainerId: detail?.id,
      list: dataEorClaim.filter((item) => selectedId.includes(item.id)),
    };

    axiosPut({
      url: "/claim-kca/adjust-eor",
      data: body,
      callback: (res) => {
        setLoadingRepair(false);
        setShow(true);
        setMessage("Request repaired berhasil");
        setTimeout(() => {
          window.location.reload();
        }, 2000);
      },
      errorCallback: (err) => {
        setErrRepair(err);
        setShow(true);
        setLoadingRepair(false);
        if (
          err.statusCode &&
          (err.statusCode === constants.HTTP_STATUS_UNAUTHORIZED ||
            err.statusCode === constants.HTTP_STATUS_FAILED ||
            err.statusCode === constants.HTTP_STATUS_ERROR ||
            err.statusCode === constants.HTTP_STATUS_SERVER_ERROR)
        ) {
          navigate("/");
        }
      },
    });
  };

  const handleRevisiEor = () => {
    toggleModal();
  };

  const listContainer = detail?.claimContainerDetail
    ?.map((item) => `${item.containerNo} (${item.sizeType ?? "-"})`)
    .join(", ");

  return loading ? (
    <div
      className="d-flex justify-content-center align-items-center"
      style={{ minHeight: "100vh" }}
    >
      <SpinnerPrimary />
    </div>
  ) : (
    <>
      {((!isAdminKca && statusHistory[0]?.statusCode === "101") ||
        (isAdminKca && statusHistory[0]?.statusCode === "200")) && (
        <StatusBar
          id={detail?.id}
          statusCode={statusHistory[0]?.statusCode}
          isExpired={detail?.claim?.expiredDate}
        />
      )}
      <div className="container">
        {!isAdminKca && statusHistory[0]?.statusCode === "101" && (
          <>
            <br />
            <br />
          </>
        )}
        <BannerImage isAdmin={isAdminKca} />
        <Row>
          <Col xs={12} md={8}>
            <div className="d-flex justify-content-end ">
              <div className="pl-5 pr-5">
                <span className="title-header-left">
                  No Container (Size Type)
                </span>
                <p className="fw-bold" style={{ color: "#646464" }}>
                  {listContainer}
                </p>
              </div>
            </div>
          </Col>
          <Col xs={12} md={4} className="">
            <h5 className="pl-4 pr-4 mt-2 title-header-right">
              {isAdminKca ? "Detail Sertifikat" : "Update Status"}
            </h5>
          </Col>
          <Col xs={12} md={8}>
            <Card className="card-wrapper p-4 mb-4">
              <Card.Header className="card-main-header">
                Detail Perbaikan
              </Card.Header>
              <Card.Body className="main-card">
                <p className="title">Consignee</p>
                <div className="insured">
                  <h5 className="m-0">{detail?.consignee ?? "-"}</h5>
                </div>

                <Row className="mt-4">
                  <Col md={6}>
                    <p className="title">Tanggal Pengajuan</p>
                    <p className="value">
                      {moment(detail?.createdDAte).format("DD MMM YYYY") ?? "-"}
                    </p>

                    <p className="title">Nama PIC</p>
                    <p className="value">{detail?.claim?.picName ?? "-"}</p>
                  </Col>
                  <Col md={6}>
                    <p className="title">Email PIC</p>
                    <p className="value">{detail?.claim?.picEmail ?? "-"}</p>

                    <p className="title">No Handphone PIC</p>
                    <p className="value">
                      {detail?.claim?.picPhoneNumber ?? "-"}
                    </p>
                  </Col>
                </Row>
              </Card.Body>
            </Card>

            <Card className="card-wrapper p-4 mb-4">
              <Card.Header className="card-main-header">
                Detail Kronologi
              </Card.Header>
              <Card.Body className="main-card">
                {detail?.type === "total-loss" ? (
                  <>
                    <p className="title">Tanggal Kejadian</p>
                    <p className="value">
                      {moment(detail?.accidentDate).format("DD MMM YYYY")}
                    </p>

                    <p className="title">Lokasi Kejadian</p>
                    <p className="value">{detail?.accidentLocation}</p>
                  </>
                ) : (
                  <>
                    <p className="title">Tanggal Survey</p>
                    <p className="value">
                      {moment(detail?.surveyDate).format("DD MMM YYYY")}
                    </p>

                    <p className="title">Lokasi Consignee</p>
                    <p className="value">{detail?.consigneeLocation}</p>
                  </>
                )}

                {isAdminKca && (
                  <>
                    <p className="title">Jenis Kerugian</p>
                    <p className="value">
                      {detail?.type === "partial-loss"
                        ? "Partial Loss"
                        : "Total Loss" ?? "-"}
                    </p>
                  </>
                )}

                <p className="title">Estimasi Perbaikan</p>
                <p className="value">
                  {detail?.currencyCode}{" "}
                  {CurrencyFormat(detail?.claim?.claimAmount) ?? "-"}
                </p>

                <>
                  <hr />
                  <Row className="mb-2">
                    <Col md={5} className="d-flex align-items-center">
                      <div className="card-main-header mb-3">Detail EOR</div>
                    </Col>
                    <Col
                      md={7}
                      className={`d-flex align-items-center ${
                        isAdminKca
                          ? "justify-content-between"
                          : "justify-content-end"
                      }`}
                    >
                      <div>
                        <div className="count">
                          Total : {detail?.currencyCode}{" "}
                          {CurrencyFormat(subtotalEor.toFixed(2))}
                        </div>
                        {isAdminKca && (
                          <div className="count">
                            Subtotal Selected : {detail?.currencyCode}{" "}
                            {CurrencyFormat(currentSelectedTotal.toFixed(2))}
                          </div>
                        )}
                      </div>
                      {isAdminKca && (
                        <div>
                          <Button
                            type="button"
                            color="primary"
                            size="small"
                            className="back-button"
                            onClick={handleAjukanRepair}
                            disabled={loadingRepair || selectedId.length <= 0}
                            style={{ minWidth: "195px" }}
                          >
                            {loadingRepair ? (
                              <Spinner animation="border" size="sm" />
                            ) : (
                              "Ajukan Final Repaired"
                            )}
                          </Button>
                        </div>
                      )}
                    </Col>
                  </Row>

                  <Row>
                    <Col md={12} className="tb-eor">
                      <Controller
                        control={control}
                        name="eorList"
                        {...register("eorList")}
                        render={({ field }) => (
                          <BootstrapTable
                            {...field}
                            name={field.name}
                            id={field.name}
                            value={field.value}
                            keyField="id"
                            data={dataEorClaim}
                            columns={columns}
                            {...expandRowOptions}
                            {...selectRowOptions}
                            striped
                            hover
                            condensed
                            onBlur={field.onBlur}
                            getInputRef={field.ref}
                            pagination={paginationFactory(options)}
                          />
                        )}
                      />
                    </Col>
                  </Row>
                </>
              </Card.Body>
            </Card>

            <div className="accordion-eor">
              <Accordion className="mb-4">
                <Accordion.Item eventKey="0">
                  <Accordion.Header>
                    Data Permintaan Revisi EOR
                  </Accordion.Header>
                  <Accordion.Body className="tb-eor">
                    {!isAdminKca &&
                      dataAdjustEor?.length > 0 &&
                      statusHistory[0].statusCode === "100" && (
                        <div className="text-end">
                          <Button
                            type="button"
                            color="primary"
                            size="small"
                            className="back-button mb-2"
                            onClick={handleRevisiEor}
                            style={{ minWidth: "195px" }}
                          >
                            Upload Revisi EOR
                          </Button>
                        </div>
                      )}
                    <TableContainer data={dataAdjustEor} cols={columns} eor />
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
            </div>

            <Card
              className="card-wrapper p-4 mb-4"
              style={{ minHeight: "100px" }}
            >
              <Card.Header className="card-main-header">
                Daftar Lampiran
              </Card.Header>
              <Card.Body className="main-card">
                <DownloadDocClaim data={detail?.claimContainerDocument} />
              </Card.Body>
            </Card>

            <div className="accordion-eor">
              <DownloadHistoryDocEor id={detail?.id} />
            </div>
          </Col>

          <Col xs={12} md={4}>
            <DetailClaimCertificate
              data={detail}
              statusHistory={statusHistory}
              isAdmin={isAdminKca}
            />

            {isAdminKca && statusHistory[0]?.statusCode === "100" ? (
              <>
                <ApproveRepairs detail={detail} />
                <RejectRepairs detail={detail} />
              </>
            ) : isAdminKca && statusHistory[0]?.statusCode === "102" ? (
              <>
                {detail?.type !== "total-loss" && (
                  <SubmitToInsurance detail={detail} />
                )}
                <Button
                  type="button"
                  color="primary"
                  size="small"
                  className="text-center action-button w-100 mt-3"
                  onClick={() =>
                    navigate(
                      `/claim/${window.btoa(idClaim)}/upload-dokumen-tambahan`
                    )
                  }
                >
                  Upload Additional Doc
                </Button>
              </>
            ) : isAdminKca && statusHistory[0]?.statusCode === "200" ? (
              <>
                <Button
                  type="button"
                  color="primary"
                  size="small"
                  className="text-center action-button w-100 mt-3"
                >
                  Download Dokumen LOD
                </Button>
                <Button
                  type="button"
                  color="primary"
                  size="small"
                  className="text-center action-button w-100 mt-3"
                >
                  Download Dokumen SKGR
                </Button>
              </>
            ) : (
              <></>
            )}
          </Col>
        </Row>

        <Row>
          <Col lg={12} className="d-flex justify-content-between">
            <Link
              to={`${isAdminKca ? "/perbaikan" : "/perbaikan-kontainer"}`}
              style={{ textDecoration: "none" }}
            >
              <Button
                type="button"
                color="primary"
                size="small"
                className="d-flex justify-content-between align-items-center back-button"
              >
                <FontAwesomeIcon icon={faArrowLeft}></FontAwesomeIcon>
                BACK
              </Button>
            </Link>
          </Col>
        </Row>
      </div>

      <Modal show={modal} onHide={toggleModal} size={isAdminKca ? "md" : "lg"}>
        <Modal.Header closeButton>
          <Modal.Title style={{ fontSize: "15px" }}>
            {isAdminKca ? "Form Edit Data EOR" : "Upload Revisi EOR"}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {isAdminKca ? (
            <ModalFormEditEor
              data={modalDataEor}
              toggleModal={toggleModal}
              dataEorClaim={dataEorClaim}
            />
          ) : (
            <ModalFormRevisionEor
              toggleModal={toggleModal}
              claimId={detail?.id}
              refId={detail?.claim?.refId}
              claimContainerDetailId={detail?.claimContainerDetail[0].id}
            />
          )}
        </Modal.Body>
      </Modal>

      <Alert
        message={message}
        show={show}
        error={errorRepair}
        handleClose={() => setShow(false)}
      />
    </>
  );
}
