import React, { Fragment, useState } from "react";
import axiosGet from "../../helpers/axiosGet";
import { saveAs } from "file-saver";
import { Button, Spinner, Accordion, Badge } from "react-bootstrap";
import { FilenameFormat } from "../Formating/DocFormat";
import moment from "moment";
import TableContainer from "../DataTable/TableContainer";
import CreateColumnEor from "../Formating/CreateColumnEor";

export default function DownloadHistoryDocEor({ data, url, id, headerEor }) {
  const [loadingDownload, setLoadingDownload] = useState(false);
  const [loadingData, setLoadingData] = useState(false);
  const [dataHistory, setDataHistory] = useState(null);

  const downloadDoc = (fileId, filename) => {
    setLoadingDownload(fileId);
    axiosGet({
      url: `/claim-kca/download/file-id/${fileId}`,
      responseType: "blob",
      callback: (res) => {
        setLoadingDownload("");
      },
      errorCallback: (res) => {
        setLoadingDownload("");
        let file = new File([res], `${filename}`);
        saveAs(file);
      },
    });
  };

  const handleAccordionChange = (eventKey) => {
    if (eventKey === "0") {
      setLoadingData(true);
      axiosGet({
        url: `/claim-kca/eor-file/${id}`,
        callback: (res) => {
          setLoadingData(false);
          // setDataHistory(res.data);
          const dataEor = res?.data;

          // data adjust eor array
          const newDataAdjust = dataEor.map((container) => {
            const newContainer = { ...container };
            const adjustArray = newContainer.eorList
              .map((eor) => eor.adjust)
              .filter(Boolean);
            newContainer.eorList.forEach((eor) => delete eor.adjust);
            newContainer.adjust = adjustArray;

            return newContainer;
          });
          setDataHistory(newDataAdjust);
        },
        errorCallback: (err) => {
          setLoadingData(false);
          console.log(err);
        },
      });
    }
  };

  const columns = headerEor?.map(CreateColumnEor);

  return (
    <Fragment>
      <Accordion className="mb-4" onSelect={handleAccordionChange}>
        <Accordion.Item eventKey="0">
          <Accordion.Header>Riwayat Unggah EOR</Accordion.Header>
          <Accordion.Body className="tb-eor py-3">
            {loadingData ? (
              <div className="text-center">
                <Spinner
                  animation="border"
                  size="md"
                  style={{ color: "#006881" }}
                />
              </div>
            ) : (
              <>
                {dataHistory?.length > 0 ? (
                  <>
                    {dataHistory?.map((item, index) => (
                      <div key={index}>
                        <div className="d-flex justify-content-between align-items-center">
                          <div className="text-left">
                            <p className="mb-0">{FilenameFormat(item?.type)}</p>
                            <p
                              className="mb-0"
                              style={{ fontSize: "12px", color: "#2c2b2b" }}
                            >
                              {item?.filename}{" "}
                              {index === 0 ? (
                                <Badge bg="success">Aktif</Badge>
                              ) : (
                                <Badge bg="secondary">Arsip</Badge>
                              )}
                            </p>
                            <p
                              className="mb-0"
                              style={{ fontSize: "12px", color: "#6e6e6e" }}
                            >
                              Diupload:{" "}
                              {moment(item?.createdDate).format(
                                "DD MMMM YYYY HH:mm [WIB]"
                              )}
                            </p>
                          </div>
                          <div>
                            <Button
                              type="button"
                              className="download-doc"
                              onClick={() =>
                                downloadDoc(item.fileId, item.filename)
                              }
                              disabled={loadingDownload}
                            >
                              {loadingDownload === item.fileId ? (
                                <Spinner
                                  animation="border"
                                  style={{ width: "15px", height: "15px" }}
                                />
                              ) : (
                                "Unduh"
                              )}
                            </Button>
                          </div>
                        </div>
                        <TableContainer
                          data={
                            item?.adjust?.length > 0
                              ? item?.adjust
                              : item?.eorList
                          }
                          cols={columns}
                          history
                        />
                        <hr />
                      </div>
                    ))}
                  </>
                ) : (
                  <div className="text-center">Belum ada lampiran</div>
                )}
              </>
            )}
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
    </Fragment>
  );
}
