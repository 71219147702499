import { Route, BrowserRouter as Router, Routes } from "react-router-dom";
import "./App.css";
import Footer from "./components/Footer/Footer";
import Navbar from "./components/Navbar/Navbar";
import Declaration from "./pages/Declaration/Declaration";
import DetailDeclaration from "./pages/Declaration/DetailDeclaration";
import Home from "./pages/Home/Home";
import NotFound from "./pages/NotFound/NotFound";
import DetailClaim from "./pages/Claim/DetailClaim";
import ClaimSubmission from "./pages/Claim/ClaimSubmission";
import ClaimSubmissionV2 from "./pages/Claim/V2/ClaimSubmissionForm";
import ClaimSummaryV2 from "./pages/Claim/V2/ClaimSummary";
import ClaimSummary from "./pages/Claim/ClaimSummary";
import Success from "./pages/Claim/Success/Success";
import ClaimUpload from "./pages/Claim/ClaimUpload";
import ListClaimAdmin from "./pages/Claim/ListClaimAdmin";
import { useSelector } from "react-redux";
import ClaimUploadDocSubmission from "./pages/Claim/ClaimUploadDocSubmission";
import ClaimSelectAttachmentForm from "./pages/Claim/V2/ClaimSelectAttachmentForm";
import ClaimDetail from "./pages/Claim/V2/ClaimDetail";
import UploadExcel from "./pages/UploadExcel/UploadExcel";
import ListExcel from "./pages/UploadExcel/ListExcel";
import ListClaimUser from "./pages/Claim/ListClaimUser";
import UpdatePassword from "./pages/UpdatePassword/UpdatePassword";

function App() {
  const isAdminKca = useSelector((state) => state.user.isUserAdminKCa);
  return (
    <Router>
      <Navbar isAdmin={isAdminKca} />
      <Routes>
        <Route exact path="/" element={<Home />}></Route>
        {isAdminKca ? (
          <>
            <Route exact path="/transaksi" element={<Declaration />}></Route>
            <Route exact path="/perbaikan" element={<ListClaimAdmin />}></Route>
            <Route exact path="/claim" element={<ListClaimAdmin />}></Route>
            <Route
              exact
              path="/perbaikan/detail/:id"
              element={<DetailClaim />}
            ></Route>
            <Route
              exact
              path="/claim/detail/:id"
              element={<DetailClaim />}
            ></Route>
            <Route
              exact
              path="/v2/claim/detail/:id"
              element={<ClaimDetail />}
            ></Route>
            <Route
              exact
              path="/v2/perbaikan/detail/:id"
              element={<ClaimDetail />}
            ></Route>
            <Route
              exact
              path="/perbaikan/:id/upload-dokumen-tambahan"
              element={<ClaimUpload />}
            ></Route>
            <Route
              exact
              path="/claim/:id/upload-dokumen-tambahan"
              element={<ClaimUpload />}
            ></Route>
            <Route
              exact
              path="/perbaikan/:id/upload-doc-submission"
              element={<ClaimUploadDocSubmission />}
            ></Route>
            <Route
              exact
              path="/perbaikan-kontainer/detail-kontainer/:id"
              element={<DetailDeclaration />}
            ></Route>
            <Route
              exact
              path="/v2/perbaikan/submission/:id"
              element={<ClaimSubmission />}
            ></Route>
            <Route
              exact
              path="/v2/perbaikan/submission/summary"
              element={<ClaimSummary />}
            ></Route>
          </>
        ) : (
          <>
            <Route exact path="/transaksi" element={<Declaration />}></Route>
            <Route
              exact
              path="/perbaikan-kontainer"
              element={<ListClaimUser />}
            ></Route>
            <Route
              exact
              path="/perbaikan-kontainer/detail-kontainer/:id"
              element={<DetailDeclaration />}
            ></Route>
            <Route
              exact
              path="/perbaikan-kontainer/:id/upload-dokumen-repairs"
              element={<ClaimUpload />}
            ></Route>
            <Route
              exact
              path="/perbaikan-kontainer/detail/:id"
              element={<DetailClaim />}
            ></Route>
            <Route
              exact
              path="/v2/perbaikan-kontainer/detail/:id"
              element={<ClaimDetail />}
            ></Route>
            <Route
              exact
              path="/v2/claim/detail/:id"
              element={<ClaimDetail />}
            ></Route>
            <Route
              exact
              path="/perbaikan-kontainer/:id/upload-doc-submission"
              element={<ClaimUploadDocSubmission />}
            ></Route>
          </>
        )}

        <Route
          exact
          path="/claim/:id/upload-dokumen-lod"
          element={<ClaimUpload />}
        ></Route>
        <Route
          exact
          path="/v2/perbaikan-kontainer/submission/:id"
          element={<ClaimSubmissionV2 />}
        ></Route>
        <Route
          exact
          path="/v2/perbaikan-kontainer/submission/detail-attachment"
          element={<ClaimSelectAttachmentForm />}
        ></Route>
        <Route
          exact
          path="/v2/perbaikan-kontainer/submission/summary"
          element={<ClaimSummaryV2 />}
        ></Route>
        <Route
          exact
          path="/perbaikan-kontainer/submission/:id"
          element={<ClaimSubmission />}
        ></Route>
        <Route
          exact
          path="/perbaikan-kontainer/submission/summary"
          element={<ClaimSummary />}
        ></Route>
        <Route
          exact
          path="/perbaikan/submission/success"
          element={<Success />}
        ></Route>
        <Route exact path="/upload-excel" element={<UploadExcel />}></Route>
        <Route exact path="/list-excel" element={<ListExcel />}></Route>
        <Route
          exact
          path="/change-password"
          element={<UpdatePassword />}
        ></Route>

        <Route
          exact
          path="*"
          element={
            <>
              <NotFound />
            </>
          }
        ></Route>
      </Routes>

      <Footer />
    </Router>
  );
}

export default App;
